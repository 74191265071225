import React, { useContext, useState, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { API_BASE_URL } from '../../config';
import Auth from '../../auth';
import Lang from '../../lang';
import useLoader from '../../hooks/loader';

import DataTable from '../../ui/DataTable';
import Destroyer from '../../ui/Destroyer';

import ClientNetworkAdder from './ClientNetworkAdder';

import NetworkType from '../../networks/components/Type';


const ClientNetworks = ({ clientId }) => {
    const { hasRole } = useContext(Auth);
    const { lang } = useContext(Lang);
    const history = useHistory();
    const { url } = useRouteMatch();

    const [networks, setNetworks] = useState([]);
    const loading = useLoader(`${API_BASE_URL}/clients/${clientId}/networks`, setNetworks);

    const [adding, setAdding] = useState(false);

    const onClick = useCallback((_e, _c, _i, row) => {
        history.push(`/networks/${row.networkId}`);
    }, [history])

    const onDestroy = useCallback(async (id) => {
        await axios.delete(
            `${API_BASE_URL}/clients/${clientId}/networks/${id}`
        );
        setNetworks(networks.filter(n => n.networkId !== id))
    }, [clientId, networks, setNetworks]);

    return (
        <DataTable
            keyField='networkId'
            loading={loading}
            data={networks}
            columns={columns(
                onClick,
                onDestroy,
                lang
            )}>
            {!adding && <div className="col-md-6">
                <button className="btn btn-success"
                    onClick={() => history.push(`${url}/networks/new`)}>
                    {lang.add_network}
                </button>
                {' '}
                {hasRole('admin') && <button className="btn btn-primary"
                    onClick={() => setAdding(true)}>
                    {lang.add_existing_network}
                </button>}
            </div>}
            {adding && <div className="col-md-6">
                <ClientNetworkAdder
                    clientId={clientId}
                    onAdd={ns => setNetworks(ns.concat(networks))}
                    onDone={() => setAdding(false)} />
            </div>}
        </DataTable>
    )
};

const timestampFormatter = ts => moment(ts).format('YYYY-MM-DD HH:mm');

const columns = (onClick, onDestroy, lang) => [
    {
        dataField: 'name',
        text: lang.network_name,
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'flags',
        isDummyField: true,
        text: '',
        formatter: (_, row) => <NetworkType network={row} />,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'address',
        text: lang.network_address,
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'added',
        text: lang.created,
        sort: true,
        formatter: timestampFormatter,
    },
    {
        dataField: 'tools',
        isDummyField: true,
        text: '',
        formatter: (_, row) => <Destroyer onDestroy={() => onDestroy(row.networkId)} />,
    },
];


export default ClientNetworks;
