import React, { useContext, useState } from 'react';
import moment from 'moment';

import useLoader from '../../hooks/loader';
import Auth from '../../auth';
import Lang from '../../lang';
import { API_BASE_URL } from '../../config';
import DataTable from '../../ui/DataTable';


const timestampFormatter = ts => moment(ts).format('YYYY-MM-DD HH:mm');

const List = ({ onClick, onSelect, onSelectAll, children }) => {
    const { lang } = useContext(Lang);
    const { hasRole } = useContext(Auth);

    const columns = [
        {
            dataField: 'name',
            text: lang.client_name,
            sort: true,
        },
        {
            dataField: 'userCount',
            text: lang.client_user_count,
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: lang.created,
            sort: true,
            formatter: timestampFormatter,
        },
    ];

    const [clients, setClients] = useState([]);
    const loading = useLoader(`${API_BASE_URL}/clients`, setClients);

    const selectRow = hasRole('multi-client-admin') ? {
      mode: 'checkbox',
      selected: clients.map((c) => c.display ? c.id : null).filter(Boolean),
      onSelect,
      onSelectAll,
    } : undefined;

    return (
        <DataTable
            loading={loading}
            columns={columns}
            data={clients}
            defaultSort={[{
                dataField: 'created_at',
                order: 'desc',
            }]}
            selectRow={selectRow}
            onClick={onClick}>
            {children}
        </DataTable>
    )
};

export default List;
