import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import Lang from '../../lang';
import { API_BASE_URL } from '../../config';

import FormGroup from '../../ui/FormGroup';

const Form = ({ client, onSave }) => {
    const { lang } = useContext(Lang);

    if (!client) client = defaultClient;

    const {
        register,
        handleSubmit,
        formState,
        errors,
        reset,
    } = useForm();

    const [saving, setSaving] = useState(false);
    const [alert, setAlert] = useState(null);

    const save = data => {
        setAlert(null);
        setSaving(true);

        let req = client.id ?
            axios.put(`${API_BASE_URL}/clients/${client.id}`, data) :
            axios.post(`${API_BASE_URL}/clients`, data);

        req.then(res => {
            setSaving(false);
            setAlert({
                type: 'success',
                icon: 'check-circle-outline',
                text: lang.client_save_success,
            });
            onSave({ ...client, ...data, id: res.data.id });
            reset();
        }).catch(() => {
            setSaving(false);
            setAlert({
                type: 'danger',
                icon: 'alert-circle-outline',
                text: lang.client_save_error,
            });
        });
    }

    return (
        <form onSubmit={handleSubmit(save)}>
            <div className="form-row">
                <FormGroup
                    className="col-sm-12"
                    name="name"
                    inputRef={register({ required: lang.client_name_error })}
                    title={lang.client_name}
                    value={client.name}
                    error={errors.name}
                />
                <FormGroup
                    className="col-sm-12 col-xl-4"
                    name="contactName"
                    inputRef={register}
                    title={lang.client_contact_name}
                    value={client.contactName}
                />
                <FormGroup
                    className="col-sm-12 col-xl-4"
                    name="contactEmail"
                    inputRef={register}
                    title={lang.client_contact_email}
                    value={client.contactEmail}
                />
                <FormGroup
                    className="col-sm-12 col-xl-4"
                    name="contactPhone"
                    inputRef={register}
                    title={lang.client_contact_phone}
                    value={client.contactPhone}
                />
                <FormGroup
                    className="col-sm-12 col-xl-6"
                    name="alarmCentralPhone"
                    inputRef={register}
                    title={lang.client_alarm_central_phone}
                    value={client.alarmCentralPhone}
                />
            </div>
            <div className="form-row">
                <div className="col">
                    <button className="btn btn-primary"
                        disabled={!formState.dirty || saving}>
                        {lang.save}
                    </button>
                </div>
                <div className="col-md-auto">
                    {saving &&
                        <div className="spinner-border" />}
                    {alert &&
                        <div className={`alert alert-${alert.type}`}>
                            <i className={`mdi mdi-${alert.icon}`} />
                            {alert.text}
                        </div>}
                </div>
            </div>
        </form>
    );
}

export default Form;


const defaultClient = {
    name: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
};
