import React, {useContext, useCallback} from 'react';
import axios from 'axios';
import Lang from '../../lang';
import DataTable from '../../ui/DataTable';
import {UAN_API_BASE_URL} from '../../config';
import {SIACodes} from '../../devices/components/sia-codes';
import {v2RefreshToken} from '../../auth/index';

const List = ({
                integrations,
                networkId,
                setEdit,
                loading,
                onDelete,
                setLogs,
              }) => {
  const {lang, l} = useContext(Lang);

  const uan_api = axios.create({
    // quick solution to call v2 endpoints
    baseURL: UAN_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('uan-api-access-token')}`,
    },
  });

  uan_api.interceptors.response.use((res) => res, v2RefreshToken);

  const siaCodeFormatter = (code) => {
    const c = SIACodes[code];
    if (c) return <abbr title={c[l]}>{code}</abbr>;
    return code;
  };

  const kindFormatter = (kind) => {
    if (kind === 'shortxml') return lang.integration_shortxml;
    if (kind === 'manitou') return lang.integration_manitou;
  };

  const del = useCallback(
    async (row) => {
      await uan_api.delete(`/v2/integrations/${networkId}/${row.id}`);
      onDelete();
    },
    [networkId, onDelete, uan_api]
  );

  return (
    <DataTable
      loading={loading}
      columns={columns(
        lang,
        setEdit,
        del,
        siaCodeFormatter,
        kindFormatter,
        setLogs,
        uan_api
      )}
      data={integrations}
      keyField={'ip'}
    />
  );
};

const columns = (
  lang,
  setEdit,
  del,
  siaCodeFormatter,
  kindFormatter,
  setLogs,
  uan_api
) => [
  {
    dataField: 'status',
    text: lang.status,
    formatter: (_, row) => {
      // order of importance.
      // error > pending > success
      let classes = '';
      if (row.status === 'error') {
        classes = 'mdi-pipe text-danger';
      } else if (row.status === 'pending') {
        classes = 'mdi-pipe text-warning';
      } else if (row.status === 'success') {
        classes = 'mdi-pipe text-success';
      } else {
        classes = 'mdi-pipe-disconnected';
      }

      return <i className={`mdi mdi-24px ${classes}`}/>
    }
  },
  {
    dataField: 'kind',
    text: lang.integration_kind,
    formatter: kindFormatter,
  },
  {
    dataField: 'ip',
    text: lang.integration_ip,
    sort: true,
  },
  {
    dataField: 'port',
    text: lang.integration_port,
  },
  {
    dataField: 'description',
    text: lang.integration_description,
  },
  {
    dataField: 'settings.line',
    text: lang.integration_line,
  },
  {
    dataField: 'settings.pin',
    text: lang.integration_pin,
  },
  {
    dataField: 'settings.uid',
    text: lang.integration_uid,
  },
  {
    dataField: 'settings.sia_code_create',
    text: lang.integration_sia_code_create,
    formatter: siaCodeFormatter,
  },
  {
    dataField: 'settings.sia_code_confirm',
    text: lang.integration_sia_code_confirm,
    formatter: siaCodeFormatter,
  },
  {
    dataField: 'settings.sia_code_restore',
    text: lang.integration_sia_code_restore,
    formatter: siaCodeFormatter,
  },
  {
    dataField: 'settings.sia_code_recall',
    text: lang.integration_sia_code_recall,
    formatter: siaCodeFormatter,
  },
  {
    dataField: 'fakeKey1',
    text: lang.logs,
    formatter: (_, row) => (
      <button
        onClick={() => {
          uan_api(`/v2/integrations/logs/${row.id}`).then((res) => {
            const logs = res.data;
            logs.map((log) => log.log = JSON.stringify(log.log, null, 2));
            setLogs(logs);
          });
        }}
        className="btn btn-sm"
      >
        <span className="mdi mdi-file-document-box-multiple-outline"></span>
      </button>
    ),
  },
  {
    dataField: 'fakeKey2',
    text: lang.edit,
    formatter: (_, row) => (
      <button onClick={() => setEdit(row)} className="btn btn-sm">
        <span className="mdi mdi-pencil"></span>
      </button>
    ),
  },
  {
    dataField: 'fakeKey3',
    text: lang.delete,
    formatter: (_, row) => (
      <button onClick={() => del(row)} className="btn btn-sm btn-danger">
        <span className="mdi mdi-trash-can"></span>
      </button>
    ),
  },
];

export default List;
