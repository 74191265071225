/*
    UAN Backoffice Translation Strings - Swedish
    Please take note of case when translating.
    Please add same translation to other lang files.
    Put them into the same line number.
*/
export default {
  language: 'sv',
  //Global
  login_welcome: 'Welcome to UAN-Safe',
  page_title: 'UAN-Safe Mina Sidor SV',
  dashboard: 'Översikt',
  premium: 'Premium',
  users: 'Användare',
  remove: 'Ta bort',
  cancel: 'Avbryt',
  search: 'Sök',
  copy_to_clipboard: 'Copy to clipboard',
  select_or_search: 'Välj eller skriv för att söka...',
  no_matches: 'Tomt resultat',
  locate: 'Lokalisera',
  total: 'Totalt',
  received: 'Mottaget',
  enabled: 'Aktiverad',
  disabled: 'Inaktiverad',
  first_name: 'Förnamn',
  last_name: 'Efternamn',
  email: 'E-post',
  invalid_email_error: 'Felaktig e-postadress',
  email_exists_error: 'E-postadressen finns redan',
  admin: 'Admin',
  added: 'Tillagd',
  new: 'Ny',
  edit: 'Redigera',
  delete: 'Radera',
  new_backoffice: 'Map View',
  new_backoffice_devices: 'Enheter kan nu administreras i vårt nya backoffice! För att prova, klicka här.',
  new_backoffice_networks: 'Nätverk kan nu administreras i vårt nya backoffice! För att prova, klicka här.',
  // Integrationer
  integrations: 'Integrationer',
  create_integration: 'Lägg till integration',
  update_integration: 'Uppdatera integration',
  delete_integration: 'Ta bort integration',
  integration_ip: 'IP',
  integration_port: 'Port',
  integration_manitou: 'Manitou',
  integration_shortxml: 'ShortXML',
  integration_kind: 'Protokoll',
  integration_line: 'Line',
  integration_sia_code_create: 'SIA kod (Ny incident)',
  integration_sia_code_confirm: 'SIA kod (Bekräftad incident)',
  integration_sia_code_recall: 'SIA kod (Återkallad incident)',
  integration_sia_code_restore: 'SIA kod (Återställd incident)',
  integration_uid: 'Konto ID / Hårdvaru ID / Kund ID',
  integration_pin: 'Pinkod',
  integration_description: 'Beskrivning (för loggning)',
  integration_saved: 'Integration sparad!',
  integration_save_error: 'Fel uppstod när integrationen skulle sparas',
  integration_delete_error: 'Fel uppstod när integrationen skulle tas bort',
  integration_delete_success: 'Integrationen togs bort!',
  integration_ip_required: 'IP är obligatoriskt',
  integration_port_required: 'Port är obligatoriskt',
  integration_kind_required: 'Typ är obligatoriskt',
  integration_line_required: 'Line är obligatoriskt',
  integration_uid_required: 'UID är obligatoriskt',
  integration_pin_required: 'UID är obligatoriskt',
  integration_description_required: 'Beskrivning är obligatoriskt',
  logs: 'Loggar',
  // Översikt
  devices: 'Enheter',
  clients: 'Kunder',
  networks: 'Nätverk',
  network: 'Nätverk',
  incidents: 'Händelser',
  comments: 'Kommentarer',
  history_chart_title: 'Statistik Historikdiagram',
  history_chart_description: 'Visa alla värden för senaste dagar/månader/år',
  subscriptions_chart_title: 'Prenumerationer Fördelning',
  subscriptions_chart_description: 'Fördelning av registrerade Intrasenze användare per prenumerationsplan',
  year: 'år',
  years: 'år',
  month: 'Månad',
  months: 'Månader',
  week: 'Vecka',
  weeks: 'Veckor',
  day: 'Dag',
  days: 'Dagar',
  reports: 'Rapporter',
  stat: 'Stat', // Förkortning för Statistik
  password: 'Lösenord',
  // Användare
  add_user: 'Skapa användare',
  add_user_to: 'Skapa användare för',
  add_new_user: 'Skapa ny användare',
  add_existing_user: 'Lägg till existerande användare',
  username: 'Användarnamn',
  user_active: 'Aktiv',
  user_awaiting_activation: 'Väntar på aktivering',
  user_subscription: 'Prenumeration',
  user_status: 'Status',
  user_admin: 'Superadmin',
  user_save_and_new: 'Spara och lägg till en till användare',
  user_save_success: 'Användaren sparad!',
  user_save_error: 'Fel uppstod när användaren skulle sparas!',
  user_email_error: 'E-postadressen får inte vara tom',
  user_username_error: 'Användarnamnet får inte vara tomt',
  user_reset_password: 'Återställ lösenord',
  user_generate_password: 'Generera nytt lösenord',
  user_password_saved: 'Lösenordet sparat och skickat.',
  user_password_reset_confirm: 'Är du säker på att du vill spara? Denna handling skriver över användarens nuvarande lösenord.',
  subscription_history: 'Prenumerationshistorik',
  subscription: 'Prenumeration',
  subscription_platform: 'Plattform',
  subscription_date: 'Startad',
  subscription_expires: 'Går ut',
  subscription_unsubscribed: 'Uppsagd',
  mobile_devices: 'Mobil enheter',
  user_device_registered: 'Registrerad',
  user_device_last_update: 'Senast uppdaterad',
  user_device_app_version: 'Appversion',
  user_device_manufacturer: 'Tillverkare',
  user_device_model: 'Modell',
  user_device_os: 'OS',
  user_device_is_logged_in: 'Inloggad',
  user_device_never_accessed: 'Aldrig',
  user_notification_settings: 'Notifikationer',
  user_notification_category: 'Kategori',
  user_notification_push: 'Pushnotiser',
  user_notification_email: 'E-post',
  user_notification_sound: 'Ljud',
  show: 'Visa',
  active: 'Aktiv',
  awaiting_activation: 'Väntar på aktivering',
  status: 'Status',
  quick_search: 'Sök',
  created: 'Skapad',
  unique_id: 'Unikt ID',
  location: 'Plats',
  latitude: 'Latitud',
  longitude: 'Longitud',
  address: 'Adress',
  city: 'Stad',
  country: 'Land',
  save: 'Spara',
  registered_dalm_dialers: 'Registrerade Enheter',
  manufacturer: 'Tillverkare',
  model: 'Modell',
  operating_system: 'Operativsystem',
  app_version: 'App Version',
  notification_name: 'Meddelande Namn',
  push: 'Push',
  role: 'Roll',
  subscribe_date: 'Prenumeration Startdatum',
  unsubscribe_date: 'Prenumeration Slutdatum',
  expiration_date: 'Utgångsdatum',
  success: 'Lyckades!',
  error: 'Fel!',
  user_saved: 'Användare har sparats.',
  location_saved: 'Plats har sparats.',
  users_added: 'Users successfully added',
  add_user_to_network: 'Lägg till användaren i de valda nätverken',
  the_language: 'Språk',
  language_en: 'Engelsk',
  language_sv: 'Svenska',
  language_es: 'Spanska',
  client_affiliation: 'Kundtillhörighet',
  client_is_required: 'Klient krävs',
  // Kunder
  add_client: 'Skapa kund',
  client_name: 'Kundnamn',
  client_name_error: 'Kundnamnet får inte vara tomt',
  client_contact_name: 'Kontaktens namn',
  client_contact_email: 'Kontaktens e-post',
  client_contact_phone: 'Kontaktens telefon',
  client_alarm_central_phone: 'Larmcentralens telefon',
  client_save_success: 'Kunden sparad!',
  client_save_error: 'Fel uppstod när kunden skulle sparas!',
  client_user_count: 'Antal användare',
  client_network_summary: 'Nätverk',
  client_subscription_summary: 'Prenumerationer',
  policy: 'Policy',
  policy_sos: 'Nödsamtal',
  policy_amc: 'Bråklarm',
  policy_network: 'Nätverksalarm',
  policy_safe_assist: 'Safe Assist',
  policy_enforce: 'Skriv över användarens egna inställningar',
  policy_disable: 'Stäng av larmet',
  policy_create_public_alert: 'Skapa offentlig varning',
  policy_alert_all_available_networks: 'Varna alla tillgängliga nätverk',
  policy_alert_all_available_users: 'Varna alla tillgängliga användare',
  policy_title: 'Larmtitel',
  policy_description: 'Larmbeskrivning',
  policy_selected_networks: 'Valda nätverk',
  policy_public_radius_enabled: 'Aktivera Aviseringsintervall Dynamiska varningar',
  policy_public_radius_title: 'Aviseringsintervall Dynamiska varningar (mätare).',
  policy_public_radius_placeholder: 'Om den är tom, kommer den att ställas in på 10 000 m.',
  policy_selected_networks_radius_enabled: 'Aktivera Meddelandeintervall för valda nätverk.',
  policy_selected_networks_radius_title: 'Meddelandeintervall för valda nätverk (i meter).',
  policy_selected_networks_radius_placeholder: 'Om det är tomt kommer alla valda nätverk att meddelas.',
  client_policy_save_success: 'Kundpolicy sparad!',
  client_policy_save_error: 'Fel uppstod när kundpolicyn skulle sparas!',
  dynamic_alert: 'Dynamisk varning',
  region: 'Område',
  archived: 'Arkiveras',
  closed: 'Stängd',
  version: 'Version',
  comment: 'Kommentar',
  start: 'Start',
  end: 'Slut',
  add_new_invoice: 'Lägg till ny faktura',
  device_id: 'Enhets-ID',
  modified: 'Ändrad',
  name: 'Namn',
  public: 'Public',
  private: 'Private',
  open: 'Open',
  deleted: 'Deleted',
  public_network: 'Public Network',
  private_network: 'Private Network',
  open_network: 'Open Network',
  deleted_network: 'Deleted Network',
  //Enheter
  add_device: 'Skapa enhet',
  device_ports: 'Ingångar och utgångar',
  device_inputs: 'Ingångar',
  device_outputs: 'Utgångar',
  device_alerts: 'Enhetslarm',
  device_owner: 'Ägare',
  device_user_owner: 'Användarägare',
  device_client_owner: 'Kundägare',
  device_user_device: 'Ägarenhet',
  device_client_device: 'Delad enhet',
  device_description: 'Beskrivning',
  device_name: 'Namn',
  device_location: 'Plats',
  device_status: 'Status',
  device_dalm_id: 'DALM-ID',
  device_dalm_sim: 'DALM-SIM',
  device_dalm_passcode: 'DALM-kod',
  device_on: 'Aktiv',
  device_off: 'Inaktiv',
  device_deleted: 'Raderad',
  device_port_code: 'Kod',
  device_port_name: 'Namn',
  device_port_active: 'Status',
  device_owner_error: 'Du måste välja en ägare',
  device_name_error: 'Namnet får inte vara tomt',
  device_location_error: 'Platsen får inte vara tom',
  device_lat_error: 'Koordinaten får inte vara tom',
  device_lng_error: 'Koordinaten får inte vara tom',
  device_save_success: 'Enheten sparad!',
  device_save_error: 'Fel uppstod när enheten skulle sparas!',
  test_mode: 'Övningsläge',
  alert_type: 'Typ',
  alert_event_code: 'Händelsekod',
  alert_section_number: 'Sektionsnummer',
  alert_message: 'Meddelande',
  //Nätverk
  add_network: 'Skapa nätverk',
  add_network_to: 'Skapa nätverk för',
  add_existing_network: 'Lägg till existerande nätverk',
  network_name: 'Nätverksnamn',
  network_description: 'Beskrivning',
  network_type: 'Typ',
  network_status: 'Status',
  network_address: 'Adress',
  network_open: 'Öppet',
  network_public: 'Publikt',
  network_private: 'Privat',
  network_active: 'Aktivt',
  network_closed: 'Stängt',
  network_archived: 'Arkiverat',
  network_deleted: 'Raderat',
  network_owner: 'Ägare',
  network_admin: 'Admin',
  network_member: 'Medlem',
  network_member_joined: 'Anslöt',
  network_add_members: 'Lägg till valda användare i nätverket',
  network_save_success: 'Nätverket sparat!',
  network_save_error: 'Fel uppstod när nätverket skulle sparas!',
  network_name_error: 'Namnet får inte vara tomt',
  network_address_error: 'Adressen får inte vara tom',
  network_lat_error: 'Koordinaten får inte vara tom',
  network_lng_error: 'Koordinaten får inte vara tom',
  network_settings: 'Inställningar',
  enableNotificationRange: 'Meddela endast medlemmar inom räckhåll',
  notificationRange: 'Meddelandeintervall',
  disallowMemberPosts: 'Medlemmar kan inte meddela eller kommentera',
  onlyNotifyAdmins: 'Meddela endast admin',
  confirmIncidents: 'Skapa bekräftat meddelande',
  allowSOSAlerts: 'Tillåt inkommande SOS-nödsituation',
  allowPublicAlerts: 'Tillåt inkommande offentliga varningar',
  enableClustering: 'Avaktivera klustring',
  notificationsAndComments: 'Meddelanden och kommentarer',
  incomingAlerts: 'Inkommande varningar',
  integration_ip_error: 'The IP address cannot be empty',
  integration_port_error: 'The port cannot be empty',
  integration_description_error: 'The description cannot be empty',
  settings: 'Larmcentralskärm',
  caption: 'Benämning',
  icon: 'Ikon',
  useDeviceSettings: 'Använd enhetsinställningar',
};
