import React, { useCallback } from 'react';
import Select from 'react-select';
import axios from 'axios';

import Styles from '../../styles/Select';
import { API_BASE_URL } from '../../config';

import { Member, Admin } from './Type';


const options = [
    { value: 'admin', label: <Admin /> },
    { value: 'member', label: <Member /> },
];

const TypeSelect = ({ networkId, member }) => {
    const onChange = useCallback(async ({ value }) => {
        await axios.put(
            `${API_BASE_URL}/networks/${networkId}/users/${member.userId}`,
            { memberType: value }
        );
    }, [networkId, member])

    return (
        <Select
            styles={Styles}
            name="membertype"
            defaultValue={options.find(o => o.value === member.memberType)}
            options={options}
            onChange={onChange} />
    )
};

export default TypeSelect;
