import React, { useContext, useState } from 'react';
import moment from 'moment';

import useLoader from '../../hooks/loader';
import Lang from '../../lang';
import { API_BASE_URL } from '../../config';
import DataTable from '../../ui/DataTable';

import { SIACodes } from './sia-codes';


const timestampFormatter = ts => moment(ts).format('YYYY-MM-DD HH:mm::ss');

const List = ({ deviceId }) => {
    const { lang, l } = useContext(Lang);

    const siaCodeFormatter = code => {
        const c = SIACodes[code];
        if (c) return (<abbr title={c[l]}>{code}</abbr>);
        return code;
    }

    const columns = [
        {
            dataField: 'data.alarmType',
            text: lang.alert_type,
            sort: true,
        },
        {
            dataField: 'eventCode',
            text: lang.alert_event_code,
            sort: true,
            formatter: siaCodeFormatter,
        },
        {
            dataField: 'sectionNumber',
            text: lang.alert_section_number,
            sort: true,
        },
        {
            dataField: 'message',
            text: lang.alert_message,
            sort: true,
            formatter: v => <pre>{v}</pre>,
        },
        {
            dataField: 'data.alarmDate',
            text: lang.received,
            sort: true,
            formatter: timestampFormatter,
        },
    ];

    const [alerts, setAlerts] = useState([]);
    const loading = useLoader(`${API_BASE_URL}/devices/${deviceId}/alerts`, setAlerts);

    return (
        <DataTable
            loading={loading}
            columns={columns}
            data={alerts}
            defaultSort={[{
                dataField: "data.alarmDate",
                order: "desc",
            }]} />
    )
};

export default List;
