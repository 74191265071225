import React from 'react';
import DataSelect from '../../ui/DataSelect';
import {API_BASE_URL} from '../../config';

const Selector = (props) => {
  const {name, inputRef, inputKey, onChange, defaultValue, isDisabled, isMulti} = props;

  return (
    <DataSelect
      name={name}
      inputRef={inputRef}
      inputKey={inputKey}
      url={`${API_BASE_URL}/clients/search`}
      formatLabel={formatLabel}
      onChange={onChange}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      isMulti={isMulti}
    />
  );

}
export default Selector;

const formatLabel = (client) => <>
  {client.name}
</>;
