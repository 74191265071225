import React, { useContext } from 'react'

import Lang from '../../lang'

import Card from '../../ui/Card'
import Spinner from '../../ui/Spinner';


const HistoryTable = ({ data }) => {
    const { lang } = useContext(Lang);
    const hasData = Object.keys(data).length > 0;

    return (
        <Card className="col-lg-12" title={lang.reports}>
            {hasData &&
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                {data.timestamps.map((ts, i) =>
                                    <th key={i} style={{ whiteSpace: 'nowrap' }}>
                                        {ts}
                                    </th>)}
                            </tr>
                            {Object.entries(data.counts).map(([k, cs]) =>
                                <tr key={k}>
                                    <th>{lang[k]}</th>
                                    {cs.map((v, i) => <td key={i}>{v}</td>)}
                                </tr>)}
                        </thead>
                    </table>
                </div>}
            {!hasData && <Spinner />}
        </Card>
    )
}

export default HistoryTable;
