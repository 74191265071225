import React, { useState, useContext, useCallback } from 'react';
import axios from 'axios';
import Lang from '../../lang';
import { API_BASE_URL } from '../../config';
import SettingRowCheckbox from '../../networks/components/SettingRowCheckbox';

const System = ({ user }) => {
    const { lang } = useContext(Lang);

    const [os, setOS] = useState({});
    const [sent, setSent] = useState(false);
    const [error, setError] = useState();

    const sendNewAppNotification = useCallback(async () => {
        const data = [];

        if (os.ios) {
          data.push('iOS');
        }

        if (os.android) {
          data.push('Android');
        }

        if (!data.length) {
          return;
        }

        const res = await axios.post(`${API_BASE_URL}/system/notifications/new-app-version`, { os: data });

        if (res.status === 200) {
            setSent(true);
        } else {
            setError({
                code: res.status.toString,
                message: res.statusText,
            });
        }
    }, [os]);

    return (
      <div>
          <SettingRowCheckbox
            checked={os.ios}
            name="iOS"
            onChange={ev => setOS({ ...os, ios: ev.target.checked })}
          />
          <SettingRowCheckbox
            checked={os.android}
            name="Android"
            onChange={ev => setOS({ ...os, android: ev.target.checked })}
          />
          <br/>
          <div className="form-row">
            <div className="col-md-1">
              <button
                  onClick={() => sendNewAppNotification()}
                  className={`btn ${
                      sent ? 'btn-success' : 'btn-primary'
                  }`}
              >
                  {sent
                      ? 'Notified'
                      : 'Send new app version notification'}
              </button>
            </div>
          </div>
        {error && (
            <div
                className='form-row'
                style={{
                    margin: '10px 0 0 0',
                }}
            >
                <p className='error text-danger'>
                    {error.code} - {error.message}
                </p>
            </div>
        )}
      </div>
    );
};

export default System;
