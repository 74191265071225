import React from 'react';

const SimpleSelect = (props) => {
  const {
    name,
    title,
    inputRef,
    defaultValue,
    className,
    options,
    onChange,
  } = props;

  return (
    <div className={`form-group form-check ${className}`}>
      <label>{title}</label>
      <select
        className="form-control col-md-6"
        name={name}
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {Object.entries(options).map(([val, lbl]) =>
          <option key={val} value={val}>{lbl}</option>
        )}
      </select>
    </div>
  );
};

export default SimpleSelect;
