export const SIACodes = {
    'U1': { en: 'DAML input port 1', sv: 'DALM ingång 1' },
    'U2': { en: 'DAML input port 2', sv: 'DALM ingång 2' },
    'U3': { en: 'DAML input port 3', sv: 'DALM ingång 3' },
    'U4': { en: 'DAML input port 4', sv: 'DALM ingång 4' },
    'U5': { en: 'DAML input port 5', sv: 'DALM ingång 5' },
    'U6': { en: 'DAML input port 6', sv: 'DALM ingång 6' },
    'U7': { en: 'DAML input port 7', sv: 'DALM ingång 7' },
    'U8': { en: 'DAML input port 8', sv: 'DALM ingång 8' },
    'UA': { en: 'Untyped Zone Alarm', sv: 'Ingång Larm' },
    'UB': { en: 'Untyped Zone Bypass', sv: 'Ingång Förbikopplad' },
    'UR': { en: 'Untyped Zone Restoral', sv: 'Larm Återställd' },
    'UU': { en: 'Untyped Zone Unbypass', sv: 'Ingång Förbikopplad Återställd', },
    'DT': { en: 'Access Trouble', sv: 'Tillträde Fel' },
    'AA': { en: 'Alarm – Panel Substitution', sv: 'Utbyte centralapparat', },
    'AB': { en: 'Abort', sv: 'Avbrutet' },
    'AN': { en: 'Analog Restoral', sv: 'Analog Ok' },
    'AR': { en: 'AC Restoral', sv: 'AC Ok' },
    'AS': { en: 'Analog Service', sv: 'Analog Service' },
    'AT': { en: 'AC Trouble', sv: 'AC Fel' },
    'BA': { en: 'Burglary Alarm', sv: 'Inbrottslarm' },
    'BB': { en: 'Burglary Bypass', sv: 'Inbrottslarm Förbikopplad' },
    'BC': { en: 'Burglary Cancel', sv: 'Inbrottslarm Avbrutet av Användare', },
    'BD': { en: 'Swinger Trouble', sv: 'Swinger Fel' },
    'BE': { en: 'Swinger Trouble Restore', sv: 'Swinger Fel Ok' },
    'BG': { en: 'Unverified Event - Burglary', sv: 'Overifierat larm - Inbrott', },
    'BH': { en: 'Burglary Alarm Restore', sv: 'Inbrottslarm Ok' },
    'BJ': { en: 'Burglary Trouble Restore', sv: 'Inbrottslarm Fel Ok' },
    'BM': { en: 'Burglary Alarm - Cross Point', sv: 'Inbrottslarm Korspunkt verfierad', },
    'BR': { en: 'Burglary Restoral', sv: 'Inbrottslarm Återställd' },
    'BS': { en: 'Burglary Supervisory', sv: 'Inbrottslarm Övervakning' },
    'BT': { en: 'Burglary Trouble', sv: 'Inbrottslarm Fel' },
    'BU': { en: 'Burglary Unbypass', sv: 'Inbrottslarm Förbikopplad Återställd', },
    'BV': { en: 'Burglary Verified', sv: 'Inbrottslarm Verifierad (A-larm)', },
    'BX': { en: 'Burglary Test', sv: 'Inbrottslarm Testläge' },
    'BZ': { en: 'Missing Supervision', sv: 'Saknad Övervakning' },
    'CA': { en: 'Automatic Closing', sv: 'Automatisk Tillkoppling' },
    'CD': { en: 'Closing Delinquent', sv: 'EJ tillkopplad inom förväntad tid', },
    'CE': { en: 'Closing Extend', sv: 'Förlängd Tillkopplingstid av Användare', },
    'CF': { en: 'Forced Closing', sv: 'Forcerad Tillkoppling av Användare', },
    'CG': { en: 'Close Area', sv: 'Delvis Tillkopplat' },
    'CI': { en: 'Fail to Close', sv: 'Misslyckad Tillkoppling' },
    'CJ': { en: 'Late Close', sv: 'Sen Tillkoppling av Användare' },
    'CK': { en: 'Early Close', sv: 'Tidig frånkoppling av Användare' },
    'CL': { en: 'Closing Report', sv: 'Tillkopplat av Användare' },
    'CM': { en: 'Missing Alarm - Recent Closing', sv: 'Saknad larmadress - inom 2min efter Tillkoppling', },
    'CO': { en: 'Command Sent', sv: 'Kommandot skickat' },
    'CP': { en: 'Automatic Closing', sv: 'Automatisk Tillkoppling av Användare', },
    'CQ': { en: 'Remote Closing', sv: 'Fjärrtillkoppling av Användare' },
    'CR': { en: 'Recent Closing', sv: 'Larm inom 5min efter Tillkoppling av Användare', },
    'CS': { en: 'Closing Keyswitch', sv: 'Tillkopplad via FBK-nyckel' },
    'CT': { en: 'Late to Open', sv: 'Sen Frånkoppling' },
    'CW': { en: 'Was Force Armed', sv: 'Forcerad Tillkoppling' },
    'CX': { en: 'Custom Function Executed', sv: 'Anpassad funktion utförd', },
    'CZ': { en: 'Point Closing', sv: 'Adress Tillkopplad' },
    'DA': { en: 'Card Assigned', sv: 'Accesskort tilldelat till Användare', },
    'DB': { en: 'Card Deleted', sv: 'Accesskort har raderats från Användare', },
    'DC': { en: 'Access Closed', sv: 'Tillträde spärrat' },
    'DD': { en: 'Access Denied', sv: 'Tillträde nekad' },
    'DE': { en: 'Request to Enter', sv: 'Begäran om tillträde' },
    'DF': { en: 'Door Forced', sv: 'Dörr forcerad' },
    'DG': { en: 'Access Granted', sv: 'Tillträde beviljad' },
    'DH': { en: 'Door Left Open - Restoral', sv: 'Dörr öppen för länge - Återställd', },
    'DI': { en: 'Access Denied – Passback', sv: 'Tillträde nekad - Antipassback', },
    'DJ': { en: 'Door Forced - Trouble', sv: 'Dörr forcerad' },
    'DK': { en: 'Access Lockout', sv: 'Tillträde nekad - Fel kod' },
    'DL': { en: 'Door Left Open - Alarm', sv: 'Dörr öppen för länge - Larmat läge', },
    'DM': { en: 'Door Left Open - Trouble', sv: 'Dörr öppen för länge - Avlarmat läge', },
    'DN': { en: 'Door Left Open (non-alarm, non-trouble)', sv: 'Dörr öppen för länge - ej tillhörande larmområde', },
    'DO': { en: 'Access Open', sv: 'Tillträde Aktiverad - Tidschema På' },
    'DP': { en: 'Access Denied - Unauthorized Time', sv: 'Tillträde nekad - obehörig tid', },
    'DQ': { en: 'Access Denied Unauthorized Arming State', sv: 'Tillträde nekad - larmat tillstånd', },
    'DR': { en: 'Door Restoral', sv: 'Dörr återställd' },
    'DS': { en: 'Door Station', sv: 'Dörr' },
    'DU': { en: 'Dealer ID', sv: 'Återförsäljare ID' },
    'DV': { en: 'Access Denied Unauthorized Entry Level', sv: 'Tillträde nekad obehörig behörighetsnivå', },
    'DW': { en: 'Access Denied - Interlock', sv: 'Åtkomst nekad - Sluss öppen', },
    'DX': { en: 'Request to Exit', sv: 'Öppnaknapp' },
    'DY': { en: 'Door Locked', sv: 'Dörr låst' },
    'DZ': { en: 'Access Denied - Door Secured', sv: 'Tillträde nekad - dörr i Säkrat område', },
    'EA': { en: 'Exit Alarm', sv: 'Utgångsfördröjning - adress öppen' },
    'EE': { en: 'Exit Error', sv: 'Utgångsfördröjning - Fel' },
    'EJ': { en: 'Expansion', sv: 'Utökningskort sabotage återställd' },
    'EM': { en: 'Expansion', sv: 'Utökningskort saknas' },
    'EN': { en: 'Expansion', sv: 'Utökningskort kommunikation återställd', },
    'ER': { en: 'Expansion', sv: 'Utökningskort fel återställd' },
    'ES': { en: 'Expansion', sv: 'Utökningskort sabotage' },
    'ET': { en: 'Expansion', sv: 'Utökningskort fel' },
    'EX': { en: 'External Device Condition', sv: 'Extern enhets status' },
    'EZ': { en: 'Missing Alarm - Exit Error', sv: 'Saknad Larmadress efter utgångfördröjning', },
    'FA': { en: 'Fire Alarm', sv: 'Brandlarm' },
    'FB': { en: 'Fire Bypass', sv: 'Brandlarm Förbikopplad' },
    'FC': { en: 'Fire Cancel', sv: 'Brandlarm Avbrutet av Användare' },
    'FG': { en: 'Unverified Event – Fire', sv: 'Overifierat larm - Brandlarm', },
    'FH': { en: 'Fire Alarm Restore', sv: 'Brandlarm Ok' },
    'FI': { en: 'Fire Test Begin', sv: 'Brandlarm Test Startad' },
    'FJ': { en: 'Fire Trouble Restore', sv: 'Brandlarm Fel Ok' },
    'FK': { en: 'Fire Test End', sv: 'Brandlarmsprov Avslutad' },
    'FL': { en: 'Fire Alarm Silenced', sv: 'Brandlarm Larmdon tystade' },
    'FM': { en: 'Fire Alarm - Cross Point', sv: 'Brandlarm - Korspunkt Verifierad', },
    'FQ': { en: 'Fire Supervisory Trouble Restore', sv: 'Brandlarm Övervakningsfel Återställd', },
    'FR': { en: 'Fire Restoral', sv: 'Brandlarm Återställd' },
    'FS': { en: 'Fire Supervisory', sv: 'Brandlarm Övervakning' },
    'FT': { en: 'Fire Trouble', sv: 'Brandlarm Fel' },
    'FU': { en: 'Fire Unbypass', sv: 'Brandlarm Förbikopplad Återställd', },
    'FV': { en: 'Fire Supervision Restore', sv: 'Brandlarm Övervakning Ok', },
    'FW': { en: 'Fire Supervisory Trouble', sv: 'Brandlarm Övervakning Fel', },
    'FX': { en: 'Fire Test', sv: 'Brandlarm Testläge' },
    'FY': { en: 'Missing Fire Trouble', sv: 'Brandlarm adress saknas' },
    'FZ': { en: 'Missing Fire Supervision', sv: 'Brandlarm Övervakning saknas', },
    'GA': { en: 'Gas Alarm', sv: 'Gaslarm' },
    'GB': { en: 'Gas Bypass', sv: 'Gaslarm Förbikopplad' },
    'GH': { en: 'Gas Alarm Restore', sv: 'Gaslarm Ok' },
    'GJ': { en: 'Gas Trouble Restore', sv: 'Gaslarm Fel Ok' },
    'GR': { en: 'Gas Restoral', sv: 'Gaslarm Återställd' },
    'GS': { en: 'Gas Supervisory', sv: 'Gaslarm Övervakning' },
    'GT': { en: 'Gas Trouble', sv: 'Gaslarm Fel' },
    'GU': { en: 'Gas Unbypass', sv: 'Gaslarm Förbikopplad Återställd' },
    'GX': { en: 'Gas Test', sv: 'Gaslarm Testläge' },
    'HA': { en: 'Holdup Alarm', sv: 'Tyst larm - under hot' },
    'HB': { en: 'Holdup Bypass', sv: 'Tyst larm - Förbikopplad' },
    'HH': { en: 'Holdup Alarm Restore', sv: 'Tyst larm Ok' },
    'HJ': { en: 'Holdup Trouble Restore', sv: 'Tyst Larm Fel Ok' },
    'HR': { en: 'Holdup Restoral', sv: 'Tyst larm Återställd' },
    'HS': { en: 'Holdup Supervisory', sv: 'Tyst larm Övervakning' },
    'HT': { en: 'Holdup Trouble', sv: 'Tyst larm Fel' },
    'HU': { en: 'Holdup Unbypass', sv: 'Tyst larm Förbikopplad Återställd', },
    'IA': { en: 'Equipment Failure Condition', sv: 'Systemfel' },
    'IR': { en: 'Equipment Fail - Restoral', sv: 'Systemfel Ok' },
    'JA': { en: 'User Code Tamper', sv: 'Användarkod - för många felaktiga försök', },
    'JD': { en: 'Date Changed', sv: 'Datum ändras' },
    'JH': { en: 'Holiday Changed', sv: 'Helgdagar ändras' },
    'JK': { en: 'Latchkey Alert', sv: 'Saknad Användarkod inom tidsram' },
    'JL': { en: 'Log Threshold', sv: 'Logg tröskelnivå' },
    'JO': { en: 'Log Overflow', sv: 'Logg minnet fullt' },
    'JP': { en: 'User On Premises', sv: 'Användare på plats' },
    'JR': { en: 'Schedule Executed', sv: 'Schemahändelse aktiverad' },
    'JS': { en: 'Schedule Changed', sv: 'Schema ändras' },
    'JT': { en: 'Time Changed', sv: 'Tid ändrad' },
    'JV': { en: 'User Code Changed', sv: 'Användarkod ändrad' },
    'JX': { en: 'User Code Deleted', sv: 'Användarkod raderad' },
    'JY': { en: 'User Code Added', sv: 'Användarkod tillagd' },
    'JZ': { en: 'User Level Set', sv: 'Användarnivå har justerats' },
    'KA': { en: 'Heat Alarm', sv: 'Värmelarm' },
    'KB': { en: 'Heat Bypass', sv: 'Värmelarm Förbikopplad' },
    'KH': { en: 'Heat Alarm Restore', sv: 'Temperaturlarm Ok' },
    'KJ': { en: 'Heat Trouble Restore', sv: 'Temperaturlarm Fel Ok' },
    'KR': { en: 'Heat Restoral', sv: 'Temperaturlarm återställd' },
    'KS': { en: 'Heat Supervisory', sv: 'Temperaturlarm Övervakning' },
    'KT': { en: 'Heat Trouble', sv: 'Temperaturlarm Fel' },
    'KU': { en: 'Heat Unbypass', sv: 'Temperaturlarm Förbikopplad Återställd', },
    'LB': { en: 'Local Program', sv: 'Lokalt programmering Startad' },
    'LD': { en: 'Local Program Denied', sv: 'Lokalt programmering Nekat', },
    'LE': { en: 'Listen-in Ended', sv: 'Lyssna in Avslutad' },
    'LF': { en: 'Listen-in Begin', sv: 'Lyssna in Startad' },
    'LR': { en: 'Phone Line Restoral', sv: 'Telefonlinje Ok' },
    'LS': { en: 'Local Program Success', sv: 'Lokal programmering Lyckades', },
    'LT': { en: 'Phone Line Trouble', sv: 'Telefonlinje Fel' },
    'LU': { en: 'Local Program Fail', sv: 'Lokal programmering Misslyckad', },
    'LX': { en: 'Local Programming Ended', sv: 'Lokal programmering avslutad', },
    'MA': { en: 'Medical Alarm', sv: 'Medicinskt Larm' },
    'MB': { en: 'Medical Bypass', sv: 'Medicinskt larm Förbikopplad' },
    'MH': { en: 'Medical Alarm Restore', sv: 'Medicinskt larm Ok' },
    'MI': { en: 'Message', sv: 'Meddelande' },
    'MJ': { en: 'Medical Trouble Restore', sv: 'Medicinskt larm Ok' },
    'MR': { en: 'Medical Restoral', sv: 'Medicinskt larm Återställd' },
    'MS': { en: 'Medical Supervisory', sv: 'Medicinskt larm Övervakning', },
    'MT': { en: 'Medical Trouble', sv: 'Medicinskt larm Fel' },
    'MU': { en: 'Medical Unbypass', sv: 'Medicinskt larm Förbikopplad Återställd', },
    'NA': { en: 'No Activity', sv: 'Ingen aktivitet' },
    'NC': { en: 'Network Condition', sv: 'Nätverksstatus' },
    'NF': { en: 'Forced Perimeter Arm', sv: 'Forcerad Tillkoppling Perimeterlarm', },
    'NL': { en: 'Perimeter Armed', sv: 'Perimeterlarm Tillkopplad' },
    'NM': { en: 'Perimeter Armed, User Defined', sv: 'Perimeterlarm Tillkopplad, Användardefinierad', },
    'NR': { en: 'Network Restoral', sv: 'Nätverksfel Ok' },
    'NS': { en: 'Activity Resumed', sv: 'Aktivitet återupptagen' },
    'NT': { en: 'Network Failure', sv: 'Nätverksfel' },
    'OA': { en: 'Automatic Opening', sv: 'Automatisk Frånkoppling' },
    'OC': { en: 'Cancel Report', sv: 'Avbryt rapportering' },
    'OG': { en: 'Open Area', sv: 'Delvis Frånkopplad' },
    'OH': { en: 'Early to Open from Alarm', sv: 'Tidig Frånkoppling av Användare i larmläge före tidschema', },
    'OI': { en: 'Fail to Open', sv: 'Misslyckad Frånkoppling inom tidschema', },
    'OJ': { en: 'Late Open', sv: 'Sen Frånkoppling - efter tidschema' },
    'OK': { en: 'Early Open', sv: 'Tidig Frånkoppling - före tidschema' },
    'OL': { en: 'Late to Open from Alarm', sv: 'Sen Frånkoppling av Användare i larmläge', },
    'OP': { en: 'Opening Report', sv: 'Frånkoppling' },
    'OQ': { en: 'Remote Opening', sv: 'Fjärrfrånkoppling av Användare' },
    'OR': { en: 'Disarm From Alarm', sv: 'Frånkoppling i larmläge' },
    'OS': { en: 'Opening Keyswitch', sv: 'Frånkopplad via FBK-nyckel' },
    'OT': { en: 'Late To Close', sv: 'Sen Tillkoppling enligt tidschema', },
    'OU': { en: 'Output State – Trouble', sv: 'Utgångsstatus Fel' },
    'OV': { en: 'Output State – Restore', sv: 'Utgångsstatus Ok' },
    'OZ': { en: 'Point Opening', sv: 'Adress Frånkopplad' },
    'PA': { en: 'Panic Alarm', sv: 'Överfallslarm' },
    'PB': { en: 'Panic Bypass', sv: 'Överfallslarm Förbikopplad' },
    'PH': { en: 'Panic Alarm Restore', sv: 'Överfallslarm Ok' },
    'PJ': { en: 'Panic Trouble Restore', sv: 'Överfallslarm Fel Ok' },
    'PR': { en: 'Panic Restoral', sv: 'Överfallslarm Återställd' },
    'PS': { en: 'Panic Supervisory', sv: 'Överfallslarm Övervakning' },
    'PT': { en: 'Panic Trouble', sv: 'Överfallslarm Fel' },
    'PU': { en: 'Panic Unbypass', sv: 'Överfallslarm Förbikopplad Återställd', },
    'QA': { en: 'Emergency Alarm', sv: 'Nödlarm' },
    'QB': { en: 'Emergency Bypass', sv: 'Nödlarm Förbikopplad' },
    'QH': { en: 'Emergency Alarm Restore', sv: 'Nödlarm Ok' },
    'QJ': { en: 'Emergency Trouble Restore', sv: 'Nödlarm Fel Ok' },
    'QR': { en: 'Emergency Restoral', sv: 'Nödlarm Återställd' },
    'QS': { en: 'Emergency Supervisory', sv: 'Nödlarm Övervakning' },
    'QT': { en: 'Emergency Trouble', sv: 'Nödlarm Fel' },
    'QU': { en: 'Emergency Unbypass', sv: 'Nödlarm Förbikopplad Återställd', },
    'RA': { en: 'Remote Programmer Call Failed', sv: 'Fjärrprogrammering uppkoppling Misslyckad', },
    'RB': { en: 'Remote Program Begin', sv: 'Fjärrprogrammering Startad', },
    'RC': { en: 'Relay Close', sv: 'Relä Avaktiverad' },
    'RD': { en: 'Remote Program Denied', sv: 'Fjärrprogrammering nekad' },
    'RN': { en: 'Remote Reset', sv: 'Fjärråterställning' },
    'RO': { en: 'Relay Open', sv: 'Relä Aktiverad' },
    'RP': { en: 'Automatic Test', sv: 'Automatisk test' },
    'RR': { en: 'Power Up', sv: 'Kallstart' },
    'RS': { en: 'Remote Program Success', sv: 'Fjärrprogrammering Lyckades', },
    'RT': { en: 'Data Lost', sv: 'Data förlorad' },
    'RU': { en: 'Remote Program Fail', sv: 'Fjärrprogrammering Misslyckad', },
    'RX': { en: 'Manual Test', sv: 'Manuell test larmöverföring' },
    'RY': { en: 'Test Off Normal', sv: 'Onormal status i Testläge' },
    'SA': { en: 'Sprinkler Alarm', sv: 'Sprinkler' },
    'SB': { en: 'Sprinkler Bypass', sv: 'Sprinkler Förbikopplad' },
    'SC': { en: 'Change of State', sv: 'Statusändring' },
    'SH': { en: 'Sprinkler Alarm Restore', sv: 'Sprinkler Ok' },
    'SJ': { en: 'Sprinkler Trouble Restore', sv: 'Sprinkler Fel Ok' },
    'SR': { en: 'Sprinkler Restoral', sv: 'Sprinkler Återställd' },
    'SS': { en: 'Sprinkler Supervisory', sv: 'Sprinkler Övervakning' },
    'ST': { en: 'Sprinkler Trouble', sv: 'Sprinkler Fel' },
    'SU': { en: 'Sprinkler Unbypass', sv: 'Sprinkler Förbikopplad Återställd', },
    'TA': { en: 'Tamper Alarm', sv: 'Sabotage' },
    'TB': { en: 'Tamper Bypass', sv: 'Sabotage Förbikopplad' },
    'TC': { en: 'All Points Tested', sv: 'Alla adresser avprovade' },
    'TE': { en: 'Test End', sv: 'Testläge Slut' },
    'TH': { en: 'Tamper Alarm Restore', sv: 'Sabotagelarm Ok' },
    'TJ': { en: 'Tamper Trouble Restore', sv: 'Sabotagelarm Fel Ok' },
    'TP': { en: 'Walk Test Point', sv: 'Testläge adress provad' },
    'TR': { en: 'Tamper Restoral', sv: 'Sabotage Ok' },
    'TS': { en: 'Test Start', sv: 'Testläge Start' },
    'TT': { en: 'Tamper Trouble', sv: 'Sabotage i avlarmat läge' },
    'TU': { en: 'Tamper Unbypass', sv: 'Sabotage Förbikopplad Återställd', },
    'TW': { en: 'Area Watch Start', sv: 'Area Watch Start' },
    'TX': { en: 'Test Report', sv: 'Testlarm' },
    'TZ': { en: 'Area Watch End', sv: 'Area Watch End' },
    'UG': { en: 'Unverified Event – Untyped', sv: 'Overifierat larm' },
    'UH': { en: 'Untyped Alarm Restore', sv: 'Overifierat larm Ok' },
    'UJ': { en: 'Untyped Trouble Restore', sv: 'Overifierat Fel Ok' },
    'US': { en: 'Untyped Zone Supervisory', sv: 'Overifierad adress Övervakning', },
    'UT': { en: 'Untyped Zone Trouble', sv: 'Overifierad adress Fel' },
    'UX': { en: 'Undefined', sv: 'Odefinierad' },
    'UY': { en: 'Untyped Missing Trouble', sv: 'Overifierad adress i avlarmat läge saknas', },
    'UZ': { en: 'Untyped Missing Alarm', sv: 'Overifierad adress i larmat läge saknas', },
    'VI': { en: 'Printer Paper In', sv: 'Skrivarpapper Ok' },
    'VO': { en: 'Printer Paper Out', sv: 'Utskriftspapper Slut' },
    'VR': { en: 'Printer Restore', sv: 'Skrivareåterställning' },
    'VT': { en: 'Printer Trouble', sv: 'Skrivarproblem' },
    'VX': { en: 'Printer Test', sv: 'Skrivartest' },
    'VY': { en: 'Printer Online', sv: 'Skrivare Online' },
    'VZ': { en: 'Printer Offline', sv: 'Skrivare offline' },
    'WA': { en: 'Water Alarm', sv: 'Vattenlarm' },
    'WB': { en: 'Water Bypass', sv: 'Vattenlarm Förbikopplad' },
    'WH': { en: 'Water Alarm Restore', sv: 'Vattenlarm Ok' },
    'WJ': { en: 'Water Trouble Restore', sv: 'Vattenlarm Fel Ok' },
    'WR': { en: 'Water Restoral', sv: 'Vattenlarm Återställd' },
    'WS': { en: 'Water Supervisory', sv: 'Vattenlarm Övervakning' },
    'WT': { en: 'Water Trouble', sv: 'Vattenlarm Fel' },
    'WU': { en: 'Water Unbypass', sv: 'Vattenlarm Förbikopplad Återställd', },
    'XA': { en: 'Extra Account Report', sv: 'Extra konto rapport' },
    'XE': { en: 'Extra Point', sv: 'Extra punkt' },
    'XF': { en: 'Extra RF Point', sv: 'Extra RF-punkt' },
    'XH': { en: 'RF Interference Restoral', sv: 'RF interferens Återställning', },
    'XI': { en: 'Sensor Reset', sv: 'Sensoråterställning' },
    'XJ': { en: 'RF Receiver Tamper Restoral', sv: 'RF Mottagare Sabotage Återställning', },
    'XL': { en: 'Low Received Signal Strength', sv: 'RF Mottagare Låg signalstyrka', },
    'XM': { en: 'Missing Alarm - Cross Point', sv: 'Saknad Larmadress - Korspunkt', },
    'XQ': { en: 'RF Interference', sv: 'RF-interferens' },
    'XR': { en: 'Transmitter Battery Restoral', sv: 'Sändarens batteriladdning Återställd', },
    'XS': { en: 'RF Receiver Tamper', sv: 'RF Mottagare Sabotage' },
    'XT': { en: 'Transmitter Battery Trouble', sv: 'RF Sändare Lågt batteri', },
    'XW': { en: 'Forced Point', sv: 'Forcerad Förbikoppling' },
    'XX': { en: 'Fail to Test', sv: 'Misslyckad test' },
    'YA': { en: 'Bell Fault', sv: 'Larmdon Fel' },
    'YB': { en: 'Busy Seconds', sv: 'Larmmottagare Upptagen i sekunder' },
    'YC': { en: 'Communications Fail', sv: 'Kommunikation misslyckas' },
    'YD': { en: 'Receiver Line Card Trouble', sv: 'Mottagarkort Fel' },
    'YE': { en: 'Receiver Line Card Restored', sv: 'Mottagarkort Ok' },
    'YF': { en: 'Parameter Checksum Fail', sv: 'Parameter Kontrollsumma Misslyckad', },
    'YG': { en: 'Parameter Changed', sv: 'Parametern ändrad' },
    'YH': { en: 'Bell Restored', sv: 'Larmdon Ok' },
    'YI': { en: 'Overcurrent Trouble', sv: 'Överström Fel' },
    'YJ': { en: 'Overcurrent Restore', sv: 'Överström Ok' },
    'YK': { en: 'Communications Restoral', sv: 'Kommunikation Ok' },
    'YM': { en: 'System Battery Missing', sv: 'Systembatteri saknas' },
    'YN': { en: 'Invalid Report', sv: 'Ogiltig rapport' },
    'YO': { en: 'Unknown Message', sv: 'Okänd meddelande' },
    'YP': { en: 'Power Supply Trouble', sv: 'Strömförsörjningsfel' },
    'YQ': { en: 'Power Supply Restored', sv: 'Strömförsörjning Ok' },
    'YR': { en: 'System Battery Restoral', sv: 'Systembatteri Återställd', },
    'YS': { en: 'Communications Trouble', sv: 'Kommunikationsproblem' },
    'YT': { en: 'System Battery Trouble', sv: 'System Batteri Fel' },
    'YU': { en: 'Diagnostic Error', sv: 'Diagnostiskt fel' },
    'YW': { en: 'Watchdog Reset', sv: 'Watchdog Reset' },
    'YX': { en: 'Service Required', sv: 'Underhåll krävs' },
    'YY': { en: 'Status Report', sv: 'Status rapport' },
    'YZ': { en: 'Service Completed', sv: 'Service slutförd' },
    'ZA': { en: 'Freeze Alarm', sv: 'Fryslarm' },
    'ZB': { en: 'Freeze Bypass', sv: 'Fryslarm Förbikopplad' },
    'ZH': { en: 'Freeze Alarm Restore', sv: 'Fryslarm Ok' },
    'ZJ': { en: 'Freeze Trouble Restore', sv: 'Fryslarm Fel Ok' },
    'ZR': { en: 'Freeze Restoral', sv: 'Fryslarm Återställning' },
    'ZS': { en: 'Freeze Supervisory', sv: 'Fryslarm Övervakning' },
    'ZT': { en: 'Freeze Trouble', sv: 'Fryslarm Fel' },
    'ZU': { en: 'Freeze Unbypass', sv: 'Fryslarm Förbikopplad' },
};
