import React, { useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { API_BASE_URL } from '../../config';
import Lang from '../../lang';
import useLoader from '../../hooks/loader';

import Type from './Type';
import NetworkForm from './NetworkForm';
import DataTable from '../../ui/DataTable';
import Destroyer from '../../ui/Destroyer';
import NetworkType from '../../networks/components/Type';


const UserMemberships = ({ userId }) => {
    const { lang } = useContext(Lang);
    const history = useHistory();

    const [memberships, setMemberships] = useState([]);
    const loading = useLoader(`${API_BASE_URL}/users/${userId}/networks`, setMemberships);

    const onClick = useCallback((e, c, i, row) => {
        history.push(`/networks/${row.networkId}`);
    }, [history])

    const onDestroy = useCallback(async (id) => {
        await axios.delete(
            `${API_BASE_URL}/users/${userId}/networks/${id}`
        );
        setMemberships(memberships.filter(m => m.networkId !== id))
    }, [userId, memberships, setMemberships]);

    return (
        <>
            <NetworkForm
                userId={userId}
                onSave={ms => setMemberships(ms.concat(memberships))} />
            <hr />
            <DataTable
                keyField='networkId'
                loading={loading}
                data={memberships}
                columns={columns(onClick, onDestroy, lang)} />
        </>
    )
};

const timestampFormatter = ts => moment(ts).format('YYYY-MM-DD HH:mm');

const columns = (onClick, onDestroy, lang) => [
    {
        dataField: 'name',
        text: lang.network_name,
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'flags',
        isDummyField: true,
        text: '',
        formatter: (_, row) => <NetworkType network={row} />,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'address',
        text: lang.network_address,
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'memberType',
        text: '',
        formatter: (_, row) => (<Type member={row} />),
    },
    {
        dataField: 'joined',
        text: lang.network_member_joined,
        sort: true,
        formatter: timestampFormatter,
    },
    {
        dataField: 'tools',
        isDummyField: true,
        text: '',
        formatter: (_, row) =>
            <Destroyer onDestroy={() => onDestroy(row.networkId)} />,
    },
];


export default UserMemberships;
