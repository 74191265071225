import React, {useContext, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Lang from '../lang';
import Auth from '../auth';
import logo from '../assets/images/logo.jpg';

const TopBar = (_props) => {
  const history = useHistory();

  const {lang, setLang} = useContext(Lang);
  const {
    logout,
    user: {
      id: userId,
      roles,
      firstName,
      lastName,
      username,
      email,
    },
    hasRole,
  } = useContext(Auth);

  let displayName;

  if (firstName && lastName) {
    displayName = `${firstName} ${lastName}`;
  } else if (username) {
    displayName = username;
  } else {
    displayName = email;
  }

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand text-logo" to="/">
          <img src={logo} className="logo" alt="UAN-Safe Backoffice"/>
          {lang.page_title}
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <h2 className="page-title">
          {hasRole('admin') &&
            <div className="badge badge-success badge-fw">
              Super admin
            </div>}
          {hasRole('client-admin') &&
            <div className="badge badge-info badge-fw">
              Client admin
            </div>}
          {hasRole('multi-client-admin') &&
            <div className="badge badge-info badge-fw">
              Multi Client admin
            </div>}
          <span>
            {displayName}
          </span>
        </h2>
        <ul className="navbar-nav navbar-nav-right">
          <DropDown title={lang.language.toUpperCase()}>
            <a href="/" onClick={() => setLang('en')}>EN</a>
            <a href="/" onClick={() => setLang('sv')}>SV</a>
            <a href="/" onClick={() => setLang('es')}>ES</a>
          </DropDown>
          <DropDown title={
            <i className="mdi mdi-account" style={{fontSize: '24px'}}></i>
          }>
            <a href="/" onClick={() => history.push(`/users/${userId}`)}>
              <i className={`mdi mdi-account text-primary`}></i>
              Profile
            </a>
            <a href="/" onClick={() => logout()}>
              <i className={`mdi mdi-logout text-primary`}></i>
              Logout
            </a>
          </DropDown>
        </ul>
      </div>
    </nav>
  );
}

const DropDown = ({title, children}) => {
  let [open, setOpen] = useState(false);

  let wrappedChildren = React.Children.map(children,
    c => React.cloneElement(c, {
      onClick: e => {
        e.preventDefault();
        setOpen(false);
        c.props.onClick(e);
      },
      className: `${c.className} dropdown-item`
    }));

  return (
    <li className="nav-item nav-profile dropdown">
      <a onClick={e => {
        e.preventDefault();
        setOpen(!open);
      }}
         className="nav-link dropdown-toggle"
         href="/">
        {title}
      </a>

      <div className={
        `dropdown-menu dropdown-menu-right navbar-dropdown ${open ? 'show' : ''}`
      }>
        {wrappedChildren}
      </div>
    </li>
  )
}

export default TopBar
