import { useState, useEffect } from 'react';
import { v2RefreshToken } from '../auth/index';
import axios from 'axios';

const useLoader = (url, setData) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios.get(url).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [url, setData]);

  return loading;
};

// quick solution to call v2 endpoints
export const useApiV2Loader = (url, setData) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const uan_api = axios.create({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('uan-api-access-token')}`,
      },
    });

    uan_api.interceptors.response.use((res) => res, v2RefreshToken);

    uan_api.get(url).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [url, setData]);

  return loading;
};

export default useLoader;
