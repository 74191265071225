import React, { useContext, useState, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { API_BASE_URL } from '../../config';
import Auth from '../../auth';
import Lang from '../../lang';
import useLoader from '../../hooks/loader';

import ClientUserAdder from './ClientUserAdder';

import DataTable from '../../ui/DataTable';
import Destroyer from '../../ui/Destroyer';

import UserStatus from '../../users/components/Status';
import UserDeviceStatus from '../../users/components/UserDeviceStatus';
import Subscription from '../../users/components/Subscription';


const ClientUsers = ({ clientId }) => {
    const { hasRole } = useContext(Auth);
    const { lang } = useContext(Lang);
    const history = useHistory();
    const { url } = useRouteMatch();

    const [users, setUsers] = useState([]);
    const loading = useLoader(`${API_BASE_URL}/clients/${clientId}/users`, setUsers);

    const [adding, setAdding] = useState(false);

    const onClick = useCallback((_e, _c, _i, row) => {
        history.push(`/users/${row.userId}`);
    }, [history])

    const onDestroy = useCallback(async (id) => {
        await axios.delete(
            `${API_BASE_URL}/clients/${clientId}/users/${id}`
        );
        setUsers(users.filter(m => m.userId !== id))
    }, [clientId, users, setUsers]);

    return (
        <DataTable
            keyField='userId'
            loading={loading}
            data={users}
            columns={columns(
                clientId,
                hasRole,
                onClick,
                onDestroy,
                lang
            )}>
            {!adding && <div className="col-md-6">
                <button className="btn btn-success"
                    onClick={() => history.push(`${url}/users/new`)}>
                    {lang.add_new_user}
                </button>
                {' '}
                {(hasRole('admin') || hasRole('multi-client-admin')) && <button className="btn btn-primary"
                    onClick={() => setAdding(true)}>
                    {lang.add_existing_user}
                </button>}
            </div>}
            {adding && <div className="col-md-6">
                <ClientUserAdder
                    clientId={clientId}
                    onAdd={us => setUsers(us.concat(users))}
                    onDone={() => setAdding(false)} />
            </div>}
        </DataTable>
    )
};

const timestampFormatter = ts => moment(ts).format('YYYY-MM-DD HH:mm');

const updateUser = async (clientId, userId, updates) => {
    await axios.put(
        `${API_BASE_URL}/clients/${clientId}/users/${userId}`,
        updates
    );
}

const columns = (clientId, hasRole, onClick, onDestroy, lang) => [
    {
        dataField: 'firstName',
        text: lang.first_name,
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'lastName',
        text: lang.last_name,
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'email',
        text: lang.email,
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'added',
        text: lang.added,
        sort: true,
        formatter: timestampFormatter,
    },
    {
        dataField: 'device_last_accessed',
        text: lang.user_device_is_logged_in,
        sort: true,
        formatter: (_, row) => (<UserDeviceStatus user={row} />),
    },
    {
        dataField: 'isAdmin',
        text: '',
        formatter: (_, row) => (
            <div className="form-inline">
                <div className="form-group form-check form-check-danger">
                    <label className="form-check-label">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={row.isAdmin || row.isMultiClientAdmin}
                            disabled={row.isMultiClientAdmin}
                            onChange={ev => updateUser(
                                clientId,
                                row.userId,
                                { isAdmin: ev.target.checked }
                            )}
                        />
                        {lang.admin}
                        <i className="input-helper" />
                    </label>
                </div>
            </div>
        )
    },
    {
        dataField: 'status',
        text: '',
        formatter: (_, row) => (<UserStatus user={row} />),
    },
    {
        dataField: 'subscription',
        text: '',
        formatter: (_, row) => (<Subscription user={row} />),
    },
    {
        dataField: 'tools',
        isDummyField: true,
        text: '',
        formatter: (_, row) => {
            if (row.memberType === 'owner') return '';
            if (hasRole('client-admin') && row.isMultiClientAdmin) return '';
            return <Destroyer onDestroy={() => onDestroy(row.userId)} />;
        },
    },
];


export default ClientUsers;
