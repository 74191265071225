import React, { useContext, useState } from 'react';

import { API_BASE_URL } from '../../config';
import Lang from '../../lang';
import useLoader from '../../hooks/loader';

import Spinner from '../../ui/Spinner';
import { Public, Open, Private } from '../../networks/components/Type';


const NetworkSummary = ({ clientId }) => {
    const { lang } = useContext(Lang);

    const [summary, setSummary] = useState({});

    const loading = useLoader(
        `${API_BASE_URL}/clients/${clientId}/network-summary`,
        setSummary
    );

    if (loading) {
        return <Spinner />;
    }

    const total = Object.values(summary).reduce((a, v) => a + v);

    return (
        <table className="table table-sm table-borderless">
            <tbody>
                <tr>
                    <th colSpan="2">
                        {lang.client_network_summary}
                    </th>
                </tr>
                <tr>
                    <td style={{ width: '1%' }}><Open /></td>
                    <td>{summary.open}</td>
                </tr>
                <tr>
                    <td><Public /></td>
                    <td>{summary.public}</td>
                </tr>
                <tr>
                    <td><Private /></td>
                    <td>{summary.private}</td>
                </tr>
                <tr>
                    <td>
                        <span className="badge badge-dark">
                            {lang.total}
                        </span>
                    </td>
                    <td><strong>{total}</strong></td>
                </tr>
            </tbody>
        </table>
    );
}

export default NetworkSummary;
