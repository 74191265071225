import React, {useCallback, useContext, useState} from 'react';
import moment from 'moment';

import useLoader from '../../hooks/loader';
import Lang from '../../lang';
import { API_BASE_URL } from '../../config';
import DataTable from '../../ui/DataTable';

import Type from './Type';
import Status from './Status';
import Destroyer from "../../ui/Destroyer";
import axios from "axios";
import Auth from "../../auth";
import {useHistory} from "react-router-dom";

const timestampFormatter = (ts) => moment(ts).format('YYYY-MM-DD HH:mm');

const List = ({ children, url }) => {
  const { lang } = useContext(Lang);
  let history = useHistory();
  const { user: { roles }, hasRole } = useContext(Auth);
  const [networks, setNetworks] = useState([]);

  if (!url) url = `${API_BASE_URL}/networks`;

  const loading = useLoader(url, setNetworks);

  const onClick = useCallback((e, c, i, row) => {
    history.push(`/networks/${row.id}`);
  }, [history])

  const onDestroy = useCallback(async (id) => {
    await axios.delete(`${API_BASE_URL}/networks/${id}`);
    setNetworks(networks.filter(n => n.id !== id))
  }, [networks, setNetworks]);

  // its super-admin and we are on main page ".../networks"
  const showDestroy = (hasRole('admin') && url === `${API_BASE_URL}/networks`);

  return (
    <DataTable
      loading={loading}
      columns={columns(lang, onClick, showDestroy ? onDestroy : null)}
      data={networks}
      defaultSort={[
        {
          dataField: 'created',
          order: 'desc',
        },
      ]}
    >
      {children}
    </DataTable>
  );
};

const columns = (lang, onClick, onDestroy) => [
  {
    dataField: 'name',
    text: lang.network_name,
    sort: true,
    events: { onClick },
    style: { cursor: 'pointer' },
  },
  {
    dataField: 'type',
    isDummyField: true,
    text: '',
    events: { onClick },
    style: { cursor: 'pointer' },
    formatter: (_, row) => (
      <>
        <Type network={row} />
        <Status network={row} />
      </>
    ),
  },
  {
    dataField: 'address',
    text: lang.network_address,
    sort: true,
    events: { onClick },
    style: { cursor: 'pointer' },
  },
  {
    dataField: 'integration_settings_statuses',
    text: lang.integrations,
    sort: true,
    events: { onClick },
    style: { cursor: 'pointer' },
    formatter: (_, row) => {
      // order of importance.
      // error > pending > success
      const mostImportantStatus = row.integration_settings_statuses.sort()[0];
      let classes = '';
      if (mostImportantStatus === 'error') {
        classes = 'mdi-pipe text-danger';
      } else if (mostImportantStatus === 'pending') {
        classes = 'mdi-pipe text-warning';
      } else if (mostImportantStatus === 'success') {
        classes = 'mdi-pipe text-success';
      } else {
        classes = 'mdi-pipe-disconnected';
      }

      return <i className={`mdi mdi-24px ${classes}`}/>
    }
  },
  {
    dataField: 'created',
    text: lang.created,
    sort: true,
    events: { onClick },
    style: { cursor: 'pointer' },
    formatter: timestampFormatter,
  },
  {
    dataField: 'tools',
    isDummyField: true,
    text: '',
    formatter: (_, row) => {
      if (onDestroy) return <Destroyer onDestroy={() => onDestroy(row.id)} />;
    },
  },
];

export default List;
