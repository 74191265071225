import React from 'react';
import DataSelect from '../../ui/DataSelect';

import { API_BASE_URL } from '../../config';
import Type from './Type';


const Selector = ({ onChange, userId, clientId }) =>
    <DataSelect
        isMulti={true}
        url={`${API_BASE_URL}/networks/search`}
        formatLabel={formatLabel}
        onChange={onChange}
        params={{ userId, clientId }}
    />

export default Selector;


const formatLabel = (network) => <>
    <Type network={network} /> {network.name}
</>;
