import React, { useContext } from 'react';

import Lang from '../../lang';

const badge = (k, t) => () => {
    const { lang } = useContext(Lang);
    return (
        <span className={`badge badge-pill badge-outline-${t}`}>
            {lang[k]}
        </span>
    );
}

export const Owner = badge('network_owner', 'success');
export const Admin = badge('network_admin', 'primary');
export const Member = badge('network_member', 'info');


const Type = ({ member }) => <>
    {member.memberType === 'owner' && <Owner />}
    {member.memberType === 'admin' && <Admin />}
    {member.memberType === 'member' && <Member />}
</>

export default Type;
