import React from 'react'

import en from './translations/en'
import sv from './translations/sv'
import es from './translations/es'

export const Lang = React.createContext('en');
export default Lang;

export const initLang = () => {
    let lang = localStorage.getItem('lang');

    if (!lang) {
        let lang = navigator.language || navigator.userLanguage;
        lang = lang === 'sv' ? 'sv' : lang === 'es' ? 'es' : 'en';
        localStorage.setItem('lang', lang);
    }

    return lang;
}

export const buildLang = (lang, set) => ({
    l: lang,
    lang: lang === 'sv' ? sv : lang === 'es' ? es : en,
    setLang: lang => {
        set(lang === 'sv' ? 'sv' : lang === 'es' ? 'es' : 'en')
    }
})
