const env = process.env;

let api_base_url;
if     (env.REACT_APP_APP_ENV === 'prod') { api_base_url = 'https://api.uan-safe.com/backoffice-api'      }
else if(env.REACT_APP_APP_ENV === 'test') { api_base_url = 'https://api-test.uan-safe.com/backoffice-api' }
else                                      { api_base_url = 'http://localhost:3000/backoffice-api'                    }
export const API_BASE_URL = api_base_url;

let uan_api_base_url;
if     (env.REACT_APP_APP_ENV === 'prod') { uan_api_base_url = 'https://api.uan-safe.com'      }
else if(env.REACT_APP_APP_ENV === 'test') { uan_api_base_url = 'https://api-test.uan-safe.com' }
else                                      { uan_api_base_url = 'http://localhost:3000'         }
export const UAN_API_BASE_URL = uan_api_base_url;

let new_backoffice_url;
if     (env.REACT_APP_APP_ENV === 'prod') { new_backoffice_url = '/v2'      }
else if(env.REACT_APP_APP_ENV === 'test') { new_backoffice_url = '/v2' }
else                                      { new_backoffice_url = 'http://localhost:4002'         }
export const NEW_BACKOFFICE_URL = new_backoffice_url;

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCQga6bXdmHaoYt_IxLYfIDRP6TzriCjcE';
