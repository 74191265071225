import { GOOGLE_MAPS_API_KEY } from '../config';

const geocode = async (query) => {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAPS_API_KEY}&${query}`;

    // fetch is required because Axios does not work with the google api
    const res = await fetch(url);
    const data = await res.json();

    if (data.status !== "OK") {
        console.error(data);
        throw new Error(`Response not OK`)
    }

    return data.results[0];
}

export default geocode;
