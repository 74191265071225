import React, { useContext, useState } from 'react';
import moment from 'moment';

import useLoader from '../../hooks/loader';
import Lang from '../../lang';
import { API_BASE_URL } from '../../config';
import DataTable from '../../ui/DataTable';

import Status from './Status';
import Subscription from './Subscription';


const timestampFormatter = ts => moment(ts).format('YYYY-MM-DD HH:mm');

const List = ({ onClick, children }) => {
    const { lang } = useContext(Lang);
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'username',
            text: lang.username,
            sort: true,
        },
        {
            dataField: 'firstName',
            text: lang.first_name,
            sort: true,
        },
        {
            dataField: 'lastName',
            text: lang.last_name,
            sort: true,
        },
        {
            dataField: 'email',
            text: lang.email,
            sort: true,
        },
        {
            dataField: 'flags',
            isDummyField: true,
            text: '',
            formatter: (_, row) => (
                <>
                    <Subscription user={row} />
                    {' '}
                    <Status user={row} />
                </>
            ),
        },
        {
            dataField: 'created',
            text: lang.created,
            sort: true,
            formatter: timestampFormatter,
        },
    ];

    const [users, setUsers] = useState([]);
    const loading = useLoader(`${API_BASE_URL}/users`, setUsers);

    return (
        <DataTable
            loading={loading}
            columns={columns}
            data={users}
            defaultSort={[{
                dataField: "created",
                order: "desc",
            }]}
            onClick={onClick}>
            {children}
        </DataTable>
    )
};

export default List;
