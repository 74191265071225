import React from 'react';

export const SuperAdmin = () => (
        <span className={`badge`}>
          Super Admin
        </span>
);

export const MultiClientAdmin = () => (
        <span className={`badge`}>
          Multi Client Admin
        </span>
);

export const ClientAdmin = () => (
        <span className={`badge`}>
          Client Admin
        </span>
);

export const User = () => (
        <span className={`badge`}>
          User
        </span>
);
