import React, { useContext } from 'react';

import Lang from '../../lang';

const badge = (k, t) => () => {
    const { lang } = useContext(Lang);
    return (
        <span className={`badge badge-outline-${t}`}>
            {lang[k]}
        </span>
    );
}

export const Public = badge('network_public', 'public');
export const Open = badge('network_open', 'success');
export const Private = badge('network_private', 'warning');

const Type = ({ network }) => <>
    {network.type === 'public' && <Public />}
    {network.type === 'open' && <Open />}
    {network.type === 'private' && <Private />}
</>;

export default Type;
