import React, { useState, useContext, useCallback } from 'react';
import axios from 'axios';

import Lang from '../../lang';
import { API_BASE_URL } from '../../config';

import NetworkSelector from '../../networks/components/Selector';


const NetworkForm = ({ userId, onSave }) => {
    const { lang } = useContext(Lang);

    const [ids, setIds] = useState([])
    const [key, setKey] = useState(0);

    const save = useCallback(async () => {
        const res = await axios.post(
            `${API_BASE_URL}/users/${userId}/networks`,
            { ids }
        );

        onSave(res.data);
        setKey(key + 1);
        setIds([]);
    }, [
        userId,
        onSave,
        ids,
        setIds,
        key,
        setKey,
    ]);

    return (
        <div className="form-row">
            <div className="col">
                <NetworkSelector
                    key={key}
                    userId={userId}
                    onChange={ns => setIds((ns || []).map(n => n.id))} />
            </div>
            <div className="col-auto">
                <button
                    className="btn btn-primary btn-sm"
                    disabled={ids.length <= 0}
                    onClick={save}>
                    {lang.add_user_to_network}
                </button>
            </div>
        </div>
    )
}

export default NetworkForm;
