import React, { useState, useContext, useCallback } from 'react';
import axios from 'axios';
import Lang from '../../lang';
import { API_BASE_URL } from '../../config';

const ResetPassword = ({ user }) => {
    const { lang } = useContext(Lang);

    const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [copied, setCopied] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState();

    const generatePassword = useCallback(async () => {
        try {
            const res = await axios.get(
                `${API_BASE_URL}/users/generate-password`
            );

            if (res.status === 200) {
                setPassword(res.data.password);
            } else {
                setError(res.statusText);
            }
        } catch (e) {
            setError(e);
        }
    }, []);

    const save = useCallback(async () => {
        const res = await axios.put(
            `${API_BASE_URL}/users/${user.id}/reset-password`,
            {
                password: password,
            }
        );

        if (res.status === 200) {
            setSent(true);
        } else {
            setError({
                code: res.status.toString,
                message: res.statusText,
            });
        }
    }, [user.id, password]);

    return (
        <>
            {password && (
                <>
                    <div className='form-row'>
                        <div className='col-md-5'>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className={`form-control`}
                                placeholder={lang.password}
                                defaultValue={password}
                                readOnly={true}
                            />
                        </div>
                        <div className='col-md-2'>
                            <button
                                className='btn col-md-12 btn-primary'
                                onClick={() => {
                                    setShowPassword(!showPassword);
                                }}
                            >
                                <i
                                    className={`mdi mdi-${
                                        showPassword ? 'eye' : 'eye-off'
                                    } menu-icon`}
                                />
                            </button>
                        </div>
                    </div>
                    <div
                        className='form-row'
                        style={{
                            margin: '10px 0 0 0',
                        }}
                    >
                        <div className='col-md-5'>
                            <button
                                className={`btn col-md-12 ${
                                    copied ? 'btn-secondary' : 'btn-primary'
                                }`}
                                onClick={() => {
                                    navigator.clipboard.writeText(password);
                                    setCopied(true);
                                }}
                            >
                                {copied ? (
                                    <i className={`mdi mdi-check menu-icon`} />
                                ) : (
                                    lang.copy_to_clipboard
                                )}
                            </button>
                        </div>
                        {copied && (
                            <div className='col-md-2'>
                                <button
                                    onClick={() => {
                                        if (
                                            window.confirm(
                                                lang.user_password_reset_confirm
                                            )
                                        ) {
                                            save();
                                        }
                                    }}
                                    className={`btn col-md-12 ${
                                        sent ? 'btn-success' : 'btn-primary'
                                    }`}
                                >
                                    {sent
                                        ? lang.user_password_saved
                                        : lang.save}
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}

            {!password && (
                <button
                    onClick={() => {
                        generatePassword();
                    }}
                    className='btn btn-inverse-primary'
                >
                    {lang.user_generate_password}
                </button>
            )}
            {error && (
                <div
                    className='form-row'
                    style={{
                        margin: '10px 0 0 0',
                    }}
                >
                    <p className='error text-danger'>
                        {error.code} - {error.message}
                    </p>
                </div>
            )}
        </>
    );
};

export default ResetPassword;
