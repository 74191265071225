import React from 'react';

const FormGroup = (props) => {
  const {
    name,
    title,
    inputRef,
    checked,
    className,
    onChange,
    disabled,
  } = props;

  return (
    <div className={`form-group form-check ${className}`}>
      <label className="form-check-label">
        <input
          className="form-check-input"
          ref={inputRef}
          name={name}
          type="checkbox"
          defaultChecked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        {title}
        <i className="input-helper"/>
      </label>
    </div>
  );
};

export default FormGroup;
