import React, { useState, useContext } from 'react';
import moment from 'moment';

import Lang from '../../lang';
import { API_BASE_URL } from '../../config';
import useLoader from '../../hooks/loader';

import Subscription from './Subscription';

import DataTable from '../../ui/DataTable';


const timestampFormatter = ts =>
    ts ? moment(ts).format('YYYY-MM-DD HH:mm') : '';


const SubscriptionHistory = ({ user }) => {
    const { lang } = useContext(Lang);

    const columns = [
        {
            dataField: 'subscription',
            text: lang.subscription,
            sort: true,
            formatter: (_, row) => <Subscription user={row} />,
        },
        {
            dataField: 'platform',
            text: lang.subscription_platform,
            sort: true,
            formatter: (_, row) => (
                <div className="badge badge-outline-secondary">
                    {row.platform}
                </div>
            ),
        },
        {
            dataField: 'created',
            text: lang.subscription_date,
            sort: true,
            formatter: timestampFormatter,
        },
        {
            dataField: 'expires',
            text: lang.subscription_expires,
            sort: true,
            formatter: timestampFormatter,
        },
        {
            dataField: 'unsubscribed',
            text: lang.subscription_unsubscribed,
            sort: true,
            formatter: timestampFormatter,
        },
    ];

    const [subscriptions, setSubscriptions] = useState([]);

    const loading = useLoader(
        `${API_BASE_URL}/users/${user.id}/subscription-history`,
        setSubscriptions);

    return (
        <DataTable
            loading={loading}
            columns={columns}
            data={subscriptions}
            defaultSort={[{
                dataField: "created",
                order: "desc",
            }]} />
    )
}

export default SubscriptionHistory;
