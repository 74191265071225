import React, { useContext } from 'react';

import Lang from '../../lang';

const badge = (k, t) => () => {
    const { lang } = useContext(Lang);
    return (
        <span className={`badge badge-${t}`}>
            {lang[k]}
        </span>
    );
}

export const Closed = badge('network_closed', 'warning');
export const Archived = badge('network_archived', 'danger');
export const Deleted = badge('network_deleted', 'danger');

const Status = ({ network }) => <>
    {network.status === 'closed' && <Closed />}
    {network.status === 'archived' && <Archived />}
    {network.status === 'deleted' && <Deleted />}
</>;

export default Status;
