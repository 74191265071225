import React, {useContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';

import Lang from '../../lang';
import Spinner from '../../ui/Spinner';
import {API_BASE_URL} from '../../config';
import FormGroup from '../../ui/FormGroup';

import SOSEmergencyLogo from '../../assets/images/central-screen/sos-emergency.png';
import PublicAlertLogo from '../../assets/images/central-screen/public-alert.png';
import NetworkAlertLogo from '../../assets/images/central-screen/network-alert.png';
import SafeAssistLogo from '../../assets/images/central-screen/safe-assist.png';

import SOSEmergencyFrame from '../../assets/images/central-screen/sos.svg';
import PublicAlertFrame from '../../assets/images/central-screen/public.svg';
import NetworkAlertFrame from '../../assets/images/central-screen/networks.svg';
import SafeAssistFrame from '../../assets/images/central-screen/assist.svg';

const AlertCentralScreenSettings = ({clientId, centralScreenSettings}) => {
  const {lang} = useContext(Lang);
  const keys = ['SOS_Emergency', 'Public_Alert', 'Network_Alert', 'Safe_Assist'];

  const {
    register,
    handleSubmit,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(centralScreenSettings);
  const [alert, setAlert] = useState(null);

  const getUploadUrl = async (filename, size) => {
    const body = {filename, size};
    const response = await axios.post(`${API_BASE_URL}/clients/${clientId}/upload-url`, body);
    return response.data.url;
  }

  const uploadByUrl = async (url, file) => {
    const response = await fetch(url, {
      headers: {
        'Content-Type': file.type,
      },
      method: 'PUT',
      body: file,
    });
    if (response.status !== 200 && response.status !== 201) {
      throw new Error("Failed to load the icon");
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const filesToRemove = new Set();

  const uploadSettigs = async (data) => {
    const body = {};
    const files = [];

    for (const key of keys) {
      if (data[`${key}Icon`].length) {
        const file = data[`${key}Icon`][0];
        files.push({file, key});
      }
      if (typeof data[`${key}Caption`] === 'string') {
        const caption = data[`${key}Caption`];
        body[key] = body[key] ? {...body[key], caption} : {caption};
      }
      if (filesToRemove.has(`${key}Img`)) {
        body[key] = body[key] ? {...body[key], removeFile: true} : {removeFile: true};
      }
    }

    if (!Object.keys(body).length && !files.length) {
      return;
    }

    await Promise.all(files.map(async ({file, key}) => {
      const image = await resizeFile(file);
      const url = await getUploadUrl(file.name, image.size);
      const {origin, pathname} = new URL(url);
      body[key].icon = `${origin}${pathname}`;
      await uploadByUrl(url, image);
    }));

    const response = await axios.put(`${API_BASE_URL}/clients/${clientId}/settings`, body);
    setSettings(response.data);
  }

  const save = data => {
    setLoading(true);
    uploadSettigs(data)
      .then(() => {
        setAlert({
          type: 'success',
          icon: 'check-circle-outline',
          text: 'Alert screen settings saved!',
        });
      })
      .catch(() => {
        setAlert({
          type: 'danger',
          icon: 'alert-circle-outline',
          text: 'Alert screen settings failed to save!',
        });
      })
      .finally(() => setLoading(false));
  }

  const preview = async (id, event) => {
    const image = await resizeFile(event.target.files[0]);
    document.getElementById(`${id}Img`).style.backgroundImage = `url(${URL.createObjectURL(image)})`;
    filesToRemove.delete(id);
  }

  const defaultImages = {
    'SOS_Emergency': SOSEmergencyLogo,
    'Public_Alert': PublicAlertLogo,
    'Safe_Assist': SafeAssistLogo,
    'Network_Alert': NetworkAlertLogo,
  };

  const defaultFrames = {
    'SOS_Emergency': `url(${SOSEmergencyFrame})`,
    'Public_Alert': `url(${PublicAlertFrame})`,
    'Safe_Assist': `url(${SafeAssistFrame})`,
    'Network_Alert': `url(${NetworkAlertFrame})`,
  }

  const previewImages = {
    'SOS_Emergency': `url(${settings.SOS_Emergency ? (settings.SOS_Emergency.icon || SOSEmergencyLogo) : SOSEmergencyLogo})`,
    'Public_Alert': `url(${settings.Public_Alert ? (settings.Public_Alert.icon || PublicAlertLogo) : PublicAlertLogo})`,
    'Safe_Assist': `url(${settings.Safe_Assist ? (settings.Safe_Assist.icon || SafeAssistLogo) : SafeAssistLogo})`,
    'Network_Alert': `url(${settings.Network_Alert ? (settings.Network_Alert.icon || NetworkAlertLogo) : NetworkAlertLogo})`,
  };

  const defaultCaptions = {
    'SOS_Emergency': settings.SOS_Emergency ? settings.SOS_Emergency.caption : '',
    'Public_Alert': settings.Public_Alert ? settings.Public_Alert.caption : '',
    'Safe_Assist' : settings.Safe_Assist ? settings.Safe_Assist.caption : '',
    'Network_Alert': settings.Network_Alert ? settings.Network_Alert.caption : '',
  }

  const reset = (input) => {
    document.getElementsByName(`${input}Icon`)[0].value = '';
    document.getElementsByName(`${input}Caption`)[0].value = null;
    document.getElementById(`${input}Img`).style.backgroundImage = `url(${defaultImages[input]})`;
    filesToRemove.add(`${input}Img`);
  }

  const Component = () => (
      <form onSubmit={handleSubmit(save)}>
        {alert &&
          <div className={`alert alert-${alert.type}`}>
            <i className={`mdi mdi-${alert.icon}`}/>
            {alert.text}
          </div>}
        <h5>File: PNG, Max-Size: 10MB, 160x160px</h5>
        <div className="form row">
          <div className="form-group col-sm-12 col-xl-4">
            <h3 style={{padding: '0.5em 0 0.5em 0'}}>SOS Emergency</h3>
            <div className="alert-central-frame" style={{backgroundImage: defaultFrames['SOS_Emergency']}}/>
            <div className="alert-central" style={{backgroundImage: previewImages['SOS_Emergency']}} id="SOS_EmergencyImg"/>
            <FormGroup
              inputRef={register}
              name="SOS_EmergencyIcon"
              title={lang.icon}
              type="file"
              onChange={(e) => preview("SOS_Emergency", e)}
            />
            <FormGroup
              inputRef={register}
              name="SOS_EmergencyCaption"
              title={lang.caption}
              maxLength={20}
              placeholder="SOS"
              value={defaultCaptions['SOS_Emergency']}
            />
            <div onClick={() => reset("SOS_Emergency")} className="btn btn-sm btn-danger">Reset</div>
            <h3 style={{padding: '0.5em 0 0.5em 0'}}>Public Alert</h3>
            <div className="alert-central-frame" style={{backgroundImage: defaultFrames['Public_Alert']}}/>
            <div className="alert-central" style={{backgroundImage: previewImages['Public_Alert']}} id="Public_AlertImg"/>
            <FormGroup
              inputRef={register}
              name="Public_AlertIcon"
              title={lang.icon}
              type="file"
              onChange={(e) => preview("Public_Alert", e)}
            />
            <FormGroup
              inputRef={register}
              name="Public_AlertCaption"
              placeholder="ALARM CENTER"
              title={lang.caption}
              maxLength={20}
              value={defaultCaptions['Public_Alert']}
            />
            <div onClick={() => reset("Public_Alert")} className="btn btn-sm btn-danger">Reset</div>
          </div>
          <div className="form-group col-sm-12 col-xl-4">
            <h3 style={{padding: '0.5em 0 0.5em 0'}}>Network Alert</h3>
            <div className="alert-central-frame" style={{backgroundImage: defaultFrames['Network_Alert']}}/>
            <div className="alert-central" style={{backgroundImage: previewImages['Network_Alert']}} id="Network_AlertImg"/>
            <FormGroup
              inputRef={register}
              name="Network_AlertIcon"
              title={lang.icon}
              type="file"
              onChange={(e) => preview("Network_Alert", e)}
            />
            <FormGroup
              inputRef={register}
              name="Network_AlertCaption"
              placeholder="NETWORKS"
              title={lang.caption}
              maxLength={20}
              value={defaultCaptions['Network_Alert']}
            />
            <div onClick={() => reset("Network_Alert")} className="btn btn-sm btn-danger">Reset</div>
            <h3 style={{padding: '0.5em 0 0.5em 0'}}>Safe Assist</h3>
            <div className="alert-central-frame" style={{backgroundImage: defaultFrames['Safe_Assist']}}/>
            <div className="alert-central" alt="Safe Assist Icon" style={{backgroundImage: previewImages['Safe_Assist']}} id="Safe_AssistImg"/>
            <FormGroup
              inputRef={register}
              name="Safe_AssistIcon"
              title={lang.icon}
              type="file"
              onChange={(e) => preview("Safe_Assist", e)}
            />
            <FormGroup
              inputRef={register}
              name="Safe_AssistCaption"
              placeholder="FOLLOW ME"
              title={lang.caption}
              maxLength={20}
              value={defaultCaptions['Safe_Assist']}
            />
            <div onClick={() => reset("Safe_Assist")} className="btn btn-sm btn-danger">Reset</div>
          </div>
        </div>
        <button className="btn btn-primary">
          {lang.save}
        </button>
      </form>
  )

  return (
    loading ? <Spinner/> : <Component/>
  );
};

export default AlertCentralScreenSettings;
