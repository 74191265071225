import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import TopBar from './ui/TopBar';
import SideBar from './ui/SideBar';
import Dashboard from './dashboard/Dashboard';
import ClientRouter from './clients/Router';
import DeviceRouter from './devices/Router';
import NetworkRouter from './networks/Router';
import UserRouter from './users/Router';

const Routes = (_props) => {
    return (
        <Router>
            <TopBar />

            <div className="container-fluid page-body-wrapper">
                <SideBar />

                <div className="main-panel">
                    <div className="content-wrapper">
                        <Route exact path="/">
                            <Dashboard />
                        </Route>
                        <Route path="/clients">
                            <ClientRouter />
                        </Route>
                        <Route path="/devices">
                            <DeviceRouter />
                        </Route>
                        <Route path="/networks">
                            <NetworkRouter />
                        </Route>
                        <Route path="/users">
                            <UserRouter />
                        </Route>
                    </div>
                </div>
            </div>
        </Router>
    )
}

export default Routes;
