import React, { useContext } from 'react';
import Lang from '../../lang';
import DataTable from '../../ui/DataTable';
import moment from 'moment';

const List = ({ logs }) => {
  const { lang } = useContext(Lang);

  return <DataTable loading={false} columns={columns(lang)} data={logs} />;
};

const timestampFormatter = (ts) => moment(ts).format('YYYY-MM-DD HH:mm');

const columns = (lang) => [
  {
    dataField: 'created_at',
    text: lang.created,
    sort: true,
    formatter: timestampFormatter,
  },
  {
    dataField: 'log',
    text: lang.integration_log_data,
  },
];

export default List;
