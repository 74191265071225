
import { useHistory } from 'react-router-dom';
import React, { useContext } from 'react';

import Lang from '../lang';
import Card from '../ui/Card';
import Form from './components/Form';


const New = (_props) => {
    const { lang } = useContext(Lang);
    let history = useHistory();

    return (
        <Card title={lang.add_client}>
            <hr />
            <Form onSave={({ id }) => history.push(`/clients/${id}`)} />
        </Card>
    )
};

export default New;
