import React, {useState, useContext, useMemo} from 'react';
import moment from 'moment';
import Lang from '../../lang';
import { API_BASE_URL, UAN_API_BASE_URL } from '../../config';
import useLoader from '../../hooks/loader';
import DataTable from '../../ui/DataTable';
import {getAppVersionCode} from "../../helpers";

const timestampFormatter = ts => ts ? moment(ts).format('YYYY-MM-DD HH:mm') : '';

const versionFormatter = (cell, row) => {
    if (!row.versionIndicator) {
        return cell;
    }

    return (
      <div className={`${row.versionIndicator} text-white p-2 w-fit-content`}>
          {cell}
      </div>
    );
};

const UserDevices = ({ user }) => {
    const { lang } = useContext(Lang);

    const columns = [
        {
            dataField: 'appVersion',
            text: lang.user_device_app_version,
            sort: true,
            formatter: versionFormatter
        },
        {
            dataField: 'manufacturer',
            text: lang.user_device_manufacturer,
            sort: true,
        },
        {
            dataField: 'model',
            text: lang.user_device_model,
            sort: true,
        },
        {
            dataField: 'os',
            text: lang.user_device_os,
            sort: true,
        },
        {
            dataField: 'created',
            text: lang.user_device_registered,
            sort: true,
            formatter: timestampFormatter,
        },
        {
            dataField: 'updated',
            text: lang.user_device_last_update,
            sort: true,
            formatter: timestampFormatter,
        },
    ];

    const [appVersion, setAppVersion] = useState(null);
    const [devices, setDevices] = useState([]);

    const loading = useLoader(
        `${API_BASE_URL}/users/${user.id}/user-devices`,
        setDevices);

    useLoader(`${UAN_API_BASE_URL}/api/version`, setAppVersion);

    const data = useMemo(() => {
        if (!appVersion) return devices;

        return devices.map((item) => ({
            ...item,
            versionIndicator: getAppVersionCode(item.appVersion, appVersion, item.os)
        }));
    }, [appVersion, devices]);

    return (
        <DataTable
            loading={loading}
            columns={columns}
            data={data}
            defaultSort={[{
                dataField: "created",
                order: "desc",
            }]} />
    )
}

export default UserDevices;
