import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Lang from '../lang';

import Form from './components/Form';

import Card from '../ui/Card';


const New = (_props) => {
    const { lang } = useContext(Lang);
    let history = useHistory();

    return (
        <Card title={lang.add_device}>
            <hr />
            <Form onSave={({ id }) => history.push(`/devices/${id}`)} />
        </Card>
    )
};

export default New;
