import React, { useContext, useState, useCallback } from 'react';
import axios from 'axios';

import Lang from '../../lang';
import { API_BASE_URL } from '../../config';

import NetworkSelector from '../../networks/components/Selector';


const ClientNetworkAdder = ({ clientId, onAdd, onDone }) => {
    const { lang } = useContext(Lang);

    const [ids, setIds] = useState([]);

    const add = useCallback(async () => {
        const res = await axios.put(
            `${API_BASE_URL}/clients/${clientId}/networks`,
            { networkIds: ids }
        )
        onAdd(res.data);
        onDone();
    }, [ids, clientId, onAdd, onDone]);

    return (
        <div className="row">
            <div className="col">
                <NetworkSelector
                    isMulti={true}
                    onChange={ns => setIds((ns || []).map(n => n.id))} />
            </div>
            <div className="col-auto">
                <button className="btn btn-success"
                    onClick={add}>
                    {lang.add_existing_network}
                </button>
            </div>
        </div>
    );
}

export default ClientNetworkAdder;
