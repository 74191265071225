import React, { useState } from 'react'

import Stats from './components/Stats';
import HistoryGraph from './components/HistoryGraph';
import HistoryTable from './components/HistoryTable';
import Subscriptions from './components/Subscriptions';


const Dashboard = (_props) => {
    const [historyData, setHistoryData] = useState({})
    return (
        <div className="row">
            <Stats />
            <HistoryGraph onChange={setHistoryData} />
            <Subscriptions />
            <HistoryTable data={historyData} />
        </div>
    );
}

export default Dashboard;
