import React, { useContext, useState } from 'react';

import Lang from '../lang';


const Destroyer = ({ onDestroy }) => {
    const { lang } = useContext(Lang);
    const [destroying, setDestroying] = useState(false);

    if (destroying) {
        return (
            <div className="btn-group">
                <button className="btn btn-sm btn-danger"
                    onClick={() => onDestroy()}>
                    {lang.remove}
                </button>
                <button
                    className="btn btn-sm"
                    onClick={() => setDestroying(false)}>
                    {lang.cancel}
                </button>
            </div>
        )
    }

    return (
        <div className="btn btn-sm btn-danger"
            onClick={() => setDestroying(true)}>
            <i className="mdi mdi-close" />
        </div>
    )
}

export default Destroyer;
