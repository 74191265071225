import React from 'react';

const SettingRowCaption = (props) => {
  const {
    text,
  } = props;

  return (
    <div className="form-row">
      <div className="col-md-12">
        <h4>
          {text}
        </h4>
      </div>
    </div>
  );
};

export default SettingRowCaption;
