import React, {useContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import Auth from '../../auth';
import Lang from '../../lang';
import {API_BASE_URL} from '../../config';
import Map from '../../geo/Map';
import FormGroup from '../../ui/FormGroup';
import CheckGroup from '../../ui/CheckGroup';
import {Mini, Medi, Maxi, Mega, Macro} from './Subscription';
import {Active, AwaitingActivation} from './Status';
import NetworkSelector from '../../networks/components/Selector';
import ClientSelector from '../../clients/components/Selector';
import SimpleSelect from "../../ui/SimpleSelect";
import { SuperAdmin, MultiClientAdmin, ClientAdmin, User } from './Role';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Form = ({user, onSave, clientId}) => {
  const {hasRole} = useContext(Auth);
  const {lang} = useContext(Lang);

  if (!user) user = defaultUser;

  const {
    register,
    handleSubmit,
    setValue,
    formState,
    errors,
    reset,
  } = useForm();

  const [networks, setNetworks] = useState([]);
  const [clients, setClients] = useState((user.clientIds || []));
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [role, setRole] = useState(user.roles ? user.roles[0] : 'user');

  const save = data => {
    setAlert(null);
    setSaving(true);

    // update user "state", so that after reset, it shows correct data.
    user = {...user, ...data};

    let req = user.id ?
      axios.put(`${API_BASE_URL}/users/${user.id}`, {...data, clients}) :
      axios.post(`${API_BASE_URL}/users`, {...data, networks});

    req.then(res => {
      setSaving(false);
      setAlert({
        type: 'success',
        icon: 'check-circle-outline',
        text: lang.user_save_success,
      });

      if (!data.stay) {
        onSave({...user, ...data, id: res.data.id});
      }

      reset({...user});
    }).catch(() => {
      setSaving(false);
      setAlert({
        type: 'danger',
        icon: 'alert-circle-outline',
        text: lang.user_save_error,
      });
    });
  }

  return (
    <div className="row">
      <form className="col-md-7" onSubmit={handleSubmit(save)}>
        {/*user.clientId={user.clientId}*/}
        {/*<br/>*/}
        {/*clientId={clientId}*/}
        {/*<br/>*/}
        {/*errors={Object.keys(errors || {}).join(',')}*/}
        <div className="form-row">
          {/* clientId WAS passed from parameter, lets just output it */}
          { clientId &&
            <input
              name="clientId"
              type="hidden"
              value={clientId}
              ref={register}/>
          }

          {/* clientId WAS NOT passed from parameter, lets allow the user to select it from the select box */}
          { !clientId &&
            <div className="form-group col-md-6">
              <label>{lang.client_affiliation}</label>
              <ClientSelector
                defaultValue={ user.clientId ? {id: user.clientId, name: user.clientName} : null }
                isDisabled={!hasRole('admin')}
                onChange={(curClient) => {
                  const newClientId = (curClient ? curClient.id : null);
                  user.clientId = newClientId;
                  setValue('clientId', newClientId, { shouldValidate: true });
                }}
              />
              <div className="input-group">
                <input
                  ref={hasRole('admin') ? register : register({required: lang.client_is_required})}
                  name='clientId'
                  type='text'
                  style={{display: 'none'}}
                  defaultValue={user.clientId}
                />
              </div>
              {errors.clientId &&
                <p className="error text-danger">{errors.clientId.message}</p>
              }
            </div>
          }

          {(role !== 'multi-client-admin')
            ? <div className="form-group col-md-6"></div>
            : <div className="form-group col-md-6">
                <FormGroup title={"Clients"}>
                  <ClientSelector
                    clientId={clientId}
                    isMulti={true}
                    defaultValue={(user.clientIds || []).map((id, i)=> ({id, value: id, name: user.clientNames[i]}))}
                    isDisabled={!hasRole('admin')}
                    onChange={c => {
                      setClients((c || []).map(client => client.id));
                      setDisabled(false);
                    }}/>
                </FormGroup>
          </div>}

          {/* Networks. Show if NEW user, for ANY role. */}
          {!user.id &&
            <FormGroup className="col-6" title={lang.networks}>
              <NetworkSelector
                clientId={clientId}
                onChange={ns => setNetworks((ns || []).map(n => n.id))}/>
            </FormGroup>
          }
        </div>
        <div className="form-row">
          <FormGroup
            className="col-md-6"
            name="email"
            inputRef={register({
              required: lang.user_email_error,
              pattern: {
                value: EMAIL_REGEX,
                message: lang.invalid_email_error,
              },
              validate: checkEmail(user.id, lang.email_exists_error),
            })}
            title={lang.email}
            value={user.email}
            error={errors.email}
            readOnly={!!user.id}
          />
          <FormGroup
            className="col-md-6"
            name="username"
            inputRef={register({required: lang.user_username_error})}
            title={lang.username}
            value={user.username}
            error={errors.username}
          />
          <FormGroup
            className="col-md-6"
            name="firstName"
            inputRef={register}
            title={lang.first_name}
            value={user.firstName}
          />
          <FormGroup
            className="col-md-6"
            name="lastName"
            inputRef={register}
            title={lang.last_name}
            value={user.lastName}
          />
          <FormGroup
            className="col-12"
            title={lang.user_subscription}>
            <CheckGroup
              type="radio"
              name="subscription"
              inputRef={register}
              value={user.subscription}
              options={{
                // micro: <Micro/>,
                mini: <Mini/>,
                medi: <Medi/>,
                maxi: <Maxi/>,
                mega: <Mega/>,
                macro: <Macro/>,
              }}
            />
          </FormGroup>
          <FormGroup
            className="col-md-6"
            title={lang.user_status}>
            <CheckGroup
              type="radio"
              name="status"
              inputRef={register}
              value={user.status}
              options={{
                active: <Active/>,
                'awaiting-activation': <AwaitingActivation/>,
              }}
            />
          </FormGroup>
          {user.id && hasRole('admin') &&
            <FormGroup
              className="col-md-6"
              title={"Role"}>
              <CheckGroup
                type="radio"
              onChange={e => setRole(e.target.value)}
                name="role"
                inputRef={register}
                value={user.roles ? user.roles[0] : 'user'}
                options={{
                  admin: <SuperAdmin/>,
                  'multi-client-admin': <MultiClientAdmin/>,
                  'client-admin': <ClientAdmin/>,
                  'user': <User/>
                }}
              />
            </FormGroup>
          }
          <SimpleSelect
            className={'col-md-12'}
            name="language"
            title={lang.the_language}
            inputRef={register}
            defaultValue={user.language}
            options={{en: lang.language_en, sv: lang.language_sv, es: lang.language_es}}
            onChange={(e) => user.language = e.target.value}
          />
        </div>
        <div className="form-row">
          <div className="col">
            <button className="btn btn-primary"
                    disabled={disabled && (!formState.dirty || saving || (Object.keys(errors).length > 0))}
                    onClick={() => setValue('stay', '')}>
              {lang.save}
            </button>
            {' '}
            {!user.id &&
              <>
                <input
                  style={{display: "none"}}
                  name="stay"
                  defaultValue=""
                  ref={register}/>
                <button className="btn btn-inverse-primary"
                        disabled={!formState.dirty || saving || (Object.keys(errors).length > 0)}
                        onClick={() => setValue('stay', 'stay')}>
                  {saving && <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"/>}
                  {lang.user_save_and_new}
                </button>
              </>}
          </div>
          <div className="col-md-auto">
            {saving &&
              <div className="spinner-border"/>}
            {alert &&
              <div className={`alert alert-${alert.type}`}>
                <i className={`mdi mdi-${alert.icon}`}/>
                {alert.text}
              </div>}
          </div>
        </div>
      </form>
      <div className="col-md-5">
        {user.id &&
          <Map lat={user.lat} lng={user.lng}/>
        }
      </div>
    </div>
  );
}

export default Form;

const checkEmail = (userId, message) => async email => {
  if (EMAIL_REGEX.test(email)) {
    let res = await axios.get(`${API_BASE_URL}/users/search`, {
      params: {email}
    });

    let users = (res.data || []).filter(u => u.id !== userId);
    if (users.length > 0) {
      return message;
    }
  }
};


const defaultUser = {
  email: '',
  username: '',
  firstName: '',
  lastName: '',
  subscription: 'medi',
  status: 'active',
  clientName: '',
  clientId: '',
};
