import React, { useState, useContext } from 'react';
import Form from './components/Form';
import List from './components/List';
import Logs from './components/Logs';
import Lang from '../lang';
import axios from 'axios';
import { UAN_API_BASE_URL } from '../config';
import { useApiV2Loader } from '../hooks/loader';
import Spinner from '../ui/Spinner';
import { v2RefreshToken } from '../auth/index';

const Integrations = ({ networkId }) => {
  const { lang } = useContext(Lang);
  const [integrations, setIntegrations] = useState();

  const loading = useApiV2Loader(
    `${UAN_API_BASE_URL}/v2/integrations/${networkId}`,
    setIntegrations
  );

  const [edit, setEdit] = useState();
  const [create, setCreate] = useState(false);
  const [logs, setLogs] = useState();

  if (loading) return <Spinner />;

  const reloadTable = () => {
    setEdit(false);
    setCreate(false);

    const uan_api = axios.create({
      // quick solution to call v2 endpoints
      baseURL: UAN_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('uan-api-access-token')}`,
      },
    });

    uan_api.interceptors.response.use((res) => res, v2RefreshToken);

    const load = async () => {
      const res = await uan_api.get(`/v2/integrations/${networkId}`);
      setIntegrations(res.data);
    };

    load();
  };

  return (
    <>
      <button
        className="btn btn-success"
        onClick={() => {
          setEdit(false);
          setCreate(true);
        }}
      >
        {lang.create_integration}
      </button>

      <List
        setEdit={(integration) => {
          setCreate(false);
          setEdit(integration);
        }}
        setLogs={(integration) => {
          setLogs(integration);
        }}
        onDelete={reloadTable}
        networkId={networkId}
        integrations={integrations}
        loading={loading}
      />

      {edit && (
        <>
          <hr />
          <Form
            onSave={reloadTable}
            integration={edit}
            networkId={networkId}
            isEdit
          />
        </>
      )}

      {create && (
        <>
          <hr />
          <Form onSave={reloadTable} networkId={networkId} />
        </>
      )}

      {logs && (
        <>
          <hr />
          <h2> Logs </h2>
          <Logs logs={logs} />
        </>
      )}
    </>
  );
};

export default Integrations;
