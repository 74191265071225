import React, { useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { API_BASE_URL } from '../../config';
import Lang from '../../lang';
import useLoader from '../../hooks/loader';

import Type from './Type';
import TypeSelect from './TypeSelect';
import UserForm from './UserForm';
import DataTable from '../../ui/DataTable';
import Destroyer from '../../ui/Destroyer';
import UserStatus from '../../users/components/Status';
import UserDeviceStatus from '../../users/components/UserDeviceStatus';


const NetworkMembers = ({ networkId }) => {
    const { lang } = useContext(Lang);
    const history = useHistory();

    const [members, setMembers] = useState([]);
    const loading = useLoader(`${API_BASE_URL}/networks/${networkId}/users`, setMembers);

    const onClick = useCallback((e, c, i, row) => {
        history.push(`/users/${row.userId}`);
    }, [history])

    const onDestroy = useCallback(async (id) => {
        await axios.delete(
            `${API_BASE_URL}/networks/${networkId}/users/${id}`
        );
        setMembers(members.filter(m => m.userId !== id))
    }, [networkId, members, setMembers]);

    return (
        <>
            <UserForm
                networkId={networkId}
                onSave={ms => setMembers(ms.concat(members))} />
            <hr />
            <DataTable
                keyField='userId'
                loading={loading}
                data={members}
                columns={columns(
                    networkId,
                    onClick,
                    onDestroy,
                    lang
                )} />
        </>
    )
};

const timestampFormatter = ts => moment(ts).format('YYYY-MM-DD HH:mm');

const columns = (networkId, onClick, onDestroy, lang) => [
    {
        dataField: 'userId',
        text: 'ID',
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'firstName',
        text: lang.first_name,
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'lastName',
        text: lang.last_name,
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'email',
        text: lang.email,
        sort: true,
        events: { onClick },
        style: { cursor: 'pointer' },
    },
    {
        dataField: 'joined',
        text: lang.network_member_joined,
        sort: true,
        formatter: timestampFormatter,
    },
    {
        dataField: 'device_last_accessed',
        text: lang.user_device_is_logged_in,
        sort: true,
        formatter: (_, row) => (<UserDeviceStatus user={row} />),
    },
    {
        dataField: 'memberType',
        text: '',
        formatter: (_, row) => {
            if (row.memberType === 'owner')
                return <Type member={row} />;

            return <TypeSelect
                networkId={networkId}
                member={row} />;
        },
    },
    {
        dataField: 'status',
        text: '',
        formatter: (_, row) => (<UserStatus user={row} />),
    },
    {
        dataField: 'tools',
        isDummyField: true,
        text: '',
        formatter: (_, row) => {
            if (row.memberType === 'owner') return '';
            return <Destroyer onDestroy={() => onDestroy(row.userId)} />;
        },
    },
];


export default NetworkMembers;
