import React, {useContext} from 'react';
import Lang from "../../lang";

const SettingRowCheckbox = (props) => {
  const {lang} = useContext(Lang);

  const {
    name,
    checked,
    onChange,
  } = props;

  return (
    <div className="form-row">
      <div className="col-md-3">
        <label className="padding-top-7">{lang[name] || name}</label>
      </div>
      <div className="col-md-1">
        <div className="form-inline margin-right-left-47">
          <div className="form-group form-check">
            <label className="form-check-label">
              <input
                name={name}
                type="checkbox"
                checked={checked}
                // defaultChecked={checked}
                onChange={onChange}
              />
              <i className="input-helper"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingRowCheckbox;
