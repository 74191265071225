import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios';
import moment from 'moment';
import { Line } from 'react-chartjs-2'

import { API_BASE_URL } from '../../config';
import Lang from '../../lang'

import Card from '../../ui/Card'
import CardTitle from '../../ui/CardTitle'


const ranges = [
    { type: 'daily', count: 30, display: 30, lang: 'days' },
    { type: 'weekly', count: 12, display: 3, lang: 'months' },
    { type: 'monthly', count: 12, display: 1, lang: 'year' },
    { type: 'monthly', count: 36, display: 3, lang: 'years' },
    { type: 'monthly', count: 60, display: 5, lang: 'years' },
];

const colors = {
    users: '#fc5286',
    clients: '#764ba2',
    devices: '#f6d365',
    networks: '#0ba360',
    incidents: '#1e3c72',
    comments: '#c471f5',
}

const HistoryGraph = ({ onChange }) => {
    const { lang } = useContext(Lang);

    const [range, setRange] = useState(1);
    const [data, setData] = useState({})

    useEffect(() => {
        const r = ranges[range];
        axios.get(`${API_BASE_URL}/stats/counts/${r.type}?count=${r.count}`)
            .then(res => {
                let data = res.data;
                let timestamps = res.data.timestamps.map(
                    ts => moment(ts).format('YYYY-MM-DD'));

                setData({
                    labels: timestamps,
                    datasets: Object.entries(data.counts).map(([type, data]) => {
                        return {
                            data,
                            label: lang[type],
                            backgroundColor: colors[type],
                            borderColor: colors[type],
                        };
                    })
                });
                onChange({ timestamps, counts: data.counts });
            })
    }, [range, setData, lang, onChange])

    return (
        <Card className="col-lg-6">
            <Line
                data={data}
                options={{
                    cutoutPercentage: 70,
                    legend: { display: true, position: 'top' },
                    elements: { line: { fill: false } }
                }} />

            <CardTitle
                title={lang.history_chart_title}
                description={lang.history_chart_description} />

            <div className="d-flex justify-content-center">
                {ranges.map((r, i) => <button
                    key={i}
                    className={`btn btn-${range !== i ? 'outline-' : ''}primary mx-2`}
                    onClick={() => setRange(i)}>
                    {r.display} {lang[r.lang]}
                </button>)}
            </div>
        </Card>
    )
}

export default HistoryGraph;
