import React, {useState, useContext} from 'react';
import Lang from '../../lang';
import {API_BASE_URL} from '../../config';
import useLoader from '../../hooks/loader';
import {NotificationSettingsCategoryCaptionsArr} from "../../constants";
import axios from "axios";

const NotificationSettings = ({user}) => {
  const {lang} = useContext(Lang);

  const [notificationSettings, setNotificationSettings] = useState([]);
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState(null);

  useLoader(`${API_BASE_URL}/users/${user.id}/notification_settings`, setNotificationSettings);

  function updateNotificationSetting(category, key, value) {
    notificationSettings[category][key] = value;

    // setting Push to false, should make Sound false as well
    if (key === 'isPush' && !value) {
      notificationSettings[category].isSound = false;
    }

    setNotificationSettings([...notificationSettings]);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setAlert(null);
    setSaving(true);

    let req = axios.put(`${API_BASE_URL}/users/${user.id}/notification_settings`, notificationSettings);

    req.then(res => {
      setSaving(false);
      setAlert({ type: 'success', icon: 'check-circle-outline', text: lang.success });
    }).catch(() => {
      setSaving(false);
      setAlert({ type: 'danger', icon: 'alert-circle-outline', text: lang.error });
    });
 }

  return (
    <div>
      <div className="react-bootstrap-table">
        <form>
          <table className="table table-hover">
            <thead>
            <tr>
              <th></th>
              {['email', 'push', 'sound'].map((curCaption) =>
                <th className="text-align-center" key={curCaption}>{lang[`user_notification_${curCaption}`]}</th>
              )}
            </tr>
            </thead>
            <tbody>
            {Object.entries(notificationSettings).map(([curCategory, curRow]) =>
              <tr key={curCategory}>
                <td>{NotificationSettingsCategoryCaptionsArr[curCategory]}</td>
                {['isEmail', 'isPush', 'isSound'].map((curKey) =>
                  <td key={`${curCategory}-${curKey}`}>
                    <div className="form-inline margin-right-left-47">
                      <div className="form-group form-check">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            checked={curRow[curKey]}
                            disabled={curKey === 'isSound' && !curRow.isPush} //Sound should be disabled if Push is off
                            onChange={ev => updateNotificationSetting(curCategory, curKey, ev.target.checked)}
                          />
                          <i className="input-helper"/>
                        </label>
                      </div>
                    </div>
                  </td>
                )}
              </tr>
            )}
            </tbody>
          </table>
          <center>
            <button className="btn btn-primary" onClick={handleSubmit}>{lang.save}</button>
            <span className="col-md-auto">
              {saving && <span className="spinner-border"/>}
              {alert &&
                <span className={`alert alert-${alert.type}`}>
                  <i className={`mdi mdi-${alert.icon}`}/>
                  {alert.text}
                </span>}
            </span>
          </center>
        </form>
      </div>
    </div>
  )
}

export default NotificationSettings;
