import React, { useContext, useCallback, useState } from 'react';
import axios from 'axios';
import { UAN_API_BASE_URL } from '../../config';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import Styles from '../../styles/Select';
import { SIACodes } from '../../devices/components/sia-codes';
import { v2RefreshToken } from '../../auth/index';

import Lang from '../../lang';

import FormGroup from '../../ui/FormGroup';

const Form = ({ integration, networkId, onSave, isEdit }) => {
  const { lang, l } = useContext(Lang);
  const { register, handleSubmit, formState, errors, setValue } = useForm();
  if (!integration) integration = defaultIntegration;

  const [saving, setSaving] = useState(false);
  const [kind, setKind] = useState(integration.kind);
  const [sia_code_create, setSiaCodeCreate] = useState(integration.settings.sia_code_create);
  const [sia_code_confirm, setSiaCodeConfirm] = useState(integration.settings.sia_code_confirm);
  const [sia_code_recall, setSiaCodeRecall] = useState(integration.settings.sia_code_recall);
  const [sia_code_restore, setSiaCodeRestore] = useState(integration.settings.sia_code_restore);

  const options = [
    { value: 'manitou', label: 'Manitou' },
    { value: 'shortxml', label: 'ShortXML' },
  ];

  const siaCodeOptions = Object.keys(SIACodes).map((code) => ({
    value: code,
    label: `${code} - ${SIACodes[code][l]}`,
  }));

  const uan_api = axios.create({
    // quick solution to call v2 endpoints
    baseURL: UAN_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('uan-api-access-token')}`,
    },
  });

  uan_api.interceptors.response.use((res) => res, v2RefreshToken);

  const save = useCallback(
    async (data) => {
      setSaving(true);
      if (isEdit) {
        await uan_api.put(`/v2/integrations/${networkId}/${integration.id}`, {...data});
      } else {
        await uan_api.post(`/v2/integrations/${networkId}`, {...data});
      }
      setSaving(false);
      onSave();
    },
    [networkId, uan_api, isEdit, onSave, integration]
  );

  return (
    <div className="row">
      <form className="col-md-4" onSubmit={handleSubmit(save)}>
        <div className="form-row">
          <FormGroup
            title={lang.integration_kind}
            className="col-md-12"
            error={errors.kind}
          >
            <input
              name="kind"
              type="hidden"
              value={kind}
              ref={register({ required: lang.integration_kind_required })}
            />

            <Select
              className="form-control"
              options={options}
              styles={Styles}
              isClearable
              onChange={(o) => {
                if (!o || !o.value) {
                  setKind('');
                  setValue('kind', '');
                  return;
                }
                setKind(o.value);
                setValue('kind', o.value);
              }}
              value={options.find((o) => o.value === kind)}
            />
          </FormGroup>
        </div>

        <div className="form-row">
          <FormGroup
            className="col-md-7"
            name="ip"
            inputRef={register({ required: lang.integration_ip_required })}
            title={lang.integration_ip}
            value={integration.ip}
            error={errors.ip}
          />
          <FormGroup
            className="col-md-5"
            name="port"
            inputRef={register({ required: lang.integration_port_required })}
            title={lang.integration_port}
            value={integration.port}
            error={errors.port}
          />
          <FormGroup
            className="col-md-12"
            name="description"
            inputRef={register({
              required: lang.integration_description_required,
            })}
            title={lang.integration_description}
            value={integration.description}
            error={errors.description}
          />
        </div>

        <div className="form-row">
          {kind === 'manitou' && (
            <FormGroup
              className="col-md-12"
              name="settings.line"
              inputRef={register({ required: lang.integration_line_required })}
              title={lang.integration_line}
              value={integration.settings.line}
              error={
                errors.settings && errors.settings.line
                  ? errors.settings.line
                  : null
              }
            />
          )}

          {kind === 'shortxml' && (
            <>
              <FormGroup
                className="col-md-12"
                name="settings.pin"
                inputRef={register({ required: lang.integration_pin_required })}
                title={lang.integration_pin}
                value={integration.settings.pin}
                error={
                  errors.settings && errors.settings.pin
                    ? errors.settings.pin
                    : null
                }
              />
              <FormGroup
                className="col-md-12"
                name="settings.uid"
                inputRef={register({ required: lang.integration_uid_required })}
                title={lang.integration_uid}
                value={integration.settings.uid}
                error={
                  errors.settings && errors.settings.uid
                    ? errors.settings.uid
                    : null
                }
              />
            </>
          )}
        </div>

        <div className="form-row">
          <FormGroup
            className="col-md-12"
            name="settings.sia_code_create"
            title={lang.integration_sia_code_create}
            value={integration.settings.sia_code_create}
            error={
              errors.settings && errors.settings.sia_code_create
                ? errors.settings.sia_code_create
                : null
            }
          >
            <input
              name="settings.sia_code_create"
              type="hidden"
              value={sia_code_create}
              ref={register()}
            />

            <Select
              className="form-control"
              options={siaCodeOptions}
              styles={Styles}
              isClearable
              onChange={(o) => {
                if (!o || !o.value) {
                  setSiaCodeCreate('');
                  setValue('settings.sia_code_create', '');
                  return;
                }
                setSiaCodeCreate(o.value);
                setValue('settings.sia_code_create', o.value);
              }}
              value={siaCodeOptions.find((o) => o.value === sia_code_create)}
            />
          </FormGroup>
          <FormGroup
            className="col-md-12"
            name="settings.sia_code_confirm"
            title={lang.integration_sia_code_confirm}
            value={integration.settings.sia_code_confirm}
            error={
              errors.settings && errors.settings.sia_code_confirm
                ? errors.settings.sia_code_confirm
                : null
            }
          >
            <input
              name="settings.sia_code_confirm"
              type="hidden"
              value={sia_code_confirm}
              ref={register()}
            />

            <Select
              className="form-control"
              options={siaCodeOptions}
              styles={Styles}
              isClearable
              onChange={(o) => {
                if (!o || !o.value) {
                  setSiaCodeConfirm('');
                  setValue('settings.sia_code_confirm', '');
                  return;
                }
                setSiaCodeConfirm(o.value);
                setValue('settings.sia_code_confirm', o.value);
              }}
              value={siaCodeOptions.find((o) => o.value === sia_code_confirm)}
            />
          </FormGroup>
          <FormGroup
            className="col-md-12"
            name="settings.sia_code_restore"
            title={lang.integration_sia_code_restore}
            value={integration.settings.sia_code_restore}
            error={
              errors.settings && errors.settings.sia_code_restore
                ? errors.settings.sia_code_restore
                : null
            }
          >
            <input
              name="settings.sia_code_restore"
              type="hidden"
              value={sia_code_restore}
              ref={register()}
            />

            <Select
              className="form-control"
              options={siaCodeOptions}
              styles={Styles}
              isClearable
              onChange={(o) => {
                if (!o || !o.value) {
                  setSiaCodeRestore('');
                  setValue('settings.sia_code_restore', '');
                  return;
                }
                setSiaCodeRestore(o.value);
                setValue('settings.sia_code_restore', o.value);
              }}
              value={siaCodeOptions.find((o) => o.value === sia_code_restore)}
            />
          </FormGroup>
          <FormGroup
            className="col-md-12"
            name="settings.sia_code_recall"
            title={lang.integration_sia_code_recall}
            value={integration.settings.sia_code_recall}
            error={
              errors.settings && errors.settings.sia_code_recall
                ? errors.settings.sia_code_recall
                : null
            }
          >
            <input
              name="settings.sia_code_recall"
              type="hidden"
              value={sia_code_recall}
              ref={register()}
            />

            <Select
              className="form-control"
              options={siaCodeOptions}
              styles={Styles}
              isClearable
              onChange={(o) => {
                if (!o || !o.value) {
                  setSiaCodeRecall('');
                  setValue('settings.sia_code_recall', '');
                  return;
                }
                setSiaCodeRecall(o.value);
                setValue('settings.sia_code_recall', o.value);
              }}
              value={siaCodeOptions.find((o) => o.value === sia_code_recall)}
            />
          </FormGroup>
        </div>

        <div className="form-row">
          <div className="col">
            <button
              className="btn btn-primary"
              disabled={!formState.dirty || saving}
            >
              {isEdit ? lang.update_integration : lang.create_integration}
            </button>
          </div>
          <div className="col-md-auto">
            {saving && <div className="spinner-border" />}
            {alert && (
              <div className={`alert alert-${alert.type}`}>
                <i className={`mdi mdi-${alert.icon}`} />
                {alert.text}
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

const defaultIntegration = {
  id: '',
  kind: 'manitou',
  ip: '',
  port: '',
  description: '',
  settings: {
    line: '',
  },
};

export default Form;
