import React from 'react'
import { Marker } from 'mapbox-gl';
import ReactMapboxGl, { MapContext } from 'react-mapbox-gl';


const MapboxMap = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiaW50cmFzZW56ZS10ZWNoIiwiYSI6ImNrODJ0OWNoNjBsa20zbm93cng5enVrYnMifQ.T4oFSUqTSFBSbzst3L_7Ew'
});

const Map = ({ lat, lng, onChange, onStyleLoad }) => {
    return (
        <MapboxMap
            style="mapbox://styles/mapbox/satellite-streets-v12"
            onStyleLoad={onStyleLoad}
            containerStyle={{
                height: '100%',
                width: '100%'
            }}
            center={[
                lng || 0,
                lat || 0
            ]}>
            <MapContext.Consumer>
                {map => <DraggableMarker
                    map={map}
                    lng={lng || 0}
                    lat={lat || 0}
                    onDrag={onChange}
                />}
            </MapContext.Consumer>
        </MapboxMap>
    );
}

class DraggableMarker extends React.Component {
    marker = null;

    pin = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMTIgMkM4LjEzIDIgNSA1LjEzIDUgOWMwIDUuMjUgNyAxMyA3IDEzczctNy43NSA3LTEzYzAtMy44Ny0zLjEzLTctNy03em0wIDkuNWMtMS4zOCAwLTIuNS0xLjEyLTIuNS0yLjVzMS4xMi0yLjUgMi41LTIuNSAyLjUgMS4xMiAyLjUgMi41LTEuMTIgMi41LTIuNSAyLjV6IiBzdHlsZT0iZmlsbDojZmM1Mjg2OyIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4K';


    componentDidMount() {
        const { map, onDrag, lng, lat } = this.props;

        this.marker = new Marker({
            anchor: "bottom",
            draggable: !!onDrag,
            element: this.refs.wrapper
        })
            .setLngLat([lng, lat])
            .addTo(map)

        this.marker.on('dragend', () => {
            if (this.props.onDrag) {
                const { lat, lng } = this.marker.getLngLat();
                this.props.onDrag(lat, lng);
            }
        });
    }

    componentWillUnmount() {
        this.marker && this.marker.remove();
    }

    componentDidUpdate() {
        const { onDrag, lng, lat } = this.props;
        this.marker.setDraggable(!!onDrag);
        this.marker.setLngLat([lng, lat]);
    }

    render() {
        return (
            <div ref="wrapper">
                <img src={this.pin} alt="pin" />
            </div>
        );
    }
}

export default Map;
