import React, { useContext } from 'react';

import Lang from '../../lang';

const badge = (k, t) => () => {
    const { lang } = useContext(Lang);
    return (
        <span className={`badge badge-outline-${t}`}>
            {lang[k]}
        </span>
    );
}

export const Active = badge('user_active', 'success');
export const AwaitingActivation = badge('user_awaiting_activation', 'warning');

const Status = ({ user }) => <>
    {user.status === 'awaiting-activation' && <AwaitingActivation />}
    {user.status === 'active' && <Active />}
</>;

export default Status;
