import React, { useContext } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginator from 'react-bootstrap-table2-paginator';
import Toolkit, { Search } from 'react-bootstrap-table2-toolkit';

import Spinner from './Spinner';
import Lang from '../lang';

const { SearchBar } = Search;

const paginator = Paginator({
    sizePerPage: 25,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="btn-group btn-group-sm">
            {options.map(option => (
                <button
                    key={option.text}
                    type="button"
                    className={
                        `btn btn-${currSizePerPage === `${option.page}` ? "primary" : "inverse-secondary"}`
                    }
                    onClick={() => onSizePerPageChange(option.page)}>
                    {option.text}
                </button>
            ))}
        </div>
    ),
});

const DataTable = (props) => {
    const { lang } = useContext(Lang);

    const {
        loading,
        data,
        columns,
        defaultSort,
        selectRow,
    } = props

    let rowStyles = {};
    let rowEvents = {};
    if (props.onClick) {
        rowEvents.onClick = (_, row) => props.onClick(row);
        rowStyles.cursor = 'pointer';
    }

    return (
        <Toolkit
            keyField={props.keyField || 'id'}
            bootstrap4
            data={data}
            columns={columns}
            search >
            {tk => (
                <>
                    <div className="row justify-content-end">
                        {props.children}
                        <div className="col-md-6">
                            <SearchBar
                                placeholder={lang.search}
                                {...tk.searchProps} />
                        </div>
                    </div>
                    <BootstrapTable
                        bordered={false}
                        hover
                        selectRow={selectRow}
                        defaultSorted={defaultSort}
                        noDataIndication={loading ?
                            () => <Spinner /> :
                            lang.no_matches}
                        pagination={paginator}
                        rowEvents={rowEvents}
                        rowStyle={rowStyles}
                        {...tk.baseProps} />
                </>
            )}
        </Toolkit>
    )
};

export default DataTable;
