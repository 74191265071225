import React, { useContext, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'

import { API_BASE_URL } from '../../config';
import Lang from '../../lang'

import useLoader from '../../hooks/loader';

import Card from '../../ui/Card'
import CardTitle from '../../ui/CardTitle'


const Legend = ({ percent, name, gradient }) => {
    return (
        <li>
            <h2 className="mb-3">{percent}%</h2>
            <div className="legend-content">
                <span className="legend-dots mini" style={{
                    backgroundColor: gradient[0]
                }}></span>
                {name}
            </div>
        </li>
    )
}

const toPercent = v => {
    if (isNaN(v)) return 0;
    v = v * 100;
    return v.toFixed(v < 1 ? 1 : 0);
}

const Subscriptions = (_props) => {
    const { lang } = useContext(Lang);

    const [counts, setCounts] = useState({
        micro: 0,
        mini: 0,
        medi: 0,
        maxi: 0,
        mega: 0,
        macro: 0,
    });

    useLoader(`${API_BASE_URL}/stats/subscriptions`, setCounts);

    const total = Object.values(counts).reduce((a, b) => a + b, 0);

    const dataBuilder = (canvas) => {
        const ctx = canvas.getContext("2d");

        const gds = Object.keys(counts).map((key) => {
            const [from, to] = gradients[key];
            let g = ctx.createLinearGradient(0, 100, 20, 400);
            g.addColorStop(0, from);
            g.addColorStop(1, to);
            return g;
        })

        return {
            labels: Object.keys(counts),
            datasets: [{
                data: Object.values(counts),
                borderWidth: 2,
                borderColor: 'transparent',
                weight: 1,
                backgroundColor: gds,
            }]
        };
    }

    return (
        <Card className="col-lg-6">
            <Doughnut
                data={dataBuilder}
                options={{
                    cutoutPercentage: 70,
                    legend: { display: true, position: 'top' }
                }} />

            <CardTitle
                title={lang.subscriptions_chart_title}
                description={lang.subscriptions_chart_description} />

            <div className="chartjs-legend traffic-chart-legend">
                <ul>
                    {Object.keys(counts).map(k =>
                        <Legend
                            key={k}
                            name={k}
                            percent={toPercent(counts[k] / total)}
                            gradient={gradients[k]} />)}
                </ul>
            </div>
        </Card>
    )
}

const gradients = {
    micro: ['rgba(102, 126, 234, 1)', 'rgba(118, 75,  162, 1)'],
    mini: ['rgba(11,  163, 96,  1)', 'rgba(60,  186, 146, 1)'],
    medi: ['rgba(30,  60,  114, 1)', 'rgba(22,  85,  178, 1)'],
    maxi: ['rgba(253, 160, 133, 1)', 'rgba(246, 211, 101, 1)'],
    mega: ['rgba(252, 82,  134, 1)', 'rgba(251, 170, 162, 1)'],
    macro: ['rgba(196, 113, 245, 1)', 'rgba(250, 113, 205, 1)']

};

export default Subscriptions;
