import React, {useContext} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import Lang from '../lang';
import List from './components/List';
import Card from '../ui/Card';

const Index = (_props) => {
  const {lang} = useContext(Lang);
  const {url} = useRouteMatch();
  let history = useHistory();

  const show = (user) => history.push(`${url}/${user.id}`);
  const add = () => history.push(`${url}/new`);

  return (
    <Card>
      <List onClick={show}>
        <div className="col-md-6">
          <button className="btn btn-success" onClick={add}>
            {lang.add_user}
          </button>
        </div>
      </List>
    </Card>
  );
};

export default Index;
