// this is not enum, since enums are not supported here.
export const NotificationSettingsCategoryArr = [
    'SendingMessage',
    'DeviceAlert',
    'Emergency',
    'AlertCenter',
    'NetworkAlert',
    'SafeAssist',
    'LocalAlarm'
];

export const NotificationSettingsCategoryCaptionsArr = [
    'Message',
    'Device Alert',
    'Emergency Alert',
    'Alert Central',
    'Network Alert',
    'Safe Assist Alert',
    'Local Alert'
];
