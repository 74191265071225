/*
    UAN Backoffice Translation Strings - Spanish
    Please take note of case when translating.
    Please add same translation to other lang files.
    Put them into the same line number.
*/
export default {
  language: 'es',
  //Global
  login_welcome: 'Bienvenido a UAN-Safe',
  page_title: 'UAN-Safe Backoffice',
  dashboard: 'Cuadro de mando',
  premium: 'Premium',
  users: 'Usuarios',
  remove: 'Eliminar',
  cancel: 'Cancelar',
  search: 'Buscar',
  copy_to_clipboard: 'Copiar en el portapapeles',
  select_or_search: 'Seleccione o escriba para buscar...',
  no_matches: 'Resultado vacío',
  locate: 'Localizar',
  total: 'Total',
  received: 'Recibido',
  enabled: 'Habilitado',
  disabled: 'Deshabilitado',
  first_name: 'Nombre',
  last_name: 'Apellido',
  email: 'E-mail',
  invalid_email_error: 'Dirección de correo electrónico no válida',
  email_exists_error: 'La e-mail electrónico ya existe',
  admin: 'Admin',
  added: 'Añadido',
  new: 'Nuevo',
  edit: 'Editar',
  delete: 'Borrar',
  new_backoffice: 'Vista de mapa',
  new_backoffice_devices: '¡Ahora puede administrar dispositivos en nuestra vista de mapa! Para probarlo, haga clic aquí.',
  new_backoffice_networks: '¡Ahora puede administrar redes en nuestra vista de mapa! Para probarlo, haga clic aquí.',
  // Integrations
  integrations: 'Integraciones',
  create_integration: 'Crear integración',
  update_integration: 'Actualizar integración',
  delete_integration: 'Eliminar integración',
  integration_ip: 'IP',
  integration_port: 'Puerto',
  integration_manitou: 'Manitou',
  integration_shortxml: 'ShortXML',
  integration_kind: 'Tipo',
  integration_line: 'Línea',
  integration_sia_code_create: 'Código SIA (Nuevo incidente)',
  integration_sia_code_confirm: 'Código SIA (Incidente confirmado)',
  integration_sia_code_recall: 'Código SIA (Incidente recordado)',
  integration_sia_code_restore: 'Código SIA (Incidente restaurado)',
  integration_uid: 'ID de cuenta / ID de hardware / ID de cliente',
  integration_pin: 'Código PIN',
  integration_description: 'Descripción (para fines de registro)',
  integration_saved: 'Integración guardada',
  integration_save_error: 'Se ha producido un error al guardar la integración',
  integration_delete_error: 'Se ha producido un error al eliminar la integración',
  integration_delete_success: 'Integración eliminada',
  integration_ip_required: 'Se requiere IP',
  integration_port_required: 'Se requiere Puerto',
  integration_kind_required: 'Se requiere Tipo',
  integration_line_required: 'Se requiere Línea',
  integration_uid_required: 'Se requiere UID',
  integration_pin_required: 'Se requiere código PIN',
  integration_description_required: 'Se requiere descripción',
  logs: 'Registros',
  // dashboard
  devices: 'Dispositivos',
  clients: 'Clientes',
  networks: 'Redes',
  network: 'Red',
  incidents: 'Incidentes',
  comments: 'Comentarios',
  history_chart_title: 'Historial de estadísticas',
  history_chart_description: 'Ver todos los números de los últimos días/meses/años',
  subscriptions_chart_title: 'Distribución de suscripciones',
  subscriptions_chart_description: 'Distribución de usuarios registrados de Intrasenze por plan de suscripción',
  year: 'año',
  years: 'Años',
  month: 'mes',
  months: 'Meses',
  week: 'semana',
  weeks: 'Semanas',
  day: 'día',
  days: 'Días',
  reports: 'Informes',
  stat: 'Estad.', // abbrevation for Statistic
  password: 'Contraseña',
  // users
  add_user: 'Agregar usuario',
  add_user_to: 'Agregar usuario a',
  add_new_user: 'Agregar nuevo usuario',
  add_existing_user: 'Agregar usuario existente',
  username: 'Nombre de usuario',
  user_active: 'Activo',
  user_awaiting_activation: 'En espera de activación',
  user_subscription: 'Suscripción',
  user_status: 'Estado',
  user_admin: 'Super admin',
  user_save_and_new: 'Guardar y agregar otro usuario',
  user_save_success: '¡Usuario guardado!',
  user_save_error: '¡Se ha producido un error al guardar el usuario!',
  user_email_error: 'El correo electrónico no puede estar vacío',
  user_username_error: 'El nombre de usuario no puede estar vacío',
  user_reset_password: 'Restablecer contraseña',
  user_generate_password: 'Generar nueva contraseña',
  user_password_saved: 'Contraseña guardada y enviada.',
  user_password_reset_confirm: '¿Estás seguro de que quieres guardar? Esto sobrescribirá la contraseña anterior de los usuarios.',
  subscription_history: 'Historial de suscripciones',
  subscription: 'Suscripción',
  subscription_platform: 'Plataforma',
  subscription_date: 'Comenzó',
  subscription_expires: 'Expira',
  subscription_unsubscribed: 'Dado de baja',
  mobile_devices: 'Dispositivos móviles',
  user_device_registered: 'Registrado',
  user_device_last_update: 'Última actualización',
  user_device_app_version: 'Versión de la aplicación',
  user_device_manufacturer: 'Fabricante',
  user_device_model: 'Modelo',
  user_device_os: 'Sistema Operativo',
  user_device_is_logged_in: 'Conectado',
  user_device_never_accessed: 'Nunca',
  user_notification_settings: 'Notificaciones',
  user_notification_category: 'Categoría',
  user_notification_push: 'Notificación push',
  user_notification_email: 'E-mail',
  user_notification_sound: 'Sonido',
  show: 'Mostrar',
  active: 'Activo',
  awaiting_activation: 'En espera de activación',
  status: 'Estado',
  quick_search: 'Búsqueda rápida',
  created: 'Creado',
  unique_id: 'ID único',
  location: 'Ubicación',
  latitude: 'Latitud',
  longitude: 'Longitud',
  address: 'Dirección',
  city: 'Ciudad',
  country: 'País',
  save: 'Guardar',
  registered_dalm_dialers: 'Comunicadores Dalm registrados',
  manufacturer: 'Fabricante',
  model: 'Modelo',
  operating_system: 'Sistema operativo',
  app_version: 'Versión de la aplicación',
  notification_name: 'Nombre de notificación',
  push: 'Push',
  role: 'Rol',
  subscribe_date: 'Fecha de suscripción',
  unsubscribe_date: 'Fecha de baja',
  expiration_date: 'Fecha de caducidad',
  success: '¡Éxito!',
  error: 'Error!',
  user_saved: 'Usuario guardado correctamente.',
  location_saved: 'Ubicación guardada correctamente.',
  users_added: 'Usuarios agregados correctamente',
  add_user_to_network: 'Agregar usuario a redes seleccionadas',
  the_language: 'Idioma',
  language_en: 'Inglesa',
  language_sv: 'Sueca',
  language_es: 'Español',
  client_affiliation: 'Afiliación de Clientes',
  client_is_required: 'Se requiere cliente',
  // Clients
  add_client: 'Agregar cliente',
  client_name: 'Nombre del cliente',
  client_name_error: 'El nombre del cliente no puede estar vacio',
  client_contact_name: 'Nombre de contacto',
  client_contact_email: 'Correo electrónico de contacto',
  client_contact_phone: 'Teléfono de contacto',
  client_alarm_central_phone: 'Central de alarma telefónica',
  client_save_success: '¡Cliente salvado!',
  client_save_error: '¡Se ha producido un error al guardar el cliente!',
  client_user_count: 'Número de usuarios',
  client_network_summary: 'Redes',
  client_subscription_summary: 'Suscripciones',
  policy: 'Política',
  policy_sos: 'Llamada de emergencia',
  policy_amc: 'Alarma de pánico',
  policy_network: 'Alarma de red',
  policy_safe_assist: 'Safe Assist',
  policy_enforce: 'Anular la configuración propia del usuario',
  policy_disable: 'Desactivar la alarma',
  policy_create_public_alert: 'Crear alerta pública',
  policy_alert_all_available_networks: 'Alertar a todas las redes disponibles',
  policy_alert_all_available_users: 'Alerta a todas las usuarias disponibles',
  policy_title: 'Título de la alarma',
  policy_description: 'Descripción de la alarma',
  policy_selected_networks: 'Redes seleccionadas',
  policy_public_radius_enabled: 'Habilitar de notificación Alertas dinámicas.',
  policy_public_radius_title: 'Rango de notificación Alertas dinámicas (medidor).',
  policy_public_radius_placeholder: 'Si está vacía, se establecerá en 10.000 m.',
  policy_selected_networks_radius_enabled: 'Habilitar Rango de notificación de Redes Seleccionadas.',
  policy_selected_networks_radius_title: 'Rango de notificación de Redes Seleccionadas (en metros).',
  policy_selected_networks_radius_placeholder: 'Si está vacío, se notificará a todas las redes seleccionadas.',
  client_policy_save_success: '¡Política de cliente guardada!',
  client_policy_save_error: 'Se ha producido un error al guardar la directiva de cliente.',
  dynamic_alert: 'Alerta dinámica',
  region: 'Regiín',
  archived: 'Archivados',
  closed: 'Cerrado',
  version: 'Versión',
  comment: 'Comentario',
  start: 'Empezar',
  end: 'Fin',
  add_new_invoice: 'Agregar nueva factura',
  device_id: 'ID del dispositivo',
  modified: 'Modificado',
  name: 'Nombre',
  public: 'Publico',
  private: 'Privado',
  open: 'Abierto',
  deleted: 'Eliminado',
  public_network: 'Red publica',
  private_network: 'Red privada',
  open_network: 'Red abierta',
  deleted_network: 'Red eliminada',
  //devices
  add_device: 'Agregar dispositivo',
  device_ports: 'Entradas y salidas',
  device_inputs: 'Entradas',
  device_outputs: 'Salidas',
  device_alerts: 'Alertas de dispositivos',
  device_owner: 'Dueño',
  device_user_owner: 'Dispositivo propietario',
  device_client_owner: 'Dispositivo compartido',
  device_user_device: 'Dispositivo personal del usuario',
  device_client_device: 'Dispositivo cliente',
  device_name: 'Nombre',
  device_description: 'Descripción',
  device_location: 'Ubicación',
  device_status: 'Estado',
  device_dalm_id: 'ID DALM',
  device_dalm_sim: 'SIM DALM',
  device_dalm_passcode: 'Código de acceso DALM',
  device_on: 'Activo',
  device_off: 'Inactivo',
  device_deleted: 'Eliminado',
  device_port_code: 'Código',
  device_port_name: 'Nombre',
  device_port_active: 'Estado',
  device_owner_error: 'Debe seleccionar un dueño',
  device_name_error: 'El nombre no puede estar vacío',
  device_location_error: 'La ubicación no puede estar vacía',
  device_lat_error: 'La coordenada no puede estar vacía',
  device_lng_error: 'La coordenada no puede estar vacía',
  device_save_success: '¡Dispositivo guardado!',
  device_save_error: '¡Se ha producido un error al guardar el dispositivo!',
  test_mode: 'Modo de prueba',
  alert_type: 'Tipo',
  alert_event_code: 'Código de evento',
  alert_section_number: 'Número de sección',
  alert_message: 'Mensaje',
  //networks
  add_network: 'Agregar red',
  add_network_to: 'Agregar red a',
  add_existing_network: 'Agregar red existente',
  network_name: 'Nombre de red',
  network_description: 'Descripción',
  network_type: 'Tipo',
  network_status: 'Estado',
  network_address: 'Dirección',
  network_open: 'Abiero',
  network_public: 'Publico',
  network_private: 'Privado',
  network_active: 'Activo',
  network_closed: 'Cerrado',
  network_archived: 'Archivado',
  network_deleted: 'Eliminado',
  network_owner: 'Dueño',
  network_admin: 'Admin',
  network_member: 'Miembro',
  network_member_joined: 'Unido',
  network_add_members: 'Agregar usuarios seleccionados a la red',
  network_save_success: '¡Red guardada!',
  network_save_error: '¡Se ha producido un error al guardar la red!',
  network_name_error: 'El nombre no puede estar vacío',
  network_address_error: 'La dirección no puede estar vacía',
  network_lat_error: 'La coordenada no puede estar vacía',
  network_lng_error: 'La coordenada no puede estar vacía',
  network_settings: 'Ajustes',
  enableNotificationRange: 'Notificar solo a los miembros que estén a su alcance',
  notificationRange: 'Rango de notificación',
  disallowMemberPosts: 'Los miembros no pueden notificar o comentar',
  onlyNotifyAdmins: 'Notificar solo al administrador',
  confirmIncidents: 'Crear notificación confirmada',
  allowSOSAlerts: 'Permitir emergencia SOS entrante',
  allowPublicAlerts: 'Permitir alertas públicas entrantes',
  enableClustering: 'Deshabilitar la agrupación',
  notificationsAndComments: 'Notificaciones y Comentarios',
  incomingAlerts: 'Alertas entrantes',
  integration_ip_error: 'La dirección IP no puede estar vacía',
  integration_port_error: 'El puerto no puede estar vacío',
  integration_description_error: 'La descripción no puede estar vacía',
  settings: 'Pantalla Central De Alertas',
  caption: 'Subtítulo',
  icon: 'Icono',
  useDeviceSettings: 'Usar configuración del dispositivo',
};
