import React, {useContext} from 'react';

import Lang from '../../lang';

const badge = (k, t) => () => {
  const {lang} = useContext(Lang);
  return (
    <span className={`badge badge-${t}`}>
      {lang[k]}
    </span>
  );
}

export const Enabled = badge('enabled', 'danger');
export const Disabled = badge('disabled', 'success');

const Status = ({device}) => <>
  {device.status === 'enabled' && <Enabled/>}
  {device.status === 'disabled' && <Disabled/>}
</>;

export default Status;
