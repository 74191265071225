import React from 'react'
import PropTypes from 'prop-types'

function CardTitle(props) {
    return (
        <div className="text-center mt-5">
            <h4 className="mb-2">{props.title}</h4>
            <p className="card-description mb-5">{props.description}</p>
        </div>
    )
}

CardTitle.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

export default CardTitle;
