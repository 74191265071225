import React, { useContext } from 'react';

import Lang from '../../lang';

const badge = (k, t) => () => {
    const { lang } = useContext(Lang);
    return (
        <span className={`badge badge-${t}`}>
            {lang[k]}
        </span>
    );
}

export const On = badge('device_on', 'success');
export const Off = badge('device_off', 'warning');
export const Deleted = badge('device_deleted', 'danger');

const Status = ({ device }) => <>
    {device.status === 'on' && <On />}
    {device.status === 'off' && <Off />}
    {device.status === 'deleted' && <Deleted />}
</>;

export default Status;
