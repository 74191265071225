import React, { useContext, useState } from 'react';

import { API_BASE_URL } from '../../config';
import Lang from '../../lang';
import useLoader from '../../hooks/loader';

import Spinner from '../../ui/Spinner';
import { Micro, Mini, Medi, Maxi, Mega, Macro } from '../../users/components/Subscription';


const SubscriptionSummary = ({ clientId }) => {
    const { lang } = useContext(Lang);

    const [summary, setSummary] = useState({});

    const loading = useLoader(
        `${API_BASE_URL}/clients/${clientId}/subscription-summary`,
        setSummary
    );

    if (loading) {
        return <Spinner />;
    }

    const total = Object.values(summary).reduce((a, v) => a + v);

    return (
        <table className="table table-sm table-borderless">
            <tbody>
                <tr>
                    <th colSpan="2">
                        {lang.client_subscription_summary}
                    </th>
                </tr>
                <tr>
                    <td style={{ width: '1%' }}><Micro /></td>
                    <td>{summary.micro}</td>
                </tr>
                <tr>
                    <td><Mini /></td>
                    <td>{summary.mini}</td>
                </tr>
                <tr>
                    <td><Medi /></td>
                    <td>{summary.medi}</td>
                </tr>
                <tr>
                    <td><Maxi /></td>
                    <td>{summary.maxi}</td>
                </tr>
                <tr>
                    <td><Mega /></td>
                    <td>{summary.mega}</td>
                </tr>
                <tr>
                    <td><Macro /></td>
                    <td>{summary.macro}</td>
                </tr>
                <tr>
                    <td>
                        <span className="badge badge-dark">
                            {lang.total}
                        </span>
                    </td>
                    <td><strong>{total}</strong></td>
                </tr>
            </tbody>
        </table>
    );
}

export default SubscriptionSummary;
