import React from 'react'
import PropTypes from 'prop-types'

function Card(props) {
    return (
        <div className={`${props.className} grid-margin stretch-card`}>
            <div className="card">
                <div className="card-body">
                    {props.title && (<h4>{props.title}</h4>)}
                    {props.children}
                </div>
            </div>
        </div>
    )
}

Card.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node
};

export default Card;
