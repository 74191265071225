import React from 'react';
import DataSelect from '../../ui/DataSelect';
import {API_BASE_URL} from '../../config';

const Selector = ({inputKey, name, inputRef, networkId, clientId, onChange, isMulti, defaultValue, value, isDisabled}) =>
  <DataSelect
    inputKey={inputKey}
    name={name}
    inputRef={inputRef}
    value={value}
    isMulti={isMulti}
    url={`${API_BASE_URL}/users/search`}
    formatLabel={formatLabel}
    onChange={onChange}
    params={{networkId, clientId}}
    defaultValue={defaultValue}
    isDisabled={isDisabled}
  />

export default Selector;

const formatLabel = ({firstname, lastname, email, username}) => {
  if (firstname && lastname) {
    return `${firstname} ${lastname} (${email})`
  } else if (username) {
    return `${username} (${email})`
  }

  return email;
};
