import React, { useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import Lang from '../lang';
import useLoader from '../hooks/loader';
import Card from '../ui/Card';
import Spinner from '../ui/Spinner';
import Form from './components/Form';
import UserDevices from './components/Devices';
import NotificationSettings from './components/NotificationSettings';
import Subscription from './components/Subscription';
import SubscriptionHistory from './components/SubscriptionHistory';
import UserMemberships from '../members/components/UserMemberships';
import DeviceList from '../devices/components/List';
import ResetPassword from './components/ResetPassword';
import Auth from "../auth";
import System from './components/System';

const Index = (_props) => {
    let history = useHistory();
    const { lang } = useContext(Lang);
    const { userId } = useParams();
    const { hasRole } = useContext(Auth);
    const [user, setUser] = useState({});
    const [tab, setTab] = useState('networks');
    const tabActive = (t) => (tab === t ? 'active' : '');

    const loading = useLoader(`${API_BASE_URL}/users/${userId}`, setUser);

    if (loading) {
        return <Spinner />;
    }

    return (
        <div>
            <h3>
                <Header user={user} />{' '}
                {user.isAdmin && (
                    <div className='badge badge-success'>{lang.user_admin}</div>
                )}{' '}
                <Subscription user={user} />
            </h3>
            <hr />
            <Card>
                <Form user={user} onSave={setUser} />
            </Card>
            <Card>
                <ul className='nav nav-tabs'>
                    <li className='nav-item'>
                        <button
                            className={`nav-link ${tabActive('networks')}`}
                            onClick={() => setTab('networks')}
                        >
                            {lang.networks}
                        </button>
                    </li>
                    {hasRole('admin') &&
                        <li className='nav-item'>
                            <button
                                className={`nav-link ${tabActive('devices')}`}
                                onClick={() => setTab('devices')}
                            >
                                {lang.devices}
                            </button>
                        </li>
                    }
                    <li className='nav-item'>
                        <button
                            className={`nav-link ${tabActive('subscription-history')}`}
                            onClick={() => setTab('subscription-history')}
                        >
                            {lang.subscription_history}
                        </button>
                    </li>
                    <li className='nav-item'>
                        <button
                            className={`nav-link ${tabActive('user-devices')}`}
                            onClick={() => setTab('user-devices')}
                        >
                            {lang.mobile_devices}
                        </button>
                    </li>
                    <li className='nav-item'>
                        <button
                            className={`nav-link ${tabActive('notification_settings')}`}
                            onClick={() => setTab('notification_settings')}
                        >
                            {lang.user_notification_settings}
                        </button>
                    </li>
                    <li className='nav-item'>
                        <button
                            className={`nav-link ${tabActive(
                                'reset-password'
                            )}`}
                            onClick={() => setTab('reset-password')}
                        >
                            {lang.user_reset_password}
                        </button>
                    </li>
                    {hasRole('admin') && <li className='nav-item'>
                        <button
                            className={`nav-link ${tabActive(
                                'system'
                            )}`}
                            onClick={() => setTab('system')}
                        >
                          {'System'}
                        </button>
                    </li>}
                </ul>
                <div className='tab-content'>
                    {tab === 'networks' && <UserMemberships userId={user.id} />}
                    {tab === 'devices' && (
                        <DeviceList
                            onClick={(d) => history.push(`/devices/${d.id}`)}
                            url={`${API_BASE_URL}/users/${userId}/devices`}
                        />
                    )}
                    {tab === 'subscription-history' && (
                        <SubscriptionHistory user={user} />
                    )}
                    {tab === 'user-devices' && <UserDevices user={user} />}
                    {tab === 'notification_settings' && <NotificationSettings user={user} />}
                    {tab === 'reset-password' && <ResetPassword user={user} />}
                    {hasRole('admin') && tab === 'system' && <System/>}
                </div>
            </Card>
        </div>
    );
};

const Header = ({ user }) => {
    if (user.firstName) {
        return `${user.id} - ${user.firstName} ${user.lastName}`;
    }
    if (user.username) {
        return `${user.id} - ${user.username}`;
    }
    if (user.email) {
        return `${user.id} - ${user.email}`;
    }
    return user.id;
};

export default Index;
