import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import './setting-row-range.scss';
import Lang from '../../lang';

// Constants for minimum and maximum values
const MAX = 100;
const DEFAULT_MIN_VALUE = 0.01;
const DEFAULT_MAX_VALUE = 10;

const TRACK_COLOR_INACTIVE = '#303030'; // Inactive track color
const THUMB_COLOR = '#7571f9'; // Thumb color when not dragged
const THUMB_COLOR_DRAGGED = '#CCC'; // Thumb color when dragged

const SettingRowRange = ({ value, onChange, isDisabled }) => {
	const { lang } = useContext(Lang);
	const [maxValue, setMaxValue] = useState(DEFAULT_MAX_VALUE);

	const minStep = useMemo(() => maxValue / 1000, [maxValue]);
	const kmValue = useMemo(() => value ? value / 1000 : minStep, [value, minStep]);

	const onChangeMaxValue = useCallback((e) => {
		const newValue = e.target.value;
		if (newValue > MAX || newValue < kmValue) return;
		setMaxValue(newValue);
	}, [kmValue]);

	const onChangeRange = useCallback(([value]) => {
		onChange(value > maxValue ? maxValue : value);
	}, [maxValue]);

	useEffect(() => {
		if (kmValue > maxValue) setMaxValue(MAX);
	}, [kmValue, maxValue]);

	return (
		<div className="form-row pt-4">
			<div className="mr-4">
				<label className="">{lang.notificationRange}</label>
			</div>
			<div className="mr-3">
				<label className="">0 km</label>
			</div>
			<div className="form-group form-check range-container">
				{kmValue <= maxValue && (
					<Range
						disabled={isDisabled}
						min={DEFAULT_MIN_VALUE}
						max={maxValue}
						step={minStep}
						values={[kmValue]}
						onChange={onChangeRange}
						renderTrack={({ props, children }) => (
							<div
								{...props}
								className="range-track"
								style={{
									...props.style,
									opacity: isDisabled ? .5 : 1,
									background: getTrackBackground({
										values: [kmValue],
										colors: [THUMB_COLOR, TRACK_COLOR_INACTIVE],
										min: DEFAULT_MIN_VALUE,
										max: maxValue,
									}),
								}}
							>
								{children}
							</div>
						)}
						renderThumb={({ props, isDragged }) => (
							<div {...props} className="range-thumb" style={{ ...props.style, backgroundColor: THUMB_COLOR }}>
								<div className="thumb-inner" style={{ backgroundColor: isDragged ? THUMB_COLOR_DRAGGED : THUMB_COLOR }}/>
								<div className="thumb-label">
									{kmValue.toFixed(2)} km
								</div>
							</div>
						)}
					/>
				)}
			</div>
			<div className="ml-3">
				<input
					min={10}
					max={MAX}
					type="number"
					value={maxValue}
					onChange={onChangeMaxValue}
					className="form-control"
					disabled={isDisabled}
				/>
			</div>
		</div>
	);
};

export default SettingRowRange;
