import React from 'react';

const CheckGroup = (props) => {
    const {
        name,
        inputRef,
        type,
        value,
        options,
        onChange,
    } = props;

    return (
        <>
            {Object.entries(options).map(([val, lbl]) =>
                <div key={val}
                    className="form-check form-check-inline">
                    <input id={`${name}-${type}-${value}-${val}`}
                        className="form-check-input"
                        type={type}
                        name={name}
                        onChange={onChange}
                        ref={inputRef}
                        value={val}
                        defaultChecked={value === val} />
                    <label htmlFor={`${name}-${type}-${value}-${val}`}>
                        {lbl}
                    </label>
                </div>)}
        </>
    )
}

export default CheckGroup;
