import React, { useCallback, useContext, useState } from 'react';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import Lang from '../../lang';
import {API_BASE_URL} from '../../config';
import SettingRowCheckbox from './SettingRowCheckbox';
import useLoader from "../../hooks/loader";
import SettingRowCaption from "./SettingRowCaption";
import SettingRowRange from "./SettingRowRange";
import { NetworkContext } from '../Show';
import SettingRowOption from './SettingRowOption';

const NetworkSettings = ({networkId}) => {
  const {lang} = useContext(Lang);
  const { updateNetworkContext } = useContext(NetworkContext);

  const {
    handleSubmit,
    reset,
  } = useForm();

  const [settings, setSettings] = useState(defaultSettings);
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState(null);

  const updateSettings = useCallback((data) => {
    setSettings(data);
    updateNetworkContext({ notificationRange: data.notificationRange});
  }, [setSettings]);

  useLoader(`${API_BASE_URL}/networks/${networkId}/settings`, updateSettings);

  const onChangeNotificationRange = (value) => {
     updateNetworkContext({ notificationRange: value * 1000 });
     setSettings((prev) => ({...prev, notificationRange: value * 1000}))
  }

  const onChangeEnableNotificationRange = (ev) => {
    const checked = ev.target.checked;
    !checked && onChangeNotificationRange(0);
    setSettings({
      ...settings,
      enableNotificationRange: checked,
      notificationRange: checked ? settings.notificationRange : null,
    });
  }

  const save = data => {
    setAlert(null);
    setSaving(true);

    let req = axios.put(`${API_BASE_URL}/networks/${networkId}/settings`, settings);

    req.then(res => {
      setSaving(false);
      setAlert({
        type: 'success',
        icon: 'check-circle-outline',
        text: lang.network_save_success,
      });
      reset();
    }).catch(() => {
      setSaving(false);
      setAlert({
        type: 'danger',
        icon: 'alert-circle-outline',
        text: lang.network_save_error,
      });
    });
  }

  return (
    <div>
      {/*{JSON.stringify(settings)}*/}
      {/*<br/>*/}
      {/*<br/>*/}
      <form onSubmit={handleSubmit(save)}>
        <SettingRowCaption
          text={lang.notificationsAndComments}
        />
        <SettingRowCheckbox
          name='enableNotificationRange'
          checked={!!settings.enableNotificationRange}
          onChange={onChangeEnableNotificationRange}
        />
        <SettingRowRange
          value={settings.notificationRange}
          onChange={onChangeNotificationRange}
          isDisabled={!settings.enableNotificationRange}
        />
        <SettingRowCheckbox
          name='disallowMemberPosts'
          checked={!!settings.disallowMemberPosts}
          onChange={ev => { setSettings({...settings, disallowMemberPosts: ev.target.checked})}}
        />
        <SettingRowCheckbox
          name='onlyNotifyAdmins'
          checked={!!settings.onlyNotifyAdmins}
          onChange={ev => { setSettings({...settings, onlyNotifyAdmins: ev.target.checked})}}
        />
        <SettingRowCheckbox
          name='confirmIncidents'
          checked={!!settings.confirmIncidents}
          onChange={ev => { setSettings({...settings, confirmIncidents: ev.target.checked})}}
        />
        <br/>
        <SettingRowCaption
          text={lang.incomingAlerts}
        />
        <SettingRowCheckbox
          name='allowSOSAlerts'
          checked={!!settings.allowSOSAlerts}
          onChange={ev => { setSettings({...settings, allowSOSAlerts: ev.target.checked})}}
        />
        <SettingRowCheckbox
          name='allowPublicAlerts'
          checked={!!settings.allowPublicAlerts}
          onChange={ev => { setSettings({...settings, allowPublicAlerts: ev.target.checked})}}
        />
        <br/>
        <SettingRowCaption
          text={lang.incidents}
        />
        <SettingRowOption
          name='enableClustering'
          keys={enableClusteringKeys[String(settings.enableClustering === undefined ? defaultSettings.enableClustering.toString() : settings.enableClustering)]}
          onChange={ev => { setSettings({...settings, enableClustering: enableClusteringValues[ev.target.value]})}}
        />
        <br/>

        <div className="form-row">
          <div className="col-md-1">
            <button className="btn btn-primary">
              {lang.save}
            </button>
          </div>
          <div className="col-md-3">
            {saving && <div className="spinner-border"/>}
            {alert &&
              <div className={`alert alert-${alert.type}`}>
                <i className={`mdi mdi-${alert.icon}`} />
                {alert.text}
              </div>}
          </div>
        </div>
      </form>
    </div>
  );
}

const enableClusteringValues = {
  'enabled': true,
  'disabled': false,
  'useDeviceSettings': null,
}

const enableClusteringKeys = {
  'true': [{value: 'enabled', selected: true},{value: 'disabled', selected: false},{value: 'useDeviceSettings', selected: false}],
  'false': [{ value: 'enabled', selected: false},{ value: 'disabled', selected: true},{ value: 'useDeviceSettings', selected: false}],
  'null': [{ value: 'enabled', selected: false},{ value: 'disabled', selected: false},{ value: 'useDeviceSettings', selected: true}],
}

const defaultSettings = {
  enableNotificationRange: false,
  notificationRange: null,
  disallowMemberPosts: false,
  onlyNotifyAdmins: false,
  confirmIncidents: false,
  allowSOSAlerts: false,
  allowPublicAlerts: false,
  enableClustering: false,
};

export default NetworkSettings;
