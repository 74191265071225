import React, { useState, useContext, useCallback } from 'react';
import axios from 'axios';

import Lang from '../../lang';
import { API_BASE_URL } from '../../config';

import UserSelector from '../../users/components/Selector';


const UserForm = ({ networkId, onSave }) => {
    const { lang } = useContext(Lang);

    const [ids, setIds] = useState([])
    const [key, setKey] = useState(0);

    const save = useCallback(async () => {
        const res = await axios.post(
            `${API_BASE_URL}/networks/${networkId}/users`,
            { ids }
        );

        onSave(res.data);
        setKey(key + 1);
        setIds([]);
    }, [
        networkId,
        onSave,
        ids,
        setIds,
        key,
        setKey,
    ]);

    return (
        <div className="form-row">
            <div className="col">
                <UserSelector
                    isMulti
                    key={key}
                    networkId={networkId}
                    onChange={ns => setIds((ns || []).map(n => n.id))} />
            </div>
            <div className="col-auto">
                <button
                    className="btn btn-primary btn-sm"
                    disabled={ids.length <= 0}
                    onClick={save}>
                    {lang.network_add_members}
                </button>
            </div>
        </div>
    )
}

export default UserForm;
