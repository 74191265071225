import React, {useContext, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import Lang from '../../lang';
import {API_BASE_URL} from '../../config';
import geocode from '../../geo/geocode';
import Map from '../../geo/Map';
import FormGroup from '../../ui/FormGroup';
import CheckGroup from '../../ui/CheckGroup';
import UserSelector from '../../users/components/Selector';
import {On, Off, Deleted} from './Status';
import Auth from "../../auth";
import ClientSelector from "../../clients/components/Selector";
import {Disabled, Enabled} from "./TestMode";

const locate = async (getValues, setValue, setCoords) => {
  const {location} = getValues();
  try {
    const res = await geocode(`address=${location}`);
    const {lat, lng} = res.geometry.location;
    setCoords(lat, lng);
    setValue('location', res.formatted_address);
  } catch (err) {
    console.error(err);
  }
}

const Form = ({device, onSave}) => {
  const { hasRole } = useContext(Auth);
  const {lang} = useContext(Lang);

  if (!device) device = defaultDevice;

  const {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      ownerId: device.ownerId,
    }
  });

  const lat = watch('lat', device.lat)
  const lng = watch('lng', device.lng)

  const setCoords = (lat, lng) => {
    setValue('lat', lat);
    setValue('lng', lng);
    setCoordsDirty(true);
  }

  const [coordsDirty, setCoordsDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (!coordsDirty) return;

    geocode(`latlng=${lat},${lng}`)
      .then(res => setValue('location', res.formatted_address))
      .catch(err => console.error(err));
  }, [coordsDirty, lat, lng, setValue])

  const save = data => {
    setAlert(null);
    setSaving(true);

    data.lat = parseFloat(data.lat);
    data.lng = parseFloat(data.lng);

    let req = device.id ?
      axios.put(`${API_BASE_URL}/devices/${device.id}`, data) :
      axios.post(`${API_BASE_URL}/devices`, data);

    req.then(res => {
      setSaving(false);
      setAlert({
        type: 'success',
        icon: 'check-circle-outline',
        text: lang.device_save_success,
      });
      onSave({...device, ...data, id: res.data.id});
      reset({...device, ...data, id: res.data.id});
    }).catch(error => {
      console.error(error)
      const errorMessage = (error.response && error.response.data && error.response.data.error) ? error.response.data.error : lang.device_save_error;
      setSaving(false);
      setAlert({
        type: 'danger',
        icon: 'alert-circle-outline',
        text: errorMessage,
      });
    });
  };

  return (
    <div className="row">
      <form className="col-md-7" onSubmit={handleSubmit(save)}>
        {/*errors={Object.keys(errors || {}).join(',')}*/}
        {/*<br/>*/}
        <div className="form-row">
          {/*{ hasRole('admin')*/}
          {/*  // full set of inputs*/}
          {/*  ?*/}
            <FormGroup
              title={lang.device_user_owner}
              error={errors.ownerId}
              className='col-md-6'
            >
              <input
                name="ownerId"
                type="hidden"
                value={device.ownerId || ''}
                ref={register({
                  validate: () => { if (!device.ownerId) return lang.device_owner_error; }
                })}
              />
              <UserSelector
                inputKey={`ownerIdSelect-${device.ownerType}`}
                defaultValue={device.ownerId ? {
                  value: device.ownerId,
                  id: device.ownerId,
                  username: device.ownerUsername,
                  firstname: device.ownerFirstName,
                  lastname: device.ownerLastName,
                  email: device.ownerEmail,
                } : null}
                onChange={(curUser) => {
                  const newOwnerId = (curUser ? curUser.id : null);
                  device.ownerId = newOwnerId;
                  setValue('ownerId', newOwnerId, { shouldValidate: true });
                }}
              />
            </FormGroup>
          {/*  :*/}
          {/*  // hidden input*/}
          {/*  <input*/}
          {/*    name="ownerId"*/}
          {/*    type="hidden"*/}
          {/*    value={device.ownerId}*/}
          {/*    ref={register}*/}
          {/*  />*/}
          {/*}*/}

          <FormGroup
            title={lang.device_client_owner}
            error={errors.clientOwnerId}
            className='col-md-6'
          >
            <input
              name="clientOwnerId"
              type="hidden"
              value={device.clientOwnerId || ''}
              // ref={register}
              ref={register({
                validate: () => {
                  if (hasRole('client-admin') && !device.clientOwnerId) {
                    return lang.device_owner_error;
                  }
                }
              })}
            />
            <ClientSelector
              // inputKey={`clientOwnerIdSelect-${device.ownerType}`}
              defaultValue={ device.clientOwnerId ? {id: device.clientOwnerId, name: device.clientOwnerName} : null }
              onChange={(curClient) => {
                const newClientOwnerId = (curClient ? curClient.id : null);
                device.clientOwnerId = newClientOwnerId;
                setValue('clientOwnerId', newClientOwnerId, { shouldValidate: true });
              }}
            />
          </FormGroup>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            <FormGroup
              title={lang.device_status}>
              <CheckGroup
                type="radio"
                name="status"
                inputRef={register}
                value={device.status}
                options={{
                  on: <On/>,
                  off: <Off/>,
                  deleted: <Deleted/>,
                }}
              />
            </FormGroup>
          </div>
          <div className="col-md-4">
            <FormGroup
              title={lang.test_mode}>
              <CheckGroup
                type="radio"
                name="testModeEnabled"
                inputRef={register}
                value={device.testModeEnabled ? 'true' : 'false'}
                options={{
                  true: <Enabled/>,
                  false: <Disabled/>,
                }}
              />
            </FormGroup>
          </div>
          <div className="col-md-4">
            <FormGroup
              title={lang.enableClustering}>
              <CheckGroup
                type="radio"
                name="enableClustering"
                inputRef={register}
                value={device.enableClustering ? 'true' : 'false'}
                options={{
                  true: <Enabled/>,
                  false: <Disabled/>,
                }}
              />
            </FormGroup>
          </div>
        </div>
        <div className="form-row">
          <FormGroup
            className="col-md-6"
            name="name"
            inputRef={register({required: lang.device_name_error})}
            title={lang.device_name}
            value={device.name}
            error={errors.name}
          />
          <FormGroup
            className="col-md-6"
            name="description"
            inputRef={register}
            title={lang.device_description}
            value={device.description}
          />
        </div>
        <div className={`form-group ${errors.location && 'has-danger'}`}>
          <label>{lang.device_location}</label>
          <div className="input-group">
            <input
              className={`form-control ${errors.location && 'form-control-danger'}`}
              type="text"
              name="location"
              ref={register({required: lang.device_location_error})}
              placeholder={lang.device_location}
              defaultValue={device.location}
            />
            <div className="input-group-append">
              <button
                className="btn btn-sm btn-primary"
                onClick={ev => {
                  ev.preventDefault();
                  locate(getValues, setValue, setCoords);
                }}>
                {lang.locate}
              </button>
            </div>
          </div>
          {errors.location &&
            <p className="error text-danger">{errors.location.message}</p>}
        </div>
        <div className="form-row">
          <FormGroup
            className="col-md-6"
            name="lat"
            inputRef={register({required: lang.device_lat_error})}
            title={lang.latitude}
            value={device.lat}
            error={errors.lat}
          />
          <FormGroup
            className="col-md-6"
            name="lng"
            inputRef={register({required: lang.device_lng_error})}
            title={lang.longitude}
            value={device.lng}
            error={errors.lng}
          />
        </div>
        <div className="form-row">
          <FormGroup
            className="col-md-4"
            name="dalmId"
            inputRef={register}
            title={lang.device_dalm_id}
            value={device.dalmId}
          />
          <FormGroup
            className="col-md-4"
            name="dalmSim"
            inputRef={register}
            title={lang.device_dalm_sim}
            value={device.dalmSim}
          />
          <FormGroup
            className="col-md-4"
            name="dalmPasscode"
            inputRef={register}
            title={lang.device_dalm_passcode}
            value={device.dalmPasscode}
          />
        </div>
        <div className="form-row">
          <div className="col">
            <button className="btn btn-primary"
                    disabled={!formState.dirty || saving}>
              {lang.save}
            </button>
          </div>
          <div className="col-md-auto">
            {saving &&
              <div className="spinner-border"/>}
            {alert &&
              <div className={`alert alert-${alert.type}`}>
                <i className={`mdi mdi-${alert.icon}`}/>
                {alert.text}
              </div>}
          </div>
        </div>
      </form>
      <div className="row col-md-5">
        <Map
          lat={parseFloat(lat)}
          lng={parseFloat(lng)}
          onChange={setCoords}/>
      </div>
    </div>
  );
}

export default Form;

const defaultDevice = {
  ownerId: '',
  clientOwnerId: '',
  status: 'on',
  name: '',
  description: '',
  location: '',
  lat: 59.3243556,
  lng: 18.0705097,
  dalmId: '',
  dalmSim: '',
  dalmPasscode: '',
  testModeEnabled: false,
};
