import React from 'react';

const FormGroup = (props) => {
  const {
    inputRef,
    type,
    name,
    value,
    title,
    placeholder,
    min,
    max,
    maxLength,
    pattern,
    onkeyup,
    onChange,
    className,
    children,
    error,
    readOnly,
  } = props;

  return (
    <div className={`form-group ${className} ${error && 'has-danger'}`}>
      <label>{title}</label>
      <div className="input-group">
        {!children &&
          <input
            ref={inputRef}
            name={name}
            type={type || 'text'}
            min={min}
            max={max}
            maxLength={maxLength}
            pattern={pattern}
            onKeyUp={onkeyup}
            onChange={onChange}
            className={`form-control ${error && 'form-control-danger'}`}
            placeholder={placeholder || title}
            defaultValue={value}
            readOnly={readOnly}
          />}
        {children}
      </div>
      {error && <p className="error text-danger">{error.message}</p>}
    </div>
  );
};

export default FormGroup;
