import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { API_BASE_URL } from '../config';
import Lang from '../lang';

import useLoader from '../hooks/loader';

import Card from '../ui/Card';
import Spinner from '../ui/Spinner';
import Form from '../users/components/Form';


const New = (_props) => {
    const { lang } = useContext(Lang);
    let history = useHistory();

    const { clientId } = useParams();
    const [client, setClient] = useState({});

    const loading = useLoader(`${API_BASE_URL}/clients/${clientId}`, setClient);

    if (loading) {
        return <Spinner />;
    }

    return (
        <Card title={`${lang.add_user_to} ${client.name}`}>
            <hr />
            <Form
                clientId={clientId}
                onSave={() => history.push(`/clients/${clientId}`)} />
        </Card>
    )
};

export default New;
