import React, { useContext, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import axios from 'axios';

import Auth from '../auth';
import Lang from '../lang';

import { API_BASE_URL } from '../config';
import List from './components/List';

import Card from '../ui/Card';


const Index = (_props) => {
    const { hasRole } = useContext(Auth);
    const { lang } = useContext(Lang);

    const { url } = useRouteMatch();
    let history = useHistory();

    const display = new Set();
    const hide = new Set();

    const [saving, setSaving] = useState(false);
    const [alert, setAlert] = useState(null);

    const show = (client) => history.push(`${url}/${client.id}`);
    const add = () => history.push(`${url}/new`);

    const assign = (row, isSelect) => {
      display.delete(row.id);
      hide.delete(row.id);

      if (row.display && !isSelect) {
        hide.add(row.id);
        display.delete(row.id);
      }

      if (!row.display && isSelect) {
        display.add(row.id);
        hide.delete(row.id);
      }
    };

    const assignMany = (isSelect, rows) => rows.forEach(row => assign(row, isSelect));

    const save = () => {
        setAlert(null);
        setSaving(true);

        const data = {hide: Array.from(hide.values()), display: Array.from(display.values())}

        axios.patch(`${API_BASE_URL}/clients/display`, data).then(() => {
          setSaving(false);
          setAlert({
            type: 'success',
            icon: 'check-circle-outline',
            text: 'Display settings has been updated!',
          });
          display.clear();
          hide.clear();
        }).catch(() => {
          setSaving(false);
          setAlert({
            type: 'danger',
            icon: 'alert-circle-outline',
            text: 'Display setting has failed to update!',
          });
        });
    }

    return (
        <Card>
            {alert &&
              <div className={`alert alert-${alert.type}`}>
                <i className={`mdi mdi-${alert.icon}`} />
                {alert.text}
              </div>}
            {saving ? <div className="spinner-border"/> :
            <List onClick={show} onSelect={assign} onSelectAll={assignMany}>
                {hasRole('multi-client-admin') && <div className="col-md-6">
                        <button onClick={save} className="btn btn-primary">
                            {lang.save}
                        </button>
                    </div>}
                {hasRole('admin') &&
                    <div className="col-md-6">
                        <button className="btn btn-success" onClick={add}>
                            {lang.add_client}
                        </button>
                    </div>}
            </List>}
        </Card>
    );
};

export default Index;
