import React, { useState } from 'react';
import { render } from 'react-dom';

import './assets/css/base.css';
import './assets/css/main.css';
import './assets/css/icons.css';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import './assets/sass/app.scss';

import Auth, { initAuth, buildAuth } from './auth';
import Login from './auth/Login';

import Lang, { initLang, buildLang } from './lang';

import Routes from './Routes';


const defaultLanguage = initLang();
const defaultAuth = initAuth();

const App = () => {

    const [lang, setLang] = useState(defaultLanguage);
    const [auth, setAuth] = useState(defaultAuth);

    return (
        <Auth.Provider value={buildAuth(auth, setAuth)}>
            <Lang.Provider value={buildLang(lang, setLang)}>
                {auth ? <Routes /> : <Login onAuth={setAuth} />}
            </Lang.Provider>
        </Auth.Provider>
    );
}

render(<App />, document.getElementById("root"));
