export default {
    container: (provided) => ({
        ...provided,
        padding: 0,
    }),
    control: (provided) => ({
        ...provided,
        backgroundColor: '#2b2e4c',
        fontSize: '0.8125rem',
        borderRadius: 2,
        borderColor: '#2a2c40',
        borderWidth: 1,
        '&:hover': {
            borderColor: '#2a2c40'
        }
    }),
    multiValue: (provided) => ({
        ...provided,
        color: 'black',
        backgroundColor: '#3a3f51'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'white'
    }),
    input: () => ({
        color: '#e9e6e6'
    }),
    placeholder: () => ({
        color: '#aaa7a7'
    }),
    singleValue: () => ({
        color: '#ffffff'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: 'rgba(255,255,255,0.2)'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'rgba(255,255,255,0.5)'
    }),
    option: (provided) => ({
        ...provided,
        backgroundColor: '#2b2e4c',
        fontSize: '0.8125rem',
        '&:hover': {
            backgroundColor: 'rgba(2,0,36,0.4)'
        }
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#2b2e4c',
        zIndex: 1000,
    }),
};

