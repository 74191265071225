import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { UAN_API_BASE_URL } from '../config';

import Card from '../ui/Card';

import logo from '../assets/images/auth/logo-flash.svg';
import logoFigure from '../assets/images/auth/intrasenze-logofigur-vit.svg';
import Lang from '../lang';
import './login-preloader.scss';

const LOADING_DELAY = 3000;

const Login = ({ onAuth }) => {
    const {
        register,
        handleSubmit,
    } = useForm();

    const { lang } = useContext(Lang);

    const [error, setError] = useState(null);
    const [pendingAuth, setPendingAuth] = useState(false);

    const login = useCallback(data => {
        setPendingAuth(true);

        axios.post(`${UAN_API_BASE_URL}/v2/auth`, data, {headers: {'x-intrasenze-origin':'backoffice'}})
            .then(res => {
                const {
                    accessToken,
                    refreshToken,
                    user,
                } = res.data;

                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('uan-api-access-token', accessToken);
                localStorage.setItem('uan-api-refresh-token',refreshToken);
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

                setTimeout(() => onAuth(user), LOADING_DELAY);
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    setError(err.response.data.error);
                } else {
                    setError(err.message);
                }
                setPendingAuth(false);
            });
    }, [onAuth, setPendingAuth, setError]);

    const loginFormElement = useMemo(() => (
        <>
            <h3 className='welcome-title'>{lang.login_welcome}</h3>

            <Card className="login-form">
                {error &&
                    <div className="alert alert-fill-danger">
                        <i className="mdi mdi-alert-circle"></i>
                        {error}
                    </div>}

                <form id="login-form" onSubmit={handleSubmit(login)}>
                    <div className="form-group">
                        <input className="form-control"
                            placeholder="Email address"
                            type="email"
                            name="email"
                            required
                            ref={register} />
                    </div>

                    <div className="form-group">
                        <input className="form-control"
                            placeholder="Password"
                            type="password"
                            name="password"
                            required
                            ref={register} />
                    </div>

                    <button className="btn btn-primary">
                        Sign in
                    </button>
                </form>
            </Card>
        </>
    ), [lang, error, register, handleSubmit, login]);

    const loader = useMemo(() => (
        <div className='loader-container'>
            <div className="loader"></div>
        </div>
    ), []);

    return (
        <div className="login-area content-wrapper">
            <img className='backoffice-logo' alt='backoffice-logo' src={logo}/>

            {pendingAuth ? loader : loginFormElement}

            <div className='bottom-logo-figure-container'>
                <img className='logo-figure' alt='logo-figure' src={logoFigure} />
            </div>
        </div>
    );
}

export default Login;
