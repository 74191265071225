import React, { useContext, useState } from 'react';
import moment from 'moment';
import useLoader from '../../hooks/loader';
import Lang from '../../lang';
import { API_BASE_URL } from '../../config';
import DataTable from '../../ui/DataTable';
import Status from './Status';

const timestampFormatter = ts => moment(ts).format('YYYY-MM-DD HH:mm');

const List = ({ onClick, children, url }) => {
    const { lang } = useContext(Lang);
    const columns = [
        {
            dataField: 'name',
            text: lang.device_name,
            sort: true,
        },
        {
            dataField: 'dalmId',
            text: lang.device_dalm_id,
            sort: true,
        },
        {
            dataField: 'location',
            text: lang.device_location,
            sort: true,
        },
        {
            dataField: 'flags',
            isDummyField: true,
            text: '',
            formatter: (_, row) => (
                <Status device={row} />
            ),
        },
        {
            dataField: 'createdAt',
            text: lang.created,
            sort: true,
            formatter: timestampFormatter,
        },
    ];

    const [devices, setDevices] = useState([]);
    const loading = useLoader(url || `${API_BASE_URL}/devices`, setDevices);

    return (
        <DataTable
            loading={loading}
            columns={columns}
            data={devices}
            defaultSort={[{
                dataField: "createdAt",
                order: "desc",
            }]}
            onClick={onClick}>
            {children}
        </DataTable>
    )
};

export default List;
