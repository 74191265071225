import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import Index from './Index';
import New from './New';
import Show from './Show';
import NewUser from './NewUser';
import NewNetwork from './NewNetwork';


const Router = (_props) => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <Index />
            </Route>
            <Route exact path={`${path}/new`}>
                <New />
            </Route>
            <Route exact path={`${path}/:clientId`}>
                <Show />
            </Route>
            <Route exact path={`${path}/:clientId/users/new`}>
                <NewUser />
            </Route>
            <Route exact path={`${path}/:clientId/networks/new`}>
                <NewNetwork />
            </Route>
        </Switch>
    );
};

export default Router;
