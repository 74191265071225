import React, { useState, useContext, createContext, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import Lang from '../lang';
import useLoader from '../hooks/loader';
import Type from './components/Type';
import Form from './components/Form';
import Settings from './components/Settings';
import Card from '../ui/Card';
import Spinner from '../ui/Spinner';
import NetworkMembers from '../members/components/NetworkMembers';
import Integrations from '../integrations/Integrations';

const defaultNetworkContextData = {
  networkType: 'open',
  notificationRange: 100,
};
export const NetworkContext = createContext(defaultNetworkContextData)

const Index = (props) => {
  const { lang } = useContext(Lang);
  const [tab, setTab] = useState('users');
  const { networkId } = useParams();
  const [network, setNetwork] = useState({});

  const [{ networkType, notificationRange }, setNetworkContext] = useState(defaultNetworkContextData);

  const updateNetworkContext = useCallback((newData) => {
    setNetworkContext(prev => ({ ...prev, ...newData }));
  }, [setNetworkContext]);

  const updateNetworkData = useCallback((data) => {
    setNetwork(data);
    updateNetworkContext({ networkType: data.type });
  }, [setNetwork]);

  const tabActive = (t) => (tab === t ? 'active' : '');
  const loading = useLoader(`${API_BASE_URL}/networks/${networkId}`, updateNetworkData);

  const contextValue = useMemo(() => {
    return {
      networkType,
      updateNetworkContext,
      notificationRange: tab === 'settings' ? notificationRange : 0,
    }
  }, [networkType, notificationRange, tab]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <NetworkContext.Provider value={contextValue}>
      <h3>
        <Type network={network} /> {network.name}
      </h3>
      <hr />
      <Card>
        <Form network={network} onSave={setNetwork} />
      </Card>
      <Card>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              onClick={() => setTab('users')}
              className={`nav-link ${tabActive('users')}`}
            >
              {lang.users}
            </button>
          </li>
          <li className="nav-item">
            <button
              onClick={() => setTab('integrations')}
              className={`nav-link ${tabActive('integrations')}`}
            >
              {lang.integrations}
            </button>
          </li>
          <li className="nav-item">
            <button
              onClick={() => setTab('settings')}
              className={`nav-link ${tabActive('settings')}`}
            >
              {lang.network_settings}
            </button>
          </li>
        </ul>
        <div className="tab-content">
          {tab === 'users' && <NetworkMembers networkId={network.id} />}
          {tab === 'integrations' && <Integrations entity="network" networkId={network.id} />}
          {tab === 'settings' && <Settings networkId={network.id} />}
        </div>
      </Card>
    </NetworkContext.Provider>
  );
};

export default Index;
