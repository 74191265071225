import moment from 'moment';
import React, { useContext } from 'react';
import Lang from '../../lang';

const Device = ({ enabled, title }) => {
    const color = enabled ? 'text-success' : 'text-danger';

    return (
            <i title={title} className={`mdi mdi-cellphone ${color} mdi-24px `} />
    );
}

const UserDeviceStatus = ({ user }) => {
    const { lang } = useContext(Lang);
    const { device_last_accessed } = user;

    const title = device_last_accessed ? moment(device_last_accessed).format('YYYY-MM-DD HH:mm') : lang.user_device_never_accessed

    return <>
        <Device enabled={!!device_last_accessed} title={title} />
    </>
};

export default UserDeviceStatus;
