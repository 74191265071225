export function enforceMinMax(el) {
  if (el.value !== "") {
    let newValue;
    if (parseInt(el.value) < parseInt(el.min)) {
      newValue = el.min;
    } else if (parseInt(el.value) > parseInt(el.max)) {
      newValue = el.max;
    } else {
      newValue = el.value;
    }
    el.value = parseInt(newValue);
  }
}

const COLORS_STATUS = {
  SUCCESS: 'bg-green',
  WARNING: 'bg-orange',
  DANGER: 'bg-red'
}

/**
 * Compares the current app version on the device with the latest available versions.
 * Returns a color code based on the version difference:
 * - Green if the versions are the same.
 * - Orange if the patch version number is different.
 * - Red if the minor version number is different.
 *
 * @param {string} currentVersion The current version of the app on the device
 * @param {object} latestVersions The latest versions available for different platforms.
 * @param {string} deviceType The type of the device, either 'ios' or 'android'.
 * @return {string} A color code representing the update status: "green", "orange", "red" or null.
 */
export function getAppVersionCode(
  currentVersion,
  latestVersions,
  deviceType,
) {
  if (!currentVersion || !deviceType) return;

  const latestVersion = latestVersions[deviceType.toLowerCase()];
  if (currentVersion === latestVersion) {
    return COLORS_STATUS.SUCCESS;
  }

  const [currentCoreVersion, currentMajorVersion, currentMinorVersion] = currentVersion.split('.');
  const [latestCoreVersion, latestMajorVersion, latestMinorVersion] = latestVersion.split('.');

  if (
    currentMinorVersion !== latestMinorVersion
    && currentMajorVersion === latestMajorVersion
    && currentCoreVersion === latestCoreVersion
  ) {
    return COLORS_STATUS.WARNING;
  }

  return COLORS_STATUS.DANGER;
}