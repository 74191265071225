import React, {useContext, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import Lang from '../../lang';
import {API_BASE_URL} from '../../config';
import geocode from '../../geo/geocode';
import FormGroup from '../../ui/FormGroup';
import CheckGroup from '../../ui/CheckGroup';
import UserSelector from '../../users/components/Selector';
import {Public, Open, Private} from './Type';
import ClientSelector from "../../clients/components/Selector";
import Auth from "../../auth";
import MapWithRang from "../../geo/MapWithRang";
import { NetworkContext } from '../Show';

const locate = async (getValues, setValue, setCoords) => {
  const {address} = getValues();
  try {
    const res = await geocode(`address=${address}`);
    const {lat, lng} = res.geometry.location;
    setCoords(lat, lng);
    setValue('address', res.formatted_address);
  } catch (err) {
    console.error(err);
  }
}

const Form = ({network = defaultNetwork, onSave, clientId}) => {
	const {hasRole} = useContext(Auth);
	const {lang} = useContext(Lang);
	const {networkType, notificationRange, updateNetworkContext} = useContext(NetworkContext);

  const {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm();

  const lat = watch('lat', network.lat)
  const lng = watch('lng', network.lng)

  const [ownerId, setOwnerId] = useState(null);

  const setCoords = (lat, lng) => {
    setValue('lat', lat);
    setValue('lng', lng);
    setCoordsDirty(true);
  }

	const onChangeNetworkType = (e) => {
		updateNetworkContext({ networkType: e.target.value });
	}

	const [coordsDirty, setCoordsDirty] = useState(false);

  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (!coordsDirty) return;
    geocode(`latlng=${lat},${lng}`)
      .then(res => setValue('address', res.formatted_address))
      .catch(err => console.error(err));
  }, [coordsDirty, lat, lng, setValue])

  const save = data => {
    setAlert(null);
    setSaving(true);

    data.lat = parseFloat(data.lat);
    data.lng = parseFloat(data.lng);

    let req = network.id ?
      axios.put(`${API_BASE_URL}/networks/${network.id}`, data) :
      axios.post(`${API_BASE_URL}/networks`, {
        ...data, ownerId
      });

    req.then(res => {
      setSaving(false);
      setAlert({
        type: 'success',
        icon: 'check-circle-outline',
        text: lang.network_save_success,
      });
      onSave({...network, ...data, id: res.data.id});
      reset();
    }).catch(() => {
      setSaving(false);
      setAlert({
        type: 'danger',
        icon: 'alert-circle-outline',
        text: lang.network_save_error,
      });
    });
  }

  return (
    <div className="row">
      <form className="col-md-7" onSubmit={handleSubmit(save)}>
        <div className="form-row">
          {/* clientId WAS passed from parameter, lets just output it */}
          { clientId &&
            <input
              name="clientId"
              type="hidden"
              value={clientId}
              ref={register}/>
          }
          {/* clientId WAS NOT passed from parameter, lets allow the user to select it from the select box */}
          { !clientId &&
            <div className="form-group col-md-6">
              <label>{lang.client_affiliation}</label>
              <ClientSelector
                defaultValue={ network.clientId ? {id: network.clientId, name: network.clientName} : null }
                onChange={(curClient) => {
                  const newClientId = (curClient ? curClient.id : null);
                  network.clientId = newClientId;
                  setValue('clientId', newClientId, { shouldValidate: true });
                }}
              />
              <div className="input-group">
                <input
                  ref={hasRole('admin') ? register : register({required: lang.client_is_required})}
                  name='clientId'
                  type='text'
                  style={{display: 'none'}}
                  defaultValue={network.clientId}
                />
              </div>
              {errors.clientId &&
                <p className="error text-danger">{errors.clientId.message}</p>
              }
            </div>
          }

          {/* Network user owner selectbox */}
          {!network.id &&
            <FormGroup
              className="col-md-6"
              title={lang.network_owner}>
              <UserSelector
                clientId={clientId}
                onChange={u => setOwnerId(u.id, u ? u.id : null)}
              />
            </FormGroup>
          }
        </div>
        <div className="form-row">
          <FormGroup
            className="col-md-6"
            name="name"
            inputRef={register({required: lang.network_name_error})}
            title={lang.network_name}
            value={network.name}
            error={errors.name}
          />
          <FormGroup
            className="col-md-6"
            name="description"
            inputRef={register}
            title={lang.network_description}
            value={network.description}
          />
        </div>
        <FormGroup
          title={lang.network_type}>
          <CheckGroup
            type="radio"
            name="type"
            onChange={onChangeNetworkType}
            inputRef={register}
            value={network.type}
            options={{
              public: <Public/>,
              open: <Open/>,
              private: <Private/>,
            }}
          />
        </FormGroup>
        <div className={`form-group ${errors.address && 'has-danger'}`}>
          <label>{lang.network_address}</label>
          <div className="input-group">
            <input
              className={`form-control ${errors.address && 'form-control-danger'}`}
              type="text"
              name="address"
              ref={register({required: lang.network_address_error})}
              placeholder={lang.network_address}
              defaultValue={network.address}
            />
            <div className="input-group-append">
              <button
                className="btn btn-sm btn-primary"
                onClick={ev => {
                  ev.preventDefault();
                  locate(getValues, setValue, setCoords);
                }}>
                {lang.locate}
              </button>
            </div>
          </div>
          {errors.address &&
            <p className="error text-danger">{errors.address.message}</p>}
        </div>
        <div className="form-row">
          <FormGroup
            className="col-md-6"
            name="lat"
            inputRef={register({required: lang.network_lat_error})}
            title={lang.latitude}
            value={network.lat}
            error={errors.lat}
          />
          <FormGroup
            className="col-md-6"
            name="lng"
            inputRef={register({required: lang.network_lng_error})}
            title={lang.longitude}
            value={network.lng}
            error={errors.lng}
          />
        </div>
        <div className="form-row">
          <div className="col">
            <button className="btn btn-primary"
                    disabled={!formState.dirty || saving}>
              {lang.save}
            </button>
          </div>
          <div className="col-md-auto">
            {saving &&
              <div className="spinner-border"/>}
            {alert &&
              <div className={`alert alert-${alert.type}`}>
                <i className={`mdi mdi-${alert.icon}`}/>
                {alert.text}
              </div>}
          </div>
        </div>
      </form>
      <div className="row col-md-5">
        <MapWithRang
          lat={parseFloat(lat)}
          lng={parseFloat(lng)}
          onChange={setCoords}
          networkType={networkType}
          rang={notificationRange}
        />
      </div>
    </div>
  );
}

export default Form;


const defaultNetwork = {
  name: '',
  type: 'open',
  description: '',
  address: '',
  lat: 59.3243556,
  lng: 18.0705097
};
