import React, { useContext, useState, useCallback } from 'react';
import axios from 'axios';

import Lang from '../../lang';
import { API_BASE_URL } from '../../config';

import UserSelector from '../../users/components/Selector';


const ClientUserAdder = ({ clientId, onAdd, onDone }) => {
    const { lang } = useContext(Lang);

    const [ids, setIds] = useState([]);

    const add = useCallback(async () => {
        const res = await axios.put(
            `${API_BASE_URL}/clients/${clientId}/users`,
            { userIds: ids }
        )
        onDone();
        onAdd(res.data);
    }, [ids, clientId, onAdd, onDone]);

    return (
        <div className="row">
            <div className="col">
                <UserSelector
                    isMulti={true}
                    onChange={us => setIds((us || []).map(u => u.id))} />
            </div>
            <div className="col-auto">
                <button className="btn btn-success"
                    onClick={add}>
                    {lang.add_existing_user}
                </button>
            </div>
        </div>
    );
}

export default ClientUserAdder;
