import React, {useContext} from 'react';
import Lang from "../../lang";

const SettingRowOption = (props) => {
  const {lang} = useContext(Lang);

  const {
    name,
    keys,
    onChange,
  } = props;

  return (
    <div className="form-row">
      <div className="col-md-3">
        <label className="padding-top-7">{lang[name]}</label>
      </div>
      <div className="col-md-1">
        <div className="form-inline margin-right-left-47">
          <div className="form-group">
            <select onChange={onChange} name={name} value={keys.find(key => key.selected).value}>
              {keys.map(key => <option key={key.value} value={key.value}>{lang[key.value]}</option>)}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingRowOption;

