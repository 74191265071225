import React, { useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Lang from '../lang';
import Card from '../ui/Card';
import List from './components/List';

const Index = (_props) => {
    const { lang } = useContext(Lang);
    const { url } = useRouteMatch();
    let history = useHistory();

    const show = (device) => history.push(`${url}/${device.id}`);
    const add = () => history.push(`${url}/new`);

    return (
        <>
        <div className="alert alert-fill-info">
            <i className="mdi mdi-information"></i>
            <a className="alert-link" href="/v2">{lang.new_backoffice_devices}</a>
        </div>
        <Card>
            <List onClick={show}>
                <div className="col-md-6">
                    <button className="btn btn-success" onClick={add}>
                        {lang.add_device}
                    </button>
                </div>
            </List>
        </Card>
        </>
    );
};

export default Index;
