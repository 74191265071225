import React from 'react';

const badge = (txt) => () =>
    <span className={`badge badge-subscription-${txt.toLowerCase()}`}>
        {txt}
    </span>

export const Micro = badge('MICRO');
export const Mini = badge('MINI');
export const Medi = badge('MEDI');
export const Maxi = badge('MAXI');
export const Mega = badge('MEGA');
export const Macro = badge('MACRO');

const Subscription = ({ user }) => <>
    {user.subscription === 'micro' && <Micro />}
    {user.subscription === 'mini' && <Mini />}
    {user.subscription === 'medi' && <Medi />}
    {user.subscription === 'maxi' && <Maxi />}
    {user.subscription === 'mega' && <Mega />}
    {user.subscription === 'macro' && <Macro />}
</>;

export default Subscription;
