import React, { useContext, useState } from 'react';

import useLoader from '../../hooks/loader';
import Lang from '../../lang';
import DataTable from '../../ui/DataTable';


const List = ({ url, children }) => {
    const { lang } = useContext(Lang);
    const columns = [
        {
            dataField: 'code',
            text: lang.device_port_code,
            sort: true,
        },
        {
            dataField: 'name',
            text: lang.device_port_name,
            sort: true,
        },
        {
            dataField: 'active',
            text: lang.device_port_active,
            sort: true,
            formatter: v =>
                <span className={`badge badge-${v ? 'success' : 'danger'}`}>
                    {v ? lang.enabled : lang.disabled}
                </span>,
        },
    ];

    const [ports, setPorts] = useState([]);
    const loading = useLoader(url, setPorts);

    return (
        <DataTable
            keyField="code"
            loading={loading}
            columns={columns}
            data={ports}
            defaultSort={[{
                dataField: 'code',
                order: 'asc',
            }]}>
            {children}
        </DataTable>
    )
};

export default List;
