import React, {useContext, useState} from 'react'
import {NavLink} from "react-router-dom";
import Lang from '../lang';
import Spinner from "./Spinner";
import useLoader from "../hooks/loader";
import {API_BASE_URL, NEW_BACKOFFICE_URL} from "../config";
import Auth from "../auth";

const Link = ({to, icon, title, exact}) => (
  <li className="nav-item">
    <NavLink
      exact={exact}
      to={to}
      className="nav-link"
      activeClassName="active">
      <i className={`mdi mdi-${icon} menu-icon`}></i>
      <span className="menu-title">{title}</span>
    </NavLink>
  </li>
);

const SideBar = () => {
  const {lang} = useContext(Lang);
  const {
    user: {
      roles,
    },
    hasRole,
  } = useContext(Auth);

  const [heartbeats, setHeartbeats] = useState([]);
  const loading = useLoader(`${API_BASE_URL}/heartbeats`, setHeartbeats);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <Link
          exact={true}
          to="/"
          icon="view-dashboard-outline"
          title={lang.dashboard}/>
        <Link
          to="/clients"
          icon="book-open"
          title={lang.clients}/>
        <Link
          to="/users"
          icon="account-multiple"
          title={lang.users}/>
        <Link
          to="/networks"
          icon="access-point-network"
          title={lang.networks}/>
        <Link
          to="/devices"
          icon="cellphone-sound"
          title={lang.devices}/>
        <li className="nav-item">
          <a href={NEW_BACKOFFICE_URL} className="nav-link">
            <i className="mdi mdi-monitor-cellphone-star menu-icon"></i>
            <span>{lang.new_backoffice}</span>
          </a>
        </li>
        {hasRole('admin') &&
          heartbeats.map(curHeartbeat =>(
            <li className="nav-item" key={curHeartbeat.name}>
              <div className={`badge badge-${curHeartbeat.status === 'OK' ? 'success' : 'danger'}`} style={{width: '86%', margin: '0 16px', padding: '10px'}}>
                Heartbeat {curHeartbeat.name}:
                &nbsp;
                {loading ? <Spinner/> : curHeartbeat.status}
              </div>
            </li>
          ))
        }
      </ul>
    </nav>
  );
}

export default SideBar;
