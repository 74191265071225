import React, {useContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import axios from 'axios';

import Lang from '../../lang';
import {API_BASE_URL} from '../../config';
import useLoader from '../../hooks/loader';

import NetworkType from '../../networks/components/Type';
import Spinner from '../../ui/Spinner';
import DataSelect from '../../ui/DataSelect';
import FormGroup from '../../ui/FormGroup';
import FormCheckGroup from '../../ui/FormCheckGroup';
import { enforceMinMax } from '../../helpers';

const Policy = ({clientId}) => {
  const {lang} = useContext(Lang);

  const [policy, setPolicy] = useState({});
  const [selectedNetworks, setSelectedNetworks] = useState({});
  const loading = useLoader(`${API_BASE_URL}/clients/${clientId}/policy`, setPolicy);

  const {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    reset,
  } = useForm();

  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState(null);

  const save = data => {
    setAlert(null);
    setSaving(true);

    data.sosSelectedNetworks        = (selectedNetworks.sos         || policy.sosSelectedNetworks         || []).map(n => n.id);
    data.amcSelectedNetworks        = (selectedNetworks.amc         || policy.amcSelectedNetworks         || []).map(n => n.id);
    data.networkSelectedNetworks    = (selectedNetworks.network     || policy.networkSelectedNetworks     || []).map(n => n.id);
    data.safeAssistSelectedNetworks = (selectedNetworks.safeAssist  || policy.safeAssistSelectedNetworks  || []).map(n => n.id);

    axios.put(`${API_BASE_URL}/clients/${clientId}/policy`, data).then(() => {
      setSaving(false);
      setAlert({
        type: 'success',
        icon: 'check-circle-outline',
        text: lang.client_policy_save_success,
      });
      reset(data);
    }).catch(() => {
      setSaving(false);
      setAlert({
        type: 'danger',
        icon: 'alert-circle-outline',
        text: lang.client_policy_save_error,
      });
    });
  }

  if (loading) {
    return <Spinner/>;
  }

  return (
    <form onSubmit={handleSubmit(save)}>
      <h3 style={{paddingBottom: '0.5em'}}>{lang.policy_sos}</h3>
      <div className="form-row">
        <div className="col-sm-12 col-xl-8">
          <FormCheckGroup
              name="sosEnforced"
              inputRef={register}
              title={lang.policy_enforce}
              checked={policy.sosEnforced}
              onChange={(e) => {
                const newChecked = e.target.checked;
                policy.sosEnforced = newChecked;
                setValue('sosEnforced', newChecked, { shouldValidate: true });
                policy.sosDisabled &= newChecked;
                setValue('sosDisabled', policy.sosDisabled, { shouldValidate: true });
              }}
            />
            <FormCheckGroup
              name="sosDisabled"
              inputRef={register}
              title={lang.policy_disable}
              checked={policy.sosDisabled}
              disabled={!policy.sosEnforced}
              onChange={(e) => {
                const newChecked = e.target.checked;
                policy.sosDisabled = newChecked;
                setValue('sosDisabled', newChecked, { shouldValidate: true });
              }}
            />
            <FormGroup
              name="sosTitle"
              inputRef={register}
              title={lang.policy_title}
              value={policy.sosTitle}
              readOnly={!policy.sosEnforced}
            />
            <FormGroup
              title={lang.policy_description}
              error={errors.sosDescription}>
                <textarea
                  rows={5}
                  name="sosDescription"
                  className="form-control"
                  ref={register}
                  defaultValue={policy.sosDescription}
                  style={{width: "100%"}}
                  readOnly={!policy.sosEnforced}
                ></textarea>
            </FormGroup>
          </div>
          <div className="col-sm-12 col-xl-4">
            <div className={'form-group'}>
              <h5>{lang.dynamic_alert}</h5>
            </div>
            <FormCheckGroup
              name="sosCreatePublicAlert"
              inputRef={register}
              title={lang.policy_create_public_alert}
              checked={policy.sosCreatePublicAlert}
              disabled={!policy.sosEnforced}
              onChange={(e) => {
                const newChecked = e.target.checked;
                policy.sosCreatePublicAlert = newChecked;
                setValue('sosCreatePublicAlert', newChecked, { shouldValidate: true });
              }}
            />
            <FormCheckGroup
              name="sosAlertAllAvailableNetworks"
              inputRef={register}
              title={lang.policy_alert_all_available_networks}
              checked={policy.sosAlertAllAvailableNetworks}
              disabled={!policy.sosEnforced || !policy.sosCreatePublicAlert}
            />
            <FormCheckGroup
              name="sosAlertAllAvailableUsers"
              inputRef={register}
              title={lang.policy_alert_all_available_users}
              checked={policy.sosAlertAllAvailableUsers}
              disabled={!policy.sosEnforced || !policy.sosCreatePublicAlert}
            />
            <FormCheckGroup
              name="sosPublicRadiusEnabled"
              inputRef={register}
              title={lang.policy_public_radius_enabled}
              checked={policy.sosPublicRadiusEnabled}
              disabled={!policy.sosEnforced || !policy.sosCreatePublicAlert}
              onChange={(e) => {
                const newChecked = e.target.checked;
                policy.sosPublicRadiusEnabled = newChecked;
                setValue('sosPublicRadiusEnabled', newChecked, { shouldValidate: true });
              }}
            />
            <FormGroup
              name="sosPublicRadius"
              type="number"
              inputRef={register}
              title={lang.policy_public_radius_title}
              placeholder={lang.policy_public_radius_placeholder}
              min={10}
              max={100000}
              onkeyup={(e) => enforceMinMax(e.target)}
              value={policy.sosPublicRadius || 10}
              readOnly={!policy.sosEnforced || !policy.sosCreatePublicAlert || !policy.sosPublicRadiusEnabled}
            />
            <hr/>
            <FormGroup
              title={lang.policy_selected_networks}
              error={errors.sosSelectedNetworks}>
              <input
                type="hidden"
                name="sosSelectedNetworks"
                ref={register}
                defaultValue={policy.sosSelectedNetworks}/>
              <DataSelect
                isMulti={true}
                url={`${API_BASE_URL}/networks/search`}
                defaultValue={policy.sosSelectedNetworks}
                onChange={ns => {
                  ns = ns || [];
                  setValue('sosSelectedNetworks', ns);
                  setSelectedNetworks({
                    ...selectedNetworks,
                    sos: ns
                  });
                }}
                formatLabel={formatLabel}
                isDisabled={!policy.sosEnforced}
              />
            </FormGroup>
            <FormCheckGroup
              name="sosSelectedNetworksRadiusEnabled"
              inputRef={register}
              title={lang.policy_selected_networks_radius_enabled}
              checked={policy.sosSelectedNetworksRadiusEnabled}
              disabled={!policy.sosEnforced}
              onChange={(e) => {
                const newChecked = e.target.checked;
                policy.sosSelectedNetworksRadiusEnabled = newChecked;
                setValue('sosSelectedNetworksRadiusEnabled', newChecked, { shouldValidate: true });
              }}
            />
            <FormGroup
              name="sosSelectedNetworksRadius"
              type="number"
              inputRef={register}
              title={lang.policy_selected_networks_radius_title}
              placeholder={lang.policy_selected_networks_radius_placeholder}
              min={10}
              max={100000}
              onkeyup={(e) => enforceMinMax(e.target)}
              value={policy.sosSelectedNetworksRadius || 10}
              readOnly={!policy.sosEnforced || !policy.sosSelectedNetworksRadiusEnabled}
          />
        </div>
      </div>
      <hr/>
      <h3 style={{paddingBottom: '0.5em'}}>{lang.policy_amc}</h3>
      <div className="form-row">
        <div className="col-sm-12 col-xl-8">
          <FormCheckGroup
            name="amcEnforced"
            inputRef={register}
            title={lang.policy_enforce}
            checked={policy.amcEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.amcEnforced = newChecked;
              setValue('amcEnforced', newChecked, { shouldValidate: true });
              policy.amcDisabled &= newChecked;
              setValue('amcDisabled', policy.amcDisabled, { shouldValidate: true });
            }}
          />
          <FormCheckGroup
            name="amcDisabled"
            inputRef={register}
            title={lang.policy_disable}
            checked={policy.amcDisabled}
            disabled={!policy.amcEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.amcDisabled = newChecked;
              setValue('amcDisabled', newChecked, { shouldValidate: true });
            }}
          />
          <FormGroup
            name="amcTitle"
            inputRef={register}
            title={lang.policy_title}
            value={policy.amcTitle}
            readOnly={!policy.amcEnforced}
          />
          <FormGroup
            title={lang.policy_description}
            error={errors.amcDescription}>
              <textarea
                rows={5}
                name="amcDescription"
                className="form-control"
                ref={register}
                defaultValue={policy.amcDescription}
                readOnly={!policy.amcEnforced}
                style={{width: "100%"}}></textarea>
          </FormGroup>
        </div>
        <div className="col-sm-12 col-xl-4">
          <div className={'form-group'}>
            <h5>{lang.dynamic_alert}</h5>
          </div>
          <FormCheckGroup
            name="amcCreatePublicAlert"
            inputRef={register}
            title={lang.policy_create_public_alert}
            checked={policy.amcCreatePublicAlert}
            disabled={!policy.amcEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.amcCreatePublicAlert = newChecked;
              setValue('amcCreatePublicAlert', newChecked, { shouldValidate: true });
            }}
          />
          <FormCheckGroup
            name="amcAlertAllAvailableNetworks"
            inputRef={register}
            title={lang.policy_alert_all_available_networks}
            checked={policy.amcAlertAllAvailableNetworks}
            disabled={!policy.amcEnforced || !policy.amcCreatePublicAlert}
          />
          <FormCheckGroup
            name="amcAlertAllAvailableUsers"
            inputRef={register}
            title={lang.policy_alert_all_available_users}
            checked={policy.amcAlertAllAvailableUsers}
            disabled={!policy.amcEnforced || !policy.amcCreatePublicAlert}
          />
          <FormCheckGroup
            name="amcPublicRadiusEnabled"
            inputRef={register}
            title={lang.policy_public_radius_enabled}
            checked={policy.amcPublicRadiusEnabled}
            disabled={!policy.amcEnforced || !policy.amcCreatePublicAlert}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.amcPublicRadiusEnabled = newChecked;
              setValue('amcPublicRadiusEnabled', newChecked, { shouldValidate: true });
            }}
          />
          <FormGroup
            name="amcPublicRadius"
            type="number"
            inputRef={register}
            title={lang.policy_public_radius_title}
            placeholder={lang.policy_public_radius_placeholder}
            min={10}
            max={100000}
            onkeyup={(e) => enforceMinMax(e.target)}
            value={policy.amcPublicRadius || 10}
            readOnly={!policy.amcEnforced || !policy.amcCreatePublicAlert  || !policy.amcPublicRadiusEnabled}
          />
          <hr/>
          <FormGroup
            title={lang.policy_selected_networks}
            error={errors.amcSelectedNetworks}>
            <input
              type="hidden"
              name="amcSelectedNetworks"
              ref={register}
              defaultValue={policy.amcSelectedNetworks}/>
            <DataSelect
              isMulti={true}
              url={`${API_BASE_URL}/networks/search`}
              defaultValue={policy.amcSelectedNetworks}
              onChange={ns => {
                ns = ns || [];
                setValue('amcSelectedNetworks', ns);
                setSelectedNetworks({
                  ...selectedNetworks,
                  amc: ns
                });
              }}
              formatLabel={formatLabel}
              isDisabled={!policy.amcEnforced}
            />
          </FormGroup>
          <FormCheckGroup
            name="amcSelectedNetworksRadiusEnabled"
            inputRef={register}
            title={lang.policy_selected_networks_radius_enabled}
            checked={policy.amcSelectedNetworksRadiusEnabled}
            disabled={!policy.amcEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.amcSelectedNetworksRadiusEnabled = newChecked;
              setValue('amcSelectedNetworksRadiusEnabled', newChecked, { shouldValidate: true });
            }}
          />
          <FormGroup
            name="amcSelectedNetworksRadius"
            type="number"
            inputRef={register}
            title={lang.policy_selected_networks_radius_title}
            placeholder={lang.policy_selected_networks_radius_placeholder}
            min={10}
            max={100000}
            onkeyup={(e) => enforceMinMax(e.target)}
            value={policy.amcSelectedNetworksRadius || 10}
            readOnly={!policy.amcEnforced || !policy.amcSelectedNetworksRadiusEnabled}
          />
        </div>
      </div>
      <hr/>
      <h3 style={{paddingBottom: '0.5em'}}>{lang.policy_network}</h3>
      <div className="form-row">
        <div className="col-sm-12 col-xl-8">
          <FormCheckGroup
            name="networkEnforced"
            inputRef={register}
            title={lang.policy_enforce}
            checked={policy.networkEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.networkEnforced = newChecked;
              setValue('networkEnforced', newChecked, { shouldValidate: true });
              policy.networkDisabled &= newChecked;
              setValue('networkDisabled', policy.networkDisabled, { shouldValidate: true });
            }}
          />
          <FormCheckGroup
            name="networkDisabled"
            inputRef={register}
            title={lang.policy_disable}
            checked={policy.networkDisabled}
            disabled={!policy.networkEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.networkDisabled = newChecked;
              setValue('networkDisabled', newChecked, { shouldValidate: true });
            }}
          />
          <FormGroup
            name="networkTitle"
            inputRef={register}
            title={lang.policy_title}
            value={policy.networkTitle}
            readOnly={!policy.networkEnforced}
          />
          <FormGroup
            title={lang.policy_description}
            error={errors.networkDescription}>
              <textarea
                rows={5}
                name="networkDescription"
                className="form-control"
                ref={register}
                defaultValue={policy.networkDescription}
                readOnly={!policy.networkEnforced}
                style={{width: "100%"}}></textarea>
          </FormGroup>
        </div>
        <div className="col-sm-12 col-xl-4">
          <div className={'form-group'}>
            <h5>{lang.dynamic_alert}</h5>
          </div>
          <FormCheckGroup
            name="networkCreatePublicAlert"
            inputRef={register}
            title={lang.policy_create_public_alert}
            checked={policy.networkCreatePublicAlert}
            disabled={!policy.networkEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.networkCreatePublicAlert = newChecked;
              setValue('networkCreatePublicAlert', newChecked, { shouldValidate: true });
            }}
          />
          <FormCheckGroup
            name="networkAlertAllAvailableNetworks"
            inputRef={register}
            title={lang.policy_alert_all_available_networks}
            checked={policy.networkAlertAllAvailableNetworks}
            disabled={!policy.networkEnforced || !policy.networkCreatePublicAlert}
          />
          <FormCheckGroup
            name="networkPublicRadiusEnabled"
            inputRef={register}
            title={lang.policy_public_radius_enabled}
            checked={policy.networkPublicRadiusEnabled}
            disabled={!policy.networkEnforced || !policy.networkCreatePublicAlert}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.networkPublicRadiusEnabled = newChecked;
              setValue('networkPublicRadiusEnabled', newChecked, { shouldValidate: true });
            }}
          />
          <FormGroup
            name="networkPublicRadius"
            type="number"
            inputRef={register}
            title={lang.policy_public_radius_title}
            placeholder={lang.policy_public_radius_placeholder}
            min={10}
            max={100000}
            onkeyup={(e) => enforceMinMax(e.target)}
            value={policy.networkPublicRadius || 10}
            readOnly={!policy.networkEnforced || !policy.networkCreatePublicAlert || !policy.networkPublicRadiusEnabled}
          />
          <hr/>
          <FormGroup
            title={lang.policy_selected_networks}
            error={errors.networkSelectedNetworks}>
            <input
              type="hidden"
              name="networkSelectedNetworks"
              ref={register}
              defaultValue={policy.networkSelectedNetworks}/>
            <DataSelect
              isMulti={true}
              url={`${API_BASE_URL}/networks/search`}
              defaultValue={policy.networkSelectedNetworks}
              onChange={ns => {
                ns = ns || [];
                setValue('networkSelectedNetworks', ns);
                setSelectedNetworks({
                  ...selectedNetworks,
                  network: ns
                });
              }}
              formatLabel={formatLabel}
              isDisabled={!policy.networkEnforced}
            />
          </FormGroup>
          <FormCheckGroup
            name="networkSelectedNetworksRadiusEnabled"
            inputRef={register}
            title={lang.policy_selected_networks_radius_enabled}
            checked={policy.networkSelectedNetworksRadiusEnabled}
            disabled={!policy.networkEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.networkSelectedNetworksRadiusEnabled = newChecked;
              setValue('networkSelectedNetworksRadiusEnabled', newChecked, { shouldValidate: true });
            }}
          />
          <FormGroup
            name="networkSelectedNetworksRadius"
            type="number"
            inputRef={register}
            title={lang.policy_selected_networks_radius_title}
            placeholder={lang.policy_selected_networks_radius_placeholder}
            min={10}
            max={100000}
            onkeyup={(e) => enforceMinMax(e.target)}
            value={policy.networkSelectedNetworksRadius || 10}
            readOnly={!policy.networkEnforced || !policy.networkSelectedNetworksRadiusEnabled}
          />
        </div>
      </div>
      <hr/>
      <h3 style={{paddingBottom: '0.5em'}}>{lang.policy_safe_assist}</h3>
      <div className="form-row">
        <div className="col-sm-12 col-xl-8">
          <FormCheckGroup
            name="safeAssistEnforced"
            inputRef={register}
            title={lang.policy_enforce}
            checked={policy.safeAssistEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.safeAssistEnforced = newChecked;
              setValue('safeAssistEnforced', newChecked, { shouldValidate: true });
              policy.safeAssistDisabled &= newChecked;
              setValue('safeAssistDisabled', policy.safeAssistDisabled, { shouldValidate: true });
            }}
          />
          <FormCheckGroup
            name="safeAssistDisabled"
            inputRef={register}
            title={lang.policy_disable}
            checked={policy.safeAssistDisabled}
            disabled={!policy.safeAssistEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.safeAssistDisabled = newChecked;
              setValue('safeAssistDisabled', newChecked, { shouldValidate: true });
            }}
          />
          <FormGroup
            name="safeAssistTitle"
            inputRef={register}
            title={lang.policy_title}
            value={policy.safeAssistTitle}
            readOnly={!policy.safeAssistEnforced}
          />
          <FormGroup
            title={lang.policy_description}
            error={errors.safeAssistDescription}>
              <textarea
                rows={5}
                name="safeAssistDescription"
                className="form-control"
                ref={register}
                defaultValue={policy.safeAssistDescription}
                readOnly={!policy.safeAssistEnforced}
                style={{width: "100%"}}></textarea>
          </FormGroup>
        </div>
        <div className="col-sm-12 col-xl-4">
          <div className={'form-group'}>
            <h5>{lang.dynamic_alert}</h5>
          </div>
          <FormCheckGroup
            name="safeAssistCreatePublicAlert"
            inputRef={register}
            title={lang.policy_create_public_alert}
            checked={policy.safeAssistCreatePublicAlert}
            disabled={!policy.safeAssistEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.safeAssistCreatePublicAlert = newChecked;
              setValue('safeAssistCreatePublicAlert', newChecked, { shouldValidate: true });
            }}
          />
          <FormCheckGroup
            name="safeAssistAlertAllAvailableNetworks"
            inputRef={register}
            title={lang.policy_alert_all_available_networks}
            checked={policy.safeAssistAlertAllAvailableNetworks}
            disabled={!policy.safeAssistEnforced || !policy.safeAssistCreatePublicAlert}
          />
          <FormCheckGroup
            name="safeAssistAlertAllAvailableUsers"
            inputRef={register}
            title={lang.policy_alert_all_available_users}
            checked={policy.safeAssistAlertAllAvailableUsers}
            disabled={!policy.safeAssistEnforced || !policy.safeAssistCreatePublicAlert}
          />
          <FormCheckGroup
            name="safeAssistPublicRadiusEnabled"
            inputRef={register}
            title={lang.policy_public_radius_enabled}
            checked={policy.safeAssistPublicRadiusEnabled}
            disabled={!policy.safeAssistEnforced || !policy.safeAssistCreatePublicAlert}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.safeAssistPublicRadiusEnabled = newChecked;
              setValue('safeAssistPublicRadiusEnabled', newChecked, { shouldValidate: true });
            }}
          />
          <FormGroup
            name="safeAssistPublicRadius"
            type="number"
            inputRef={register}
            title={lang.policy_public_radius_title}
            placeholder={lang.policy_public_radius_placeholder}
            min={10}
            max={100000}
            onkeyup={(e) => enforceMinMax(e.target)}
            value={policy.safeAssistPublicRadius || 10}
            readOnly={!policy.safeAssistEnforced || !policy.safeAssistCreatePublicAlert || !policy.safeAssistPublicRadiusEnabled}
          />
          <hr/>
          <FormGroup
            title={lang.policy_selected_networks}
            error={errors.safeAssistSelectedNetworks}>
            <input
              type="hidden"
              name="safeAssistSelectedNetworks"
              ref={register}
              defaultValue={policy.safeAssistSelectedNetworks}/>
            <DataSelect
              isMulti={true}
              url={`${API_BASE_URL}/networks/search`}
              defaultValue={policy.safeAssistSelectedNetworks}
              onChange={ns => {
                ns = ns || [];
                setValue('safeAssistSelectedNetworks', ns);
                setSelectedNetworks({
                  ...selectedNetworks,
                  safeAssist: ns
                });
              }}
              formatLabel={formatLabel}
              isDisabled={!policy.safeAssistEnforced}
            />
          </FormGroup>
          <FormCheckGroup
            name="safeAssistSelectedNetworksRadiusEnabled"
            inputRef={register}
            title={lang.policy_selected_networks_radius_enabled}
            checked={policy.safeAssistSelectedNetworksRadiusEnabled}
            disabled={!policy.safeAssistEnforced}
            onChange={(e) => {
              const newChecked = e.target.checked;
              policy.safeAssistSelectedNetworksRadiusEnabled = newChecked;
              setValue('safeAssistSelectedNetworksRadiusEnabled', newChecked, { shouldValidate: true });
            }}
          />
          <FormGroup
            name="safeAssistSelectedNetworksRadius"
            type="number"
            inputRef={register}
            title={lang.policy_selected_networks_radius_title}
            placeholder={lang.policy_selected_networks_radius_placeholder}
            min={10}
            max={100000}
            onkeyup={(e) => enforceMinMax(e.target)}
            value={policy.safeAssistSelectedNetworksRadius || 10}
            readOnly={!policy.safeAssistEnforced || !policy.safeAssistSelectedNetworksRadiusEnabled}
          />
        </div>
      </div>
      <hr/>

      <div className="form-row">
        <div className="col-md-auto">
          <button className="btn btn-primary"
                  disabled={!formState.dirty || saving}>
            {lang.save}
          </button>
        </div>
        <div className="col-md-auto">
          {saving &&
            <div className="spinner-border"/>}
          {alert &&
            <div className={`alert alert-${alert.type}`}>
              <i className={`mdi mdi-${alert.icon}`}/>
              {alert.text}
            </div>}
        </div>
      </div>
    </form>
  );
}

const formatLabel = (network) => <>
  <NetworkType network={network}/> {network.name}
</>;

export default Policy;
