import React, {useContext} from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Lang from '../lang';
import Styles from '../styles/Select';

const DataSelect = (props) => {
  const {inputKey, url, formatLabel, isMulti, params, onChange, defaultValue, value, isDisabled, inputRef} = props;
  const {lang} = useContext(Lang);

  const load = q => axios.get(url, {params: {...params, q}})
    .then(res => {
      if (res.data) {
        return res.data.map(e => ({ value: e.id, ...e }));
      } else {
        return [];
      }
    });

  return (
    <AsyncSelect
      ref={inputRef}
      key={inputKey}
      placeholder={lang.select_or_search}
      isMulti={isMulti}
      isClearable
      className="form-control"
      loadOptions={load}
      cacheOptions
      defaultOptions
      formatOptionLabel={formatLabel}
      styles={Styles}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  );
};

export default DataSelect;
