import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { API_BASE_URL } from '../config';
import Lang from '../lang';
import useLoader from '../hooks/loader';

import Card from '../ui/Card';
import Spinner from '../ui/Spinner';

import Form from './components/Form';
import AlertList from './components/AlertList';
import PortList from './components/PortList';

import NetworkList from '../networks/components/List';

const Show = (_props) => {
    const { lang } = useContext(Lang);

    const { deviceId } = useParams();
    const [device, setDevice] = useState({});

    const [tab, setTab] = useState('networks');
    const tabActive = t => tab === t ? 'active' : '';

    const loading = useLoader(`${API_BASE_URL}/devices/${deviceId}`, setDevice);

    if (loading) {
        return <Spinner />;
    }

    return (
        <div>
            <h3>{device.name}</h3>
            <hr />
            <Card>
                <Form device={device} onSave={setDevice} />
            </Card>
            <Card>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <button className={`nav-link ${tabActive('networks')}`}
                            onClick={() => setTab('networks')}>
                            {lang.networks}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${tabActive('ports')}`}
                            onClick={() => setTab('ports')}>
                            {lang.device_ports}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${tabActive('alerts')}`}
                            onClick={() => setTab('alerts')}>
                            {lang.device_alerts}
                        </button>
                    </li>
                </ul>
                <div className="tab-content">
                    {tab === 'networks' &&
                        <NetworkList url={`${API_BASE_URL}/devices/${deviceId}/networks`} />}
                    {tab === 'ports' &&
                        <div className="row">
                            <div className="col-md-6">
                                <PortList url={`${API_BASE_URL}/devices/${deviceId}/inputs`}>
                                    <div className="col-md-6">
                                        <h3>{lang.device_inputs}</h3>
                                    </div>
                                </PortList>
                            </div>
                            <div className="col-md-6">
                                <PortList url={`${API_BASE_URL}/devices/${deviceId}/outputs`}>
                                    <div className="col-md-6">
                                        <h3>{lang.device_outputs}</h3>
                                    </div>
                                </PortList>
                            </div>
                        </div>}
                    {tab === 'alerts' &&
                        <AlertList deviceId={device.id} />}
                </div>
            </Card>
        </div>
    );
};

export default Show;
