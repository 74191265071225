import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Map from './Map';

const SOURCE_ID = 'circle-source';
const LAYER_ID = 'circle-layer';

// Constants for geographical calculations
const EARTH_RADIUS_KM = 111.32; // Earth's radius in kilometers
const DEGREES_TO_RADIANS = Math.PI / 180; // Conversion factor from degrees to radians
const KM_PER_DEGREE_LATITUDE = 110.574; // Kilometers per degree of latitude
const DEFAULT_POINTS = 64; // Number of points used to approximate the circle

const MapWithRang = ({ lat, lng, onChange, rang, networkType = 'public' }) => {
	const [map, setMap] = useState(null);

	const circleColor = useMemo(() => {
		switch (networkType) {
			case 'public':
				return '#00838F';
			case 'open':
				return '#4CAF50';
			case 'private':
				return '#E64A19';
			default:
				return '#00838F';
		}
	}, [networkType]);

	const onStyleLoad = useCallback((mapInstance) => {
		setMap(mapInstance);
	}, []);

	const createGeoJSONCircle = useCallback((center, radiusInKm, points = DEFAULT_POINTS) => {
		const coords = {
			latitude: center[1],
			longitude: center[0]
		};

		const ret = [];

		// Calculate the distance for the circle's radius in both x and y directions
		const distanceX = radiusInKm / (EARTH_RADIUS_KM * Math.cos(coords.latitude * DEGREES_TO_RADIANS));
		const distanceY = radiusInKm / KM_PER_DEGREE_LATITUDE;

		// Generate the points that form the circle
		for (let i = 0; i < points; i++) {
			const theta = (i / points) * (2 * Math.PI); // Calculate angle for current point
			const x = distanceX * Math.cos(theta); // Calculate x coordinate
			const y = distanceY * Math.sin(theta); // Calculate y coordinate
			ret.push([coords.longitude + x, coords.latitude + y]); // Add point to the array
		}

		// Close the circle by adding the first point at the end
		ret.push(ret[0]);

		// Return the GeoJSON object representing the circle
		return {
			type: 'geojson',
			data: {
				type: 'Feature',
				geometry: {
					type: 'Polygon',
					coordinates: [ret]
				}
			}
		};
	}, []);

	useEffect(() => {
		if (!map) return;

		if (!map.getSource(SOURCE_ID)) {
			map.addSource(SOURCE_ID, createGeoJSONCircle([lng, lat], rang / 1000));
			map.addLayer({
				id: LAYER_ID,
				type: 'fill',
				source: SOURCE_ID,
				paint: {
					'fill-color': circleColor,
					'fill-opacity': 0.3
				}
			});
		} else {
			map.setPaintProperty(LAYER_ID, 'fill-color', circleColor);
		}

		const handleZoomEnd = () => {
			map.getSource(SOURCE_ID).setData(createGeoJSONCircle([lng, lat], rang / 1000).data);
		};

		handleZoomEnd();

		map.on('zoomend', handleZoomEnd);
		return () => map && map.off('zoomend', handleZoomEnd);
	}, [map, createGeoJSONCircle, rang, lat, lng, circleColor]);

	return (
		<Map lat={lat} lng={lng} onChange={onChange} onStyleLoad={onStyleLoad}/>
	);
};

export default MapWithRang;
