import React, { useState, useContext } from 'react';
import {useParams, useHistory} from 'react-router-dom';
import { API_BASE_URL } from '../config';
import Lang from '../lang';
import useLoader from '../hooks/loader';
import Form from './components/Form';
import Policy from './components/Policy';
import AlertCentralScreenSettings from './components/AlertCentralScreenSettings';
import NetworkSummary from './components/NetworkSummary';
import SubscriptionSummary from './components/SubscriptionSummary';
import ClientUsers from './components/ClientUsers';
import ClientNetworks from './components/ClientNetworks';
import Card from '../ui/Card';
import Spinner from '../ui/Spinner';
import DeviceList from '../devices/components/List';

const Index = (_props) => {
  const history = useHistory();
  const { lang } = useContext(Lang);
  const { clientId } = useParams();
  const [client, setClient] = useState({});
  const [tab, setTab] = useState('users');
  const loading = useLoader(`${API_BASE_URL}/clients/${clientId}`, setClient);
  const tabActive = (t) => (tab === t ? 'active' : '');

  if (loading) {
    return <Spinner />;
  }

  const deviceShow = (device) => history.push(`/devices/${device.id}`);
  const deviceAdd = () => history.push(`/devices/new`);
  const deviceListUrl = `${API_BASE_URL}/clients/${clientId}/devices`;

  return (
    <div>
      <h3>{client.name}</h3>
      <hr />
      <div className="row">
        <Card className="col-md-7">
          <Form client={client} onSave={setClient} />
        </Card>
        <Card className="col-md-5">
          <div className="row">
            <div className="col-md-6">
              <SubscriptionSummary clientId={client.id} />
            </div>
            <div className="col-md-6">
              <NetworkSummary clientId={client.id} />
            </div>
          </div>
        </Card>
      </div>
      <Card>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${tabActive('users')}`}
              onClick={() => setTab('users')}
            >
              {lang.users}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${tabActive('networks')}`}
              onClick={() => setTab('networks')}
            >
              {lang.networks}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${tabActive('devices')}`}
              onClick={() => setTab('devices')}
            >
              {lang.devices}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${tabActive('policy')}`}
              onClick={() => setTab('policy')}
            >
              {lang.policy}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${tabActive('settings')}`}
              onClick={() => setTab('settings')}
            >
              {lang.settings}
            </button>
          </li>
        </ul>
        <div className="tab-content">
          {tab === 'users' && <ClientUsers clientId={client.id} />}
          {tab === 'networks' && <ClientNetworks clientId={client.id} />}
          {tab === 'devices' && (
            <DeviceList
              onClick={deviceShow}
              url={deviceListUrl}
            >
              <div className="col-md-6">
                <button className="btn btn-success" onClick={deviceAdd}>
                  {lang.add_device}
                </button>
              </div>
            </DeviceList>
          )}
          {tab === 'policy' && <Policy clientId={client.id} />}
          {tab === 'settings' && <AlertCentralScreenSettings clientId={client.id} centralScreenSettings={client.centralScreenSettings} />}
        </div>
      </Card>
    </div>
  );
};

export default Index;
