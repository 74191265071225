import React from 'react';
import axios from 'axios';

import { API_BASE_URL, UAN_API_BASE_URL } from '../config';

export const AuthContext = React.createContext(null);
export default AuthContext;

export const initAuth = () => {
  const accessToken = localStorage.getItem('accessToken');
  const user = localStorage.getItem('user');

  if (accessToken && user) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    return JSON.parse(user);
  }

  return null;
};

const roleNameById = {
  '13': 'admin',
  '14': 'client-admin',
  '16': 'multi-client-admin',
}

export const buildAuth = (user, set) => {
  if (!user) return null;

  return {
    user,
    logout: () => set(logout()),
    getRoleName: (role) => roleNameById[role],
    hasRole: (roles) => {
      if (!Array.isArray(roles)) {
        roles = [roles];
      }

      const userRoles = user.roles.map(r => roleNameById[r]);
      return roles.some((r) => userRoles.includes(r));
    },
  };
};

const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');

  delete axios.defaults.headers.common['Authorization'];

  return null;
};

axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (!err.response || err.response.status !== 401 || !err.config) {
      return Promise.reject(err);
    }

    const url = err.config.url;

    if (url === `${API_BASE_URL}/login` || url === `${UAN_API_BASE_URL}/v2/auth`) {
      return Promise.reject(err);
    }

    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      try {
        delete axios.defaults.headers.common['Authorization'];

        const res = await fetch(`${UAN_API_BASE_URL}/v2/auth/refresh`, {
          method: 'POST',
          headers: { Authorization: `Bearer ${refreshToken}` },
        });

        const tokens = await res.json();

        localStorage.setItem('uan-api-access-token', tokens.accessToken);
        localStorage.setItem('uan-api-refresh-token', tokens.refreshToken);
        localStorage.setItem('accessToken', tokens.accessToken);
        localStorage.setItem('refreshToken', tokens.refreshToken);

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${tokens.accessToken}`;

        let config = err.config;
        config.headers['Authorization'] = `Bearer ${tokens.accessToken}`;

        return axios(config);
      } catch (_err) {
        console.log(_err);
      }
    }

    logout();
    window.location.href = '/';
    return Promise.reject(err);
  }
);

export const v2RefreshToken = async (err) => {
  if (!err.response || err.response.status !== 401 || !err.config) {
    return Promise.reject(err);
  }

  const url = err.config.url;

  if (url === `${API_BASE_URL}/login` || url === `${UAN_API_BASE_URL}/v2/auth`) {
    return Promise.reject(err);
  }

  const authURL = `${UAN_API_BASE_URL}/v2/auth`;

  if (err.response.url === authURL) return false;

  const refreshToken = localStorage.getItem('uan-api-refresh-token');

  if (refreshToken) {
    try {
      const res = await fetch(`${UAN_API_BASE_URL}/v2/auth/refresh`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${refreshToken}` },
      });

      if (res.status === 200) {
        const tokens = await res.json();
        localStorage.setItem('uan-api-access-token', tokens.accessToken);
        localStorage.setItem('uan-api-refresh-token', tokens.refreshToken);
        localStorage.setItem('accessToken', tokens.accessToken);
        localStorage.setItem('refreshToken', tokens.refreshToken);
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  }

  window.location.href = '/';
  return false;
};
