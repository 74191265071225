import React, { useContext, useState } from 'react';

import { API_BASE_URL } from '../../config';
import Lang from '../../lang'

import useLoader from '../../hooks/loader';
import Spinner from '../../ui/Spinner';


const Box = ({ gradient, gradientAngle, title, count, loading }) => {
    const style = {
        background: `linear-gradient(${gradientAngle}deg, ${gradient[0]}, ${gradient[1]})`
    };

    return (
        <div className="col-6 col-md-4 col-lg-3 col-xl-2 grid-margin stretch-card">
            <div className="card text-white text-center card-shadow-primary" style={style}>
                <div className="card-body">
                    <h6 className="font-weight-normal">{title}</h6>
                    {loading && <Spinner />}
                    {loading || <h2 className="mb-0">
                        {count}
                    </h2>}
                </div>
            </div>
        </div>
    );
}

const Stats = (_props) => {
    const { lang } = useContext(Lang);

    const [counts, setCounts] = useState({
        users: 0,
        clients: 0,
        devices: 0,
        networks: 0,
        incidents: 0,
        comments: 0,
    });
    const loading = useLoader(`${API_BASE_URL}/stats/counts`, setCounts);

    const {
        users,
        clients,
        devices,
        networks,
        incidents,
        comments,
    } = counts;

    return (
        <div className="stats-container container-fluid">
            <div className="row">
                <Box
                    title={lang.users}
                    count={users}
                    gradient={gradients.users}
                    gradientAngle={230}
                    loading={loading}
                />
                <Box
                    title={lang.clients}
                    count={clients}
                    gradient={gradients.clients}
                    gradientAngle={120}
                    loading={loading}
                />
                <Box
                    title={lang.devices}
                    count={devices}
                    gradient={gradients.devices}
                    gradientAngle={40}
                    loading={loading}
                />
                <Box
                    title={lang.networks}
                    count={networks}
                    gradient={gradients.networks}
                    gradientAngle={80}
                    loading={loading}
                />
                <Box
                    title={lang.incidents}
                    count={incidents}
                    gradient={gradients.incidents}
                    gradientAngle={140}
                    loading={loading}
                />
                <Box
                    title={lang.comments}
                    count={comments}
                    gradient={gradients.comments}
                    gradientAngle={130}
                    loading={loading}
                />
            </div>
        </div>
    );
}

const gradients = {
    users: ['#fc5286', '#fbaaa2'],
    clients: ['#667eea', '#764ba2'],
    devices: ['#fda085', '#f6d365'],
    networks: ['#0ba360', '#3cba92'],
    incidents: ['#1e3c72', '#1655b2'],
    comments: ['#c471f5', '#fa71cd'],
};

export default Stats;
