/*
    UAN Backoffice Translation Strings - English
    Please take note of case when translating.
    Please add same translation to other lang files.
    Put them into the same line number.
*/
export default {
  language: 'en',
  //Global
  login_welcome: 'Welcome to UAN-Safe',
  page_title: 'UAN-Safe Backoffice',
  dashboard: 'Dashboard',
  premium: 'Premium',
  users: 'Users',
  remove: 'Remove',
  cancel: 'Cancel',
  search: 'Search',
  copy_to_clipboard: 'Copy to clipboard',
  select_or_search: 'Select or type to search...',
  no_matches: 'Empty result',
  locate: 'Locate',
  total: 'Total',
  received: 'Received',
  enabled: 'Enabled',
  disabled: 'Disabled',
  useDeviceSettings: 'Use Device Settings',
  first_name: 'First name',
  last_name: 'Last name',
  email: 'E-mail',
  invalid_email_error: 'Invalid e-mail address',
  email_exists_error: 'The e-mail address already exists',
  admin: 'Admin',
  added: 'Added',
  new: 'New',
  edit: 'Edit',
  delete: 'Delete',
  new_backoffice: 'Map View',
  new_backoffice_devices: 'You can now administer devices in our Map View! To try it out, click here.',
  new_backoffice_networks: 'You can now administer networks in our Map View! To try it out, click here.',
  // Integrations
  integrations: 'Integrations',
  create_integration: 'Create integration',
  update_integration: 'Update integration',
  delete_integration: 'Delete integration',
  integration_ip: 'IP',
  integration_port: 'Port',
  integration_manitou: 'Manitou',
  integration_shortxml: 'ShortXML',
  integration_kind: 'Protocol',
  integration_line: 'Line',
  integration_sia_code_create: 'SIA code (New incident)',
  integration_sia_code_confirm: 'SIA code (Confirmed incident)',
  integration_sia_code_recall: 'SIA code (Recalled incident)',
  integration_sia_code_restore: 'SIA code (Restored incident)',
  integration_uid: 'Account ID / Hardware ID / Customer ID',
  integration_pin: 'PIN code',
  integration_description: 'Description (for logging purposes)',
  integration_saved: 'Integration saved',
  integration_save_error: 'An error occurred when saving the integration',
  integration_delete_error: 'An error occurred when deleting the integration',
  integration_delete_success: 'Integration deleted',
  integration_ip_required: 'IP is required',
  integration_port_required: 'Port is required',
  integration_kind_required: 'Kind is required',
  integration_line_required: 'Line is required',
  integration_uid_required: 'UID is required',
  integration_pin_required: 'PIN code is required',
  integration_description_required: 'Description is required',
  logs: 'Logs',
  // dashboard
  devices: 'Devices',
  clients: 'Clients',
  networks: 'Networks',
  network: 'Network',
  incidents: 'Incidents',
  comments: 'Comments',
  history_chart_title: 'Stats History Chart',
  history_chart_description: 'View all numbers for the past days/months/years',
  subscriptions_chart_title: 'Subscription distribution',
  subscriptions_chart_description: 'Distribution of registered Intrasenze users per subscription plan',
  year: 'year',
  years: 'years',
  month: 'month',
  months: 'months',
  week: 'week',
  weeks: 'weeks',
  day: 'day',
  days: 'days',
  reports: 'Reports',
  stat: 'Stat', // abbrevation for Statistic
  password: 'Password',
  // users
  add_user: 'Add user',
  add_user_to: 'Add user to',
  add_new_user: 'Add new user',
  add_existing_user: 'Add existing user',
  username: 'Username',
  user_active: 'Active',
  user_awaiting_activation: 'Awaiting activation',
  user_subscription: 'Subscription',
  user_status: 'Status',
  user_admin: 'Super admin',
  user_save_and_new: 'Save and add another user',
  user_save_success: 'User saved!',
  user_save_error: 'An error occurred when saving the user!',
  user_email_error: 'The e-mail cannot be empty',
  user_username_error: 'The username cannot be empty',
  user_reset_password: 'Reset password',
  user_generate_password: 'Genererate new password',
  user_password_saved: 'Password saved and sent.',
  user_password_reset_confirm: "Are you sure you want to save? This will overwrite the users's previous password.",
  subscription_history: 'Subscription history',
  subscription: 'Subscription',
  subscription_platform: 'Platform',
  subscription_date: 'Started',
  subscription_expires: 'Expires',
  subscription_unsubscribed: 'Unsubscribed',
  mobile_devices: 'Mobile devices',
  user_device_registered: 'Registered',
  user_device_last_update: 'Last updated',
  user_device_app_version: 'App version',
  user_device_manufacturer: 'Manufacturer',
  user_device_model: 'Model',
  user_device_os: 'OS',
  user_device_is_logged_in: 'Logged in',
  user_device_never_accessed: 'Never',
  user_notification_settings: 'Notifications',
  user_notification_category: 'Category',
  user_notification_push: 'Push notification',
  user_notification_email: 'E-mail',
  user_notification_sound: 'Sound',
  show: 'Show',
  active: 'Active',
  awaiting_activation: 'Awaiting Activation',
  status: 'Status',
  quick_search: 'Quick Search',
  created: 'Created',
  unique_id: 'Unique ID',
  location: 'Location',
  latitude: 'Latitude',
  longitude: 'Longitude',
  address: 'Address',
  city: 'City',
  country: 'Country',
  save: 'Save',
  registered_dalm_dialers: 'Registered Dalm Dialers',
  manufacturer: 'Manufacturer',
  model: 'Model',
  operating_system: 'Operating System',
  app_version: 'App Version',
  notification_name: 'Notification Name',
  push: 'Push',
  role: 'Role',
  subscribe_date: 'Subscribe Date',
  unsubscribe_date: 'Unsubscribe Date',
  expiration_date: 'Expiration Date',
  success: 'Success!',
  error: 'Error!',
  user_saved: 'User Successfully Saved.',
  location_saved: 'Location Successfully Saved.',
  users_added: 'Users successfully added',
  add_user_to_network: 'Add user to selected networks',
  the_language: 'Language',
  language_en: 'English',
  language_sv: 'Swedish',
  language_es: 'Spanish',
  client_affiliation: 'Client Affilation',
  client_is_required: 'Client is required',
  // Clients
  add_client: 'Add client',
  client_name: 'Client name',
  client_name_error: 'Client name cannot be empty',
  client_contact_name: 'Contact name',
  client_contact_email: 'Contact e-mail',
  client_contact_phone: 'Contact phone',
  client_alarm_central_phone: 'Alarm central phone',
  client_save_success: 'Client saved!',
  client_save_error: 'An error occurred when saving the client!',
  client_user_count: 'Number of users',
  client_network_summary: 'Networks',
  client_subscription_summary: 'Subscriptions',
  policy: 'Policy',
  policy_sos: 'SOS Emergency',
  policy_amc: 'Panic Alert',
  policy_network: 'Network Alert',
  policy_safe_assist: 'Safe Assist',
  policy_enforce: "Override the user's own settings",
  policy_disable: "Disable the alarm",
  policy_create_public_alert: 'Create Public Alert',
  policy_alert_all_available_networks: 'Alert All Available Networks',
  policy_alert_all_available_users: 'Alert All Available Users',
  policy_title: 'Alarm title',
  policy_description: 'Alarm description',
  policy_selected_networks: 'Selected Networks',
  policy_public_radius_enabled: 'Enable Notification range Dynamic Alerts',
  policy_public_radius_title: 'Notification range Dynamic Alerts (meter).',
  policy_public_radius_placeholder: 'If empty, will be set to 10.000 m.',
  policy_selected_networks_radius_enabled: 'Enable Notification range of Selected Networks.',
  policy_selected_networks_radius_title: 'Notification range of Selected Networks (in meters).',
  policy_selected_networks_radius_placeholder: 'If empty, all Selected Networks will be notified.',
  client_policy_save_success: 'Client policy saved!',
  client_policy_save_error: 'An error occurred when saving the client policy!',
  settings: 'Alert Central',
  caption: 'Caption',
  icon: 'Icon',
  dynamic_alert: 'Dynamic Alert',
  region: 'Region',
  archived: 'Archived',
  closed: 'Closed',
  version: 'Version',
  comment: 'Comment',
  start: 'Start',
  end: 'End',
  add_new_invoice: 'Add New Invoice',
  device_id: 'Device ID',
  modified: 'Modified',
  name: 'Name',
  public: 'Public',
  private: 'Private',
  open: 'Open',
  deleted: 'Deleted',
  public_network: 'Public Network',
  private_network: 'Private Network',
  open_network: 'Open Network',
  deleted_network: 'Deleted Network',
  //devices
  add_device: 'Add device',
  device_ports: 'Inputs and outputs',
  device_inputs: 'Inputs',
  device_outputs: 'Outputs',
  device_alerts: 'Device alerts',
  device_owner: 'Owner',
  device_user_owner: 'Owner Device',
  device_client_owner: 'Shared Device',
  device_user_device: 'User Personal Device',
  device_client_device: 'Client Device',
  device_name: 'Name',
  device_description: 'Description',
  device_location: 'Location',
  device_status: 'Status',
  device_dalm_id: 'DALM ID',
  device_dalm_sim: 'DALM SIM',
  device_dalm_passcode: 'DALM Passcode',
  device_on: 'Active',
  device_off: 'Inactive',
  device_deleted: 'Deleted',
  device_port_code: 'Code',
  device_port_name: 'Name',
  device_port_active: 'Status',
  device_owner_error: 'You must select an owner',
  device_name_error: 'The name cannot be empty',
  device_location_error: 'The location cannot be empty',
  device_lat_error: 'The coordinate cannot be empty',
  device_lng_error: 'The coordinate cannot be empty',
  device_save_success: 'Device saved!',
  device_save_error: 'An error occurred when saving the device!',
  test_mode: 'Test mode',
  alert_type: 'Type',
  alert_event_code: 'Event code',
  alert_section_number: 'Section number',
  alert_message: 'Message',
  //networks
  add_network: 'Add network',
  add_network_to: 'Add network to',
  add_existing_network: 'Add existing network',
  network_name: 'Network name',
  network_description: 'Description',
  network_type: 'Type',
  network_status: 'Status',
  network_address: 'Address',
  network_open: 'Open',
  network_public: 'Public',
  network_private: 'Private',
  network_active: 'Active',
  network_closed: 'Closed',
  network_archived: 'Archived',
  network_deleted: 'Deleted',
  network_owner: 'Owner',
  network_admin: 'Admin',
  network_member: 'Member',
  network_member_joined: 'Joined',
  network_add_members: 'Add selected users to the network',
  network_save_success: 'Network saved!',
  network_save_error: 'An error occurred when saving the network!',
  network_name_error: 'The name cannot be empty',
  network_address_error: 'The address cannot be empty',
  network_lat_error: 'The coordinate cannot be empty',
  network_lng_error: 'The coordinate cannot be empty',
  network_settings: 'Settings',
  enableNotificationRange: 'Notify only members within reach',
  notificationRange: 'Notification Range',
  disallowMemberPosts: 'Members can not Notify or Comment',
  onlyNotifyAdmins: 'Notify admin only',
  confirmIncidents: 'Create confirmed notification',
  allowSOSAlerts: 'Allow incoming SOS Emergency',
  allowPublicAlerts: 'Allow incoming Public Alerts',
  enableClustering: 'Enable clustering',
  notificationsAndComments: 'Notifications and Comments',
  incomingAlerts: 'Incoming Alerts',
  integration_ip_error: 'The IP address cannot be empty',
  integration_port_error: 'The port cannot be empty',
  integration_description_error: 'The description cannot be empty',
};
